import { AccountCircle as AccountIcon } from "@mui/icons-material";
import { Dialog, Divider, IconButton, Menu, Tooltip } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import OrganizationSelectorSection from "./OrganizationSelectorSection";
import LogoutSection from "./LogoutSection";
import ManagementSection from "./ManagementSection";
import CreateOrganizationForm from "./CreateOrganizationForm";
import ResetPasswordSection from "./ResetPasswordSection";
import UsernameSection from "./UsernameSection";
import RoleSelectorSection from "./RoleSelectorSection";

const id = "account-button";
const menuId = "account-menu";

const AccountMenu = () => {
  const { user, technicalUser, identity, roles } = useAuth();

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [newOrganizationOpen, setNewOrganizationOpen] = useState(false);

  const menuOpen = Boolean(anchorEl);

  const onCloseMenu = () => {
    setAnchorEl(undefined);
  };

  const onOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onNewOrganization = () => {
    setNewOrganizationOpen(true);
    onCloseMenu();
  };

  const onCloseNewOrganization = () => {
    setNewOrganizationOpen(false);
  };

  if (!user) {
    return <></>;
  }

  const displayRoleSector = roles.length > 1;
  const displayOrganizationSector =
    technicalUser === false && identity === "verified";

  return (
    <>
      <Tooltip title={t("header.account.tooltip")}>
        <IconButton
          id={id}
          aria-controls={menuOpen ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          onClick={onOpenMenu}
        >
          <AccountIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={onCloseMenu}
        MenuListProps={{
          "aria-labelledby": id,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <UsernameSection />
        {displayRoleSector && <Divider />}
        {displayRoleSector && <RoleSelectorSection onClick={onCloseMenu} />}
        {displayOrganizationSector && <Divider />}
        {displayOrganizationSector && (
          <OrganizationSelectorSection onNewClick={onNewOrganization} />
        )}
        <Divider />
        <ResetPasswordSection />
        <ManagementSection onClick={onCloseMenu} />
        <Divider />
        <LogoutSection onClick={onCloseMenu} />
      </Menu>
      <Dialog open={newOrganizationOpen} onClose={onCloseNewOrganization}>
        <CreateOrganizationForm onClose={onCloseNewOrganization} />
      </Dialog>
    </>
  );
};

export default AccountMenu;
