import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Version = () => {
  const { t } = useTranslation();
  const tooltip = t("footer.version.tooltip");

  return (
    <Tooltip title={tooltip}>
      <Typography color={"text.secondary"}>
        {process.env.REACT_APP_VERSION}
      </Typography>
    </Tooltip>
  );
};

export default Version;
