import ViewWrapper from "./ViewWrapper";
import UploadButton from "../../vehicles/vehicle-table/UploadButton";
import { useTranslation } from "react-i18next";

const UploadVehicles = () => {
  const { t } = useTranslation()
  const title = t("dashboard.upload.vehicles.title")
  return (
    <ViewWrapper id="upload-vehicles" title={title} align="center">
      <UploadButton />
    </ViewWrapper>
  );
};

export default UploadVehicles;