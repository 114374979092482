import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import NavBar from "./navbar/Navbar";
import { useOrganizationBrandingColor } from "../hooks/useOrganizationBrandingColor";

const LandingLayout = () => {
  useOrganizationBrandingColor();
  return (
    <Wrapper>
      <NavBar />
      <Main>
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      </Main>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  justifyContent: "space-between",
  height: "100%",
}));

const Main = styled("main")(() => ({
  flex: 1,
}));

export default LandingLayout;
