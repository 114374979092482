import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { getRoutesByRole } from "../navbar/routes";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { role } = useAuth();
  const routes = getRoutesByRole(role);
  const to = routes && routes.length > 0 ? routes[0].path : "/";

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={5}
      sx={{ padding: "3rem" }}
    >
      <Typography
        variant="h1"
        display="flex"
        alignItems="center"
        margin="0 !important"
      >
        <ErrorOutlineIcon
          fontSize="inherit"
          color="error"
          sx={{ marginRight: "1rem" }}
        />
        {t("error.pagenotfound.title")}
      </Typography>
      <Typography variant="subtitle1">
        {t("error.pagenotfound.description")}
      </Typography>
      <Stack direction={{ xs: "column", md: "row" }} spacing={3}>
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
          color="primary"
        >
          {t("error.pagenotfound.back")}
        </Button>
        <Button component={Link} to={to} variant="outlined" color="primary">
          {t("error.pagenotfound.home")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default NotFound;
