import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const ProtectedRoute: FC<RouteProps> = () => {
  const { user, initialized, error, identity, authenticated, role } = useAuth();

  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("authentication.error"), {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [error]);

  const navigateHome = () => {
    return <Navigate to="/" state={{ from: location }} replace />;
  };

  const loading =
    !initialized ||
    (authenticated &&
      role &&
      (identity === undefined || identity === "pending"));
  if (loading) {
    return <CircularProgress />;
  }

  if (authenticated === false) {
    return navigateHome();
  }

  const userReady = user && !error && initialized;

  return userReady ? <Outlet /> : navigateHome();
};

export default ProtectedRoute;
