import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../common/constants";
import { authClient } from "../../authConfig";

export interface OrganizationRequest {
  organizationId: string;
}

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      if (authClient?.token) {
        headers.set("authorization", `Bearer ${authClient.token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "Admin",
    "Vehicle",
    "OrganizationSetting",
    "Associate",
    "OrganizationBranding",
    "VehicleApproval",
    "ApprovalHistory",
    "Confirmation",
    "AssociateStats",
    "VehicleStats",
    "Organization",
    "User",
  ],
  endpoints: () => ({}),
});
