import { LockReset as LockResetIcon } from "@mui/icons-material";
import { ListItemIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import AccountMenuItem from "./AccountMenuItem";

const ResetPasswordSection = () => {
  const { login } = useAuth();
  const { t } = useTranslation();

  const onResetPassword = () => {
    login({
      action: "UPDATE_PASSWORD",
    });
  };

  return (
    <AccountMenuItem disableRipple onClick={onResetPassword}>
      <ListItemIcon>
        <LockResetIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="inherit" noWrap>
        {t("menu.update.password")}
      </Typography>
      &nbsp;
    </AccountMenuItem>
  );
};

export default ResetPasswordSection;
