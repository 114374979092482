import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import {
  DataGrid,
  getGridStringOperators,
  GridActionsCellItem,
  GridCellParams,
  GridColumns,
  GridFilterModel,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import {
  tableComponentProps,
  TableContentWrapper,
} from "../../../common/styles";
import { Operator } from "../../../common/pagination";
import {
  getVehicleTechnicalCard,
  useApproveMutation,
  useDeclineMutation,
  useGetVehicleApprovalsQuery,
  VehicleApproval,
} from "../../../redux/api/VehicleApprovalApi";
import Pagination from "../../pagination/Pagination";
import { VehiclesContext } from "../VehiclesComponent";
import DeclineModal from "./DeclineModal";
import Toolbar from "./Toolbar";
import { open } from "../../../redux/api/util";
import ExternalLink from "../../../common/external-link/ExternalLink";
import { getTableLocaleText } from "../../getTableLocaleText";
import useSmallScreenProperties from "../../../common/small-screen/useSmallScreenProperties";
import { reducedApprovalVehiclesVisibilityModel } from "../../../common/small-screen/visibilityModel";

interface ApprovalVehicleRow {
  id: string;
  technicalCardLink: string;
  organizationName: string;
  vin: string;
}

export const mapToGridRow = (
  vehicles: VehicleApproval[]
): GridRowsProp<ApprovalVehicleRow> => {
  return vehicles.map(
    ({ publicId, organizationName, vin, technicalCardLink }) => {
      return {
        id: publicId,
        organizationName,
        vin,
        technicalCardLink,
      };
    }
  );
};

const ApprovalVehiclesComponent = () => {
  const { t } = useTranslation();
  const [approve] = useApproveMutation();
  const [decline] = useDeclineMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [
    displayAll,
    setDisplayAll,
    columnVisibilityModel,
    setColumnVisibilityModel,
  ] = useSmallScreenProperties(reducedApprovalVehiclesVisibilityModel);

  const [queryOptions, setQueryOptions] =
    useOutletContext<VehiclesContext<VehicleApproval>>();
  const { data, isFetching, isLoading } =
    useGetVehicleApprovalsQuery(queryOptions);

  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<string>();

  const onDeclineModalClose = () => {
    setDeclineModalOpen(false);
  };

  const onDeclineModalConfirm = async (reason: string) => {
    if (!selectedVehicleId) {
      return;
    }

    try {
      await decline({
        reason,
        vehiclePublicId: selectedVehicleId,
      }).unwrap();
    } catch (e: any) {
      enqueueSnackbar("Vehicle decline failed", {
        variant: "error",
        preventDuplicate: true,
        key: e.status,
      });
    } finally {
      setDeclineModalOpen(false);
      setSelectedVehicleId(undefined);
    }
  };

  const onDeclineClick = (publicId: string) => {
    setDeclineModalOpen(true);
    setSelectedVehicleId(publicId);
  };

  const onApproveClick = async (publicId: string) => {
    setSelectedVehicleId(publicId);
    try {
      await approve({
        vehiclePublicId: publicId,
      }).unwrap();
    } catch (e: any) {
      enqueueSnackbar("Vehicle approval failed", {
        variant: "error",
        preventDuplicate: true,
        key: e.status,
      });
    }
  };

  const onDownloadClick = (id: string) => (e: MouseEvent) => {
    e.preventDefault();
    getVehicleTechnicalCard(id)
      .then(open)
      .catch((e) => {
        enqueueSnackbar(t("verification.download.error"), {
          variant: "error",
          preventDuplicate: true,
          key: e.status,
        });
      });
  };

  const getActions = (params: GridRowParams<ApprovalVehicleRow>) => {
    const declineTitle = t("vehicle.approval.decline");
    const approveTitle = t("vehicle.approval.approve");
    return [
      <GridActionsCellItem
        id={`decline-vehicle-${params.row.vin}-button`}
        icon={
          <Tooltip title={declineTitle}>
            <IconButton
              size="small"
              onClick={() => onDeclineClick(params.row.id)}
            >
              <ClearIcon color="error" />
            </IconButton>
          </Tooltip>
        }
        label={declineTitle}
      />,
      <GridActionsCellItem
        id={`approve-vehicle-${params.row.vin}-button`}
        icon={
          <Tooltip title={approveTitle}>
            <IconButton
              size="small"
              onClick={() => onApproveClick(params.row.id)}
            >
              <CheckIcon color="primary" />
            </IconButton>
          </Tooltip>
        }
        label={approveTitle}
      />,
    ];
  };

  const renderTechnicalCardLinkCell = (
    params: GridCellParams<unknown, ApprovalVehicleRow>
  ) => (
    <ExternalLink
      href="#"
      text={t("vehicle.approval.open")}
      onClick={onDownloadClick(params.row.id)}
    />
  );

  const columns: GridColumns = [
    {
      field: "vin",
      headerName: "VIN",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "organizationName",
      headerName: t("vehicles.approval.organizationName"),
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "technicalCardLink",
      headerName: t("vehicles.approval.technicalCardLink"),
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
      filterable: false,
      sortable: false,
      renderCell: renderTechnicalCardLinkCell,
    },
    {
      field: "actions",
      headerName: t("table.columns.actions"),
      type: "actions",
      minWidth: 80,
      filterable: false,
      sortable: false,
      getActions,
    },
  ];

  const localeText = getTableLocaleText();

  const rows = useMemo(
    () => (data?.content ? mapToGridRow(data.content) : []),
    [data]
  );

  const rowCount = useMemo(() => data?.totalElements || 0, [data]);

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof VehicleApproval,
      sortOrder: sort?.sort,
    });
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter =
      filterModel.items.length === 0 ? undefined : filterModel.items[0];
    setQueryOptions({
      ...queryOptions,
      filterField: filter?.columnField as keyof VehicleApproval,
      filterOperator: filter?.operatorValue as Operator,
      filterValue: filter?.value,
    });
  };

  const sortModel = useMemo(() => {
    return queryOptions.sortField
      ? [
          {
            sort: queryOptions.sortOrder,
            field: queryOptions.sortField,
          },
        ]
      : [];
  }, [queryOptions.sortField, queryOptions.sortOrder]);

  const filterModel = useMemo(() => {
    const filter = queryOptions.filterField
      ? [
          {
            columnField: queryOptions.filterField,
            operatorValue: queryOptions.filterOperator,
            value: queryOptions.filterValue,
          },
        ]
      : [];
    return {
      items: filter,
    };
  }, [
    queryOptions.filterField,
    queryOptions.filterOperator,
    queryOptions.filterValue,
  ]);

  return (
    <TableContentWrapper displayAll={displayAll}>
      <DataGrid
        localeText={localeText}
        columns={columns}
        rows={rows}
        components={{
          Toolbar,
          LoadingOverlay: LinearProgress,
          Pagination,
          OpenFilterButtonIcon: SearchIcon,
        }}
        rowCount={rowCount}
        page={queryOptions.page}
        onPageChange={onPageChange}
        pageSize={queryOptions.size}
        onPageSizeChange={onPageSizeChange}
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        filterMode="server"
        loading={isFetching || isLoading}
        componentsProps={{
          ...tableComponentProps,
          toolbar: { displayAll, setDisplayAll },
        }}
        disableSelectionOnClick
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
      />
      <DeclineModal
        open={declineModalOpen}
        onClose={onDeclineModalClose}
        onConfirm={onDeclineModalConfirm}
      />
    </TableContentWrapper>
  );
};

export default ApprovalVehiclesComponent;
