import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "@mui/material";
import { VehicleValidationErrors } from "../../redux/api/VehiclesApi";
import { AssociateValidationErrors } from "../../redux/api/AssociatesApi";
import { mapErrorsToCsv, translateHeaders } from "./ImportErrorExportHelper";
import { CSVLink } from "react-csv";

interface DownloadErrorsButtonProps {
  data: VehicleValidationErrors | AssociateValidationErrors;
}

const DownloadErrorsButton: FC<DownloadErrorsButtonProps> = ({ data }) => {
  const { t } = useTranslation();
  const errorRows = mapErrorsToCsv(data, t);
  const fileName = t("table.export.errors.filename", {
    date: new Date().toISOString(),
  });

  return (
    <CSVLink
      data={errorRows}
      filename={fileName}
      headers={translateHeaders(t)}
      target="_blank"
    >
      <Tooltip title={t("table.export.errors.tooltip")}>
        <Button variant="contained" size="medium" color="primary">
          {t("table.export.errors")}
        </Button>
      </Tooltip>
    </CSVLink>
  );
};

export default DownloadErrorsButton;
