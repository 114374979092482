import { Button, Tooltip } from "@mui/material";
import {
  GridApi,
  gridColumnDefinitionsSelector,
  GridToolbarContainer,
  gridVisibleColumnDefinitionsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { ViewColumn as ViewColumnIcon } from "@mui/icons-material/";

export const Toolbar = () => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext<GridApi>();
  const { length: visibleColumnsLength } =
    gridVisibleColumnDefinitionsSelector(apiRef);

  const onShowAll = () => {
    const columns = gridColumnDefinitionsSelector(apiRef).map((col) =>
      col.hideable ? { field: col.field, hide: false } : col
    );
    apiRef.current.updateColumns(columns);
  };

  return (
    <GridToolbarContainer>
      {visibleColumnsLength === 0 && (
        <Tooltip title={t("table.show.columns.tooltip")}>
          <Button
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={onShowAll}
            startIcon={<ViewColumnIcon />}
          >
            {t("table.show.columns")}
          </Button>
        </Tooltip>
      )}
    </GridToolbarContainer>
  );
};
