import { TFunction } from "react-i18next";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { ApproveState, Vehicle } from "../../redux/api/VehiclesApi";
import {
  ExternalAuthorizeEventResult,
  ExternalAuthorizeEventStatusReason,
} from "../../redux/api/ExternalAuthorizeEvent";

export const translateHeaders = (t: TFunction<string>): LabelKeyObject[] => {
  return [
    { label: t("vin"), key: "vin" },
    { label: t("vehicles.detail"), key: "modelType" },
    { label: t("vehicles.licensePlate"), key: "licensePlate" },
    { label: t("vehicles.approval"), key: "state" },
    {
      label: t("vehicles.last.evaluation.result"),
      key: "evaluationResult",
    },
    { label: t("evaluation.reject.reason"), key: "evaluationResultReason" },
    { label: t("vehicles.allowAccess"), key: "deny" },
  ];
};

interface VehicleRow {
  vin?: string;
  modelType?: string;
  licensePlate?: string;
  state?: ApproveState;
  evaluationResult?: ExternalAuthorizeEventResult;
  evaluationResultReason?: ExternalAuthorizeEventStatusReason;
  deny?: boolean;
}

export const mapVehicleDataToCsv = (
  vehicles: Vehicle[],
  t: TFunction<string>
): VehicleRow[] => {
  return vehicles.map((value): VehicleRow => {
    return {
      vin: value.vin,
      modelType: value.modelType,
      licensePlate: value.licensePlate,
      state: t("vehicles.approve.state." + value.state),
      evaluationResult: t(
        "vehicles.external.access.state." +
          value.externalLastLoginStatus?.result
      ),
      evaluationResultReason: t(
        (value.externalLastLoginStatus?.failReason &&
          "vehicle.calendarAccessRejection.reason." +
            value.externalLastLoginStatus?.failReason) ||
          ""
      ),
      deny: value.deny
        ? t("dashboard.vehicles.allowAccess.denied")
        : t("dashboard.vehicles.allowAccess.allowed"),
    };
  });
};
