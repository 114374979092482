import { baseApi, OrganizationRequest } from "./baseApi";
import { toSearchParams } from "./util";

interface ResendAssociatesConfirmationsRequest extends OrganizationRequest {
  ids: String[];
}

export const associatesConfirmationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    resendAssociatesConfirmations: builder.mutation<
      void,
      ResendAssociatesConfirmationsRequest
    >({
      query: ({ ids: publicIds, organizationId }) => ({
        url: `/associate-confirmation/resend?${toSearchParams({
          publicIds,
          organizationId,
        })}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useResendAssociatesConfirmationsMutation } =
  associatesConfirmationApi;
