import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { BASE_URL } from "./common/constants";
import translations from "./locales/en/translation.json";

const wrapTranslations = (
  translations: Record<string, string>,
  prefix = "[",
  suffix = "]"
) => {
  return Object.entries(translations).reduce<Record<string, string>>(
    (acc, [key, value]) => {
      acc[key] = `${prefix}${value}${suffix}`;
      return acc;
    },
    {}
  );
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpBackend)
  .init({
    load: "all",
    ns: ["translation", "local"],
    defaultNS: "translation",
    fallbackNS: "local",
    fallbackLng: "en-GB",
    supportedLngs: ["en-GB", "cs-CZ"],
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: {
      useSuspense: true,
    },
    // Some translations are stored locally before added to the backend
    partialBundledLanguages: true,
    backend: {
      loadPath: `${BASE_URL}/api/i18n?locale={{lng}}`,
    },
  });

// Local translations are put into a separated namespace and used only to fallback
i18n.addResourceBundle("en-GB", "local", wrapTranslations(translations));
