import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useApplicationColors } from "../../common/ApplicationColorsContext";

const ThemeSwitch = () => {
  const { t } = useTranslation();
  const applicationColors = useApplicationColors();

  const onClick = () => {
    applicationColors.changeMode();
  };

  return (
    <Tooltip title={t(`theme.switch.${applicationColors.mode}`)}>
      <IconButton onClick={onClick}>
        {applicationColors.mode === "light" ? (
          <LightModeIcon />
        ) : (
          <DarkModeIcon />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ThemeSwitch;
