import { Button, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as Brand } from "../../../assets/brand/skoda.svg";
import { styled } from "@mui/system";
import { useAuth } from "../../../hooks/useAuth";
import { dashboardRoute } from "../../navbar/routes";

const LoginSection = () => {
  const { t } = useTranslation();
  const { login } = useAuth();

  const onLoginClick = () => {
    login({ redirectUri: `${window.location.origin}${dashboardRoute.path}` });
  };

  return (
    <Wrapper>
      <Container maxWidth="xl">
        <Brand />
        <Title>{t("landing.login.title")}</Title>

        <LoginButton variant="contained" onClick={onLoginClick}>
          {t("start")}
        </LoginButton>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled("article")(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: "center",
  flex: "1 0 90vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  isolation: "isolate",
  position: "relative",
  zIndex: 1,
  svg: {
    minWidth: "125px",
    width: "55%",
    maxWidth: "225px",
  },

  "&:before": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    left: 20,
    bottom: 0,
    transform: "rotate(180deg)",
    zIndex: -1,
    backgroundImage:
      "url('https://skoda-brand.s3.amazonaws.com/2019/w21/getting-started-background-small.png')",
    // Its rotated
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    pointerEvents: "none",
    [theme.breakpoints.up("sm")]: {
      backgroundImage:
        "url('https://skoda-brand.s3.amazonaws.com/2019/w21/getting-started-background-medium.png')",
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage:
        "url('https://skoda-brand.s3.amazonaws.com/2019/w21/getting-started-background-large.png')",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage:
        "url('https://skoda-brand.s3.amazonaws.com/2019/w21/getting-started-background.png')",
    },
  },
}));

const Title = styled("h1")(() => ({
  fontSize: "clamp(0.875rem, 10vw - 1.25rem, 1.1rem)",
  fontWeight: "bold",
  lineHeight: "1.75",
  textTransform: "uppercase",
  letterSpacing: "0.01562rem",
  padding: 0,
  margin: "0 0 1.5rem",
}));

const LoginButton = styled(Button)(({ theme }) => ({
  width: "80%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

export default LoginSection;
