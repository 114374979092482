import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { TablePageWrapper } from "../../common/styles";
import { PageRequest } from "../../common/pagination";
import { Vehicle } from "../../redux/api/VehiclesApi";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const pageSizeStorageKey = "vehiclesTablePageSize";
const defaultPageSize = 10;

export type VehiclesContext<T = Vehicle> = [
  PageRequest<T>,
  (options: PageRequest<T>) => void
];

const VehiclesComponent = () => {
  const { t } = useTranslation();
  const organization = useSelector(
    (state: RootState) => state.userSettings.organization
  );

  const [queryOptions, setQueryOptions] = useState<PageRequest<Vehicle>>(() => {
    const preferredPageSize = Number(localStorage.getItem(pageSizeStorageKey));
    const size =
      isNaN(defaultPageSize) || preferredPageSize === 0
        ? defaultPageSize
        : preferredPageSize;
    return {
      page: 0,
      size,
      // This Component is also used by the Approver
      organizationId: organization?.publicId || "",
    };
  });

  const onQueryOptionsChange = (options: PageRequest<unknown>) => {
    localStorage.setItem(pageSizeStorageKey, `${options.size}`);
    setQueryOptions(options);
  };

  return (
    <TablePageWrapper>
      <Typography variant="h1">{t("vehicles")}</Typography>
      <Outlet context={[queryOptions, onQueryOptionsChange]} />
    </TablePageWrapper>
  );
};

export default VehiclesComponent;
