import { EVENTS, STATUS, Step } from "react-joyride";

export const endStates = [STATUS.FINISHED, STATUS.SKIPPED] as string[];
export const proceedingStates = [
  EVENTS.STEP_AFTER,
  EVENTS.TARGET_NOT_FOUND,
] as string[];

export const welcome: Step = {
  target: "#root",
  title: "tour.welcome.title",
  content: "tour.welcome.content",
  placement: "center",
  hideBackButton: true,
  showSkipButton: true,
  hideCloseButton: true,
  disableOverlayClose: true,
};

export const openMenu: Step = {
  target: "#open-menu-button",
  title: "tour.menu.title",
  content: "tour.menu.content",
  placement: "bottom",
  hideFooter: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  spotlightClicks: true,
  spotlightPadding: 0,
};

export const selectAssociates: Step = {
  target: "#nav-button-associates",
  title: "tour.selectAssociates.title",
  content: "tour.selectAssociates.content",
  placement: "bottom",
  hideFooter: false,
  hideBackButton: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  spotlightClicks: true,
  spotlightPadding: 0,
};

export const selectAssociatesMobile: Step = {
  target: "#mobile-drawer #nav-button-associates",
  title: "tour.selectAssociates.title",
  content: "tour.selectAssociates.content",
  placement: "bottom",
  hideFooter: false,
  hideBackButton: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  spotlightClicks: true,
  spotlightPadding: 0,
};

export const uploadAssociates: Step = {
  target: "#upload-associates-button",
  title: "tour.uploadAssociates.title",
  content: "tour.uploadAssociates.content",
  hideBackButton: true,
  hideCloseButton: true,
  disableOverlayClose: true,
};

export const selectVehicles: Step = {
  target: "#nav-button-vehicles",
  title: "tour.selectVehicles.title",
  content: "tour.selectVehicles.content",
  placement: "bottom",
  hideFooter: false,
  hideBackButton: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  spotlightClicks: true,
  spotlightPadding: 0,
};

export const selectVehicleDetails: Step = {
  target: 'button[aria-label="Detail"]',
  title: "tour.selectVehicleDetails.title",
  content: "tour.selectVehicleDetails.content",
  hideFooter: false,
  hideBackButton: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  spotlightClicks: true,
  spotlightPadding: 0,
};

export const finishTour: Step = {
  target: "#root",
  title: "tour.finishTour.title",
  content: "tour.finishTour.content",
  placement: "center",
  hideBackButton: true,
  hideCloseButton: true,
};

export const steps = [
  welcome,
  selectAssociates,
  uploadAssociates,
  selectVehicles,
  selectVehicleDetails,
  finishTour,
];

export const stepIds = {
  welcome: 0,
  selectAssociates: 1,
  uploadAssociates: 2,
  selectVehicles: 3,
  selectVehicleDetails: 4,
  finishTour: 5,
};

export const mobileSteps: Step[] = [
  welcome,
  openMenu,
  selectAssociatesMobile,
  uploadAssociates,
  selectVehicleDetails,
  finishTour,
];

export const mobileStepIds = {
  welcome: 0,
  openMenu: 1,
  selectAssociates: 2,
  uploadAssociates: 3,
  selectVehicleDetails: 4,
  finishTour: 5,
};
