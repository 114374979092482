import { FC } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Card, CardMedia } from "@mui/material";
import { MDM_WELCOME_VIDEO_URL } from "../../../common/constants";
import { CloseModalIconButton } from "../../CloseModalIconButton";

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const VideoModal: FC<VideoModalProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle sx={{ padding: 0, zIndex: 1 }}>
        <CloseModalIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Card>
          <CardMedia
            component="video"
            autoPlay
            src={MDM_WELCOME_VIDEO_URL}
            disablePictureInPicture
            controls
            controlsList="noplaybackrate nodownload"
          />
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default VideoModal;
