import { baseApi, OrganizationRequest } from "./baseApi";
import { VERIFY_VIN_KEY } from "../slice/OrganizationSettingsSlice";
import { toSearchParams } from "./util";

export interface OrganizationSetting {
  publicId?: string;
  key?: string;
  value?: string;
}

export interface SettingUpdate extends OrganizationRequest {
  publicId: string;
  shouldVerify: boolean;
}

export const organizationSettingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationSettings: builder.query<OrganizationSetting[], string>({
      query: (organizationId) => ({
        url: `/organization-settings?${toSearchParams({ organizationId })}`,
      }),
      providesTags: (_) => ["OrganizationSetting"],
    }),
    //an attempt to make api call less ugly, since BE api is poorly designed.
    setVerifyVinSetting: builder.mutation<void, SettingUpdate>({
      query: (update) => {
        const shouldVerify = update.shouldVerify ? "Y" : "N";
        const updatedSetting: OrganizationSetting = {
          publicId: update.publicId,
          key: VERIFY_VIN_KEY,
          value: shouldVerify,
        };

        return {
          url: `/organization-settings?${toSearchParams({
            organizationId: update.organizationId,
          })}`,
          method: "PUT",
          body: [updatedSetting],
        };
      },
      invalidatesTags: (_) => ["OrganizationSetting"],
    }),
  }),
});

export const {
  useGetOrganizationSettingsQuery,
  useSetVerifyVinSettingMutation,
} = organizationSettingsApi;
