import React, { FC } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@mui/material";
import { useTranslation } from "react-i18next";

interface DeleteModalProps {
  handleCancelDialog: () => void;
  handleDeleteDialog: () => void;
  isDialogOpened: boolean;
}

const DeleteModal: FC<DeleteModalProps> = ({
  handleCancelDialog,
  handleDeleteDialog,
  isDialogOpened,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={isDialogOpened}
        onClose={handleCancelDialog}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {t("delete.confirm.admin.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("delete.confirm.admin")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelDialog}
            variant="outlined"
            size="medium"
            color="secondary"
          >
            {t("cancel")}
          </Button>
          <Button
            data-cy="modal.delete.confirmation"
            onClick={handleDeleteDialog}
            variant="contained"
            size="medium"
            color="primary"
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
