import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import useFileUpload from "../../../hooks/useFileUpload";
import { useUploadLogoMutation } from "../../../redux/api/OrganizationBrandingApi";
import { Tooltip } from "@mui/material";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";

const UploadLogoButton = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const [uploadLogo, { isLoading }] = useUploadLogoMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  const title = t("upload.tooltip.logo");
  const tooltipFileTypeSize = t("upload.tooltip.filetypesize.logo");

  const onFileSelected = async (files: File[] | FileList) => {
    try {
      await uploadLogo({
        file: files[0],
        organizationId: organization.publicId,
      }).unwrap();
    } catch (err: any) {
      if (err.status === 400 && err.data?.message) {
        enqueueSnackbar(t(err.data.message), {
          variant: "error",
          preventDuplicate: true,
          key: err.status,
        });
      } else {
        enqueueSnackbar(t("upload.failed"), {
          variant: "error",
          preventDuplicate: true,
          key: err.status,
        });
      }
    }
  };

  const { openDialog } = useFileUpload({
    inputRef: fileInputRef,
    onFileSelected,
  });

  return (
    <>
      <Tooltip title={tooltipFileTypeSize}>
        <LoadingButton
          variant="outlined"
          size="medium"
          onClick={openDialog}
          startIcon={<FileUploadIcon />}
          loading={isLoading}
          loadingPosition="start"
          color="secondary"
        >
          {title}
        </LoadingButton>
      </Tooltip>
      <input
        ref={fileInputRef}
        type="file"
        accept=".jpeg,.png"
        multiple={false}
        hidden
      />
    </>
  );
};

export default UploadLogoButton;
