import { DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ResendConfirmMessageModalProps {
  handleCancelDialog: () => void;
  handleReconfirmDialog: () => void;
  isDialogOpened: boolean;
  count: number;
}

const ResendConfirmMessageModal: FC<ResendConfirmMessageModalProps> = ({
  handleCancelDialog,
  handleReconfirmDialog,
  isDialogOpened,
  count,
}) => {
  const { t } = useTranslation();

  const getContent = () => {
    if (count > 1) {
      return t("reconfirm.confirm.associate.multiple");
    } else {
      return t("reconfirm.confirm.associate");
    }
  };

  return (
    <div>
      <Dialog
        open={isDialogOpened}
        onClose={handleCancelDialog}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {t("reconfirm.confirm.associate.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{getContent()}</DialogContentText>
          {count > 1 && (
            <DialogContentText>
              {`${t("selection.associate.count")}: ${count}`}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelDialog}
            variant="outlined"
            size="medium"
            color="secondary"
          >
            {t("cancel")}
          </Button>
          <Button
            data-cy="modal.reconfirm.confirmation"
            onClick={handleReconfirmDialog}
            variant="contained"
            size="medium"
            color="primary"
          >
            {t("reconfirm.modal.confirmation")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResendConfirmMessageModal;
