export interface Page<T> {
  content: T;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
}

export type Operator = "contains" | "equals" | "is";

export interface PageRequest<T> {
  page?: number;
  size?: number;
  sortField?: keyof T;
  sortOrder?: "asc" | "desc" | null;
  filterField?: keyof T;
  filterOperator?: Operator;
  filterValue?: string;
  organizationId: string;
}

export interface CountByDeny {
  denied: number;
  allowed: number;
}

export interface CountByActivationSelfConfirmed {
  pending: number;
  active: number;
}

export interface CountByState {
  pending: number;
  active: number;
  declined: number;
}

export const toPageSearchParams = <T>({
  page,
  size,
  sortField,
  sortOrder,
  filterField,
  filterOperator: operator,
  filterValue,
  organizationId,
}: PageRequest<T>) => {
  const filterOperator = operator === "is" ? "equals" : operator;
  const params: Record<string, string> = {
    ...(page !== undefined && {
      page: page.toString(),
    }),
    ...(size !== undefined && {
      size: size.toString(),
    }),
    ...(sortField &&
      sortOrder && {
        sortField: sortField.toString(),
        sortOrder: sortOrder.toString(),
      }),
    ...(filterField &&
      filterOperator &&
      filterValue && {
        filterField: filterField.toString(),
        filterOperator,
        filterValue,
      }),
    ...(organizationId && {
      organizationId: organizationId,
    }),
  };
  const searchParams = new URLSearchParams(params);
  return searchParams.toString();
};
