import {
  CountByDeny,
  CountByState,
  Page,
  PageRequest,
  toPageSearchParams,
} from "../../common/pagination";
import { baseApi, OrganizationRequest } from "./baseApi";
import { GridSelectionModel } from "@mui/x-data-grid";
import { BASE_URL } from "../../common/constants";
import { authClient } from "../../authConfig";
import {
  ExternalAuthorizeEvent,
  ExternalAuthorizeEventResult,
  ExternalAuthorizeEventStatusReason,
} from "./ExternalAuthorizeEvent";
import { toSearchParams } from "./util";

export enum ApproveState {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  DECLINED = "DECLINED",
}

export enum VehicleValidationError {
  UNKNOWN = "UNKNOWN",
  DUPLICATE_VIN = "DUPLICATE_VIN",
  INVALID_VIN = "INVALID_VIN",
  MISSING_VIN = "MISSING_VIN",
  LICENSE_PLATE_SIZE = "LICENSE_PLATE_SIZE",
  DESCRIPTION_SIZE = "DESCRIPTION_SIZE",
}

export interface VehicleValidationErrors {
  [vin: string]: VehicleValidationError[];
}

export interface Vehicle {
  publicId: string;
  vin?: string;
  deny?: boolean;
  licensePlate?: string;
  description?: string;
  state?: ApproveState;
  externalLastLoginStatus?: LastLoginStatus;
  modelType?: string;
  paintColor?: string;
  reasonOfDecline?: string;
  technicalCardUploaded?: boolean;
}

export interface LastLoginStatus {
  result: ExternalAuthorizeEventResult;
  failReason?: ExternalAuthorizeEventStatusReason;
}

export interface VehicleDetail extends Vehicle {
  vehicleImages?: VehicleImages;
  iasVehicleData?: IASVehicleData;
  vehicleRecentCalendarAccessRejections: ExternalAuthorizeEvent[];
}

interface DenyRequest extends OrganizationRequest {
  publicId?: string;
  deny: boolean;
}

interface VehicleImages {
  smallImageUrl: string;
  mainImageUrl: string;
  garageImageUrl: string;
}

export interface IASVehicleData {
  model: string;
  modelYear: string;
  colorDescription: string;
  infotainmentTypes: string[];
}

interface TechnicalCardUpload extends OrganizationRequest {
  file: File;
  publicId: string;
}

interface GetVehicleRequest extends OrganizationRequest {
  id: string;
  language: string;
}

interface DeleteVehicleRequest extends OrganizationRequest {
  id: string;
}

interface DeleteVehiclesRequest extends OrganizationRequest {
  ids: GridSelectionModel;
}

interface VehicleBodyRequest extends OrganizationRequest {
  vehicle: Partial<Vehicle>;
}

interface ImportVehiclesRequest extends OrganizationRequest {
  file: File;
}

interface DeleteTechnicalCardRequest extends OrganizationRequest {
  publicId: string;
}

export const vehiclesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVehicles: builder.query<Page<Vehicle[]>, PageRequest<Vehicle>>({
      query: (request) => {
        return `/vehicles/?${toPageSearchParams(request)}`;
      },
      providesTags: (_) => ["Vehicle"],
    }),
    getVehicleById: builder.query<VehicleDetail, GetVehicleRequest>({
      query: ({ id, language, organizationId }) =>
        `/vehicles/${id}?${toSearchParams({ organizationId, language })}`,
      providesTags: (_) => ["Vehicle"],
    }),
    deleteVehicleById: builder.mutation<void, DeleteVehicleRequest>({
      query: ({ id, organizationId }) => ({
        url: `/vehicles/${id}?${toSearchParams({ organizationId })}`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["Vehicle", "VehicleStats"],
    }),
    deleteVehiclesByIds: builder.mutation<void, DeleteVehiclesRequest>({
      query: ({ ids, organizationId }) => ({
        url: `/vehicles?${toSearchParams({
          ids,
          organizationId,
        })}`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["Vehicle", "VehicleStats"],
    }),
    createVehicle: builder.mutation<void, VehicleBodyRequest>({
      query: ({ vehicle, organizationId }) => ({
        url: `/vehicles/?${toSearchParams({ organizationId })}`,
        method: "POST",
        body: vehicle,
      }),
      invalidatesTags: (_) => ["Vehicle", "VehicleStats"],
    }),
    updateVehicle: builder.mutation<void, VehicleBodyRequest>({
      query: ({ vehicle: { publicId, ...patch }, organizationId }) => {
        return {
          url: `/vehicles/${publicId}?${toSearchParams({ organizationId })}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (_) => ["Vehicle", "VehicleStats"],
    }),
    updateVehicleDetail: builder.mutation<void, VehicleBodyRequest>({
      query: ({ vehicle: { publicId, ...patch }, organizationId }) => {
        return {
          url: `/vehicles/${publicId}/detail?${toSearchParams({
            organizationId,
          })}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (_) => ["Vehicle", "VehicleStats"],
    }),
    denyVehicle: builder.mutation<void, DenyRequest>({
      query: ({ publicId, organizationId, ...patch }) => {
        return {
          url: `/vehicles/${publicId}?${toSearchParams({ organizationId })}`,
          method: "PATCH",
          body: patch,
        };
      },
      invalidatesTags: (_) => ["Vehicle", "VehicleStats"],
    }),
    importVehicles: builder.mutation<Vehicle[], ImportVehiclesRequest>({
      query: ({ file, organizationId }) => {
        const formData = new FormData();
        formData.append("data", file);
        return {
          url: `/vehicles/import?${toSearchParams({ organizationId })}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (_) => ["Vehicle", "VehicleStats"],
    }),
    uploadTechnicalCard: builder.mutation<void, TechnicalCardUpload>({
      query: ({ file, publicId, organizationId }) => {
        const formData = new FormData();
        formData.append("image", file);
        return {
          url: `/vehicles/${publicId}/technical-card/?${toSearchParams({
            organizationId,
          })}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (_) => ["Vehicle", "VehicleApproval"],
    }),
    countVehiclesByDeny: builder.query<CountByDeny, string>({
      query: (organizationId) =>
        `/vehicles/count-by-deny?${toSearchParams({ organizationId })}`,
      providesTags: (_) => ["VehicleStats"],
    }),
    countVehiclesByState: builder.query<CountByState, string>({
      query: (organizationId) =>
        `/vehicles/count-by-state?${toSearchParams({ organizationId })}`,
      providesTags: (_) => ["VehicleStats"],
    }),
    deleteTechnicalCard: builder.mutation<void, DeleteTechnicalCardRequest>({
      query: ({ publicId, organizationId }) => ({
        url: `/vehicles/${publicId}/technical-card/?${toSearchParams({
          organizationId,
        })}`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["Vehicle"],
    }),
  }),
});

export const getTechnicalCard = (
  publicId: string,
  organizationId: string
): Promise<Blob> => {
  return fetch(
    `${BASE_URL}/api/vehicles/${publicId}/technical-card/?${toSearchParams({
      organizationId,
    })}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authClient.token}`,
      },
    }
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.blob();
  });
};

export const {
  useGetVehiclesQuery,
  useLazyGetVehiclesQuery,
  useGetVehicleByIdQuery,
  useCreateVehicleMutation,
  useDeleteVehicleByIdMutation,
  useDeleteVehiclesByIdsMutation,
  useDenyVehicleMutation,
  useImportVehiclesMutation,
  useUploadTechnicalCardMutation,
  useUpdateVehicleMutation,
  useUpdateVehicleDetailMutation,
  useCountVehiclesByDenyQuery,
  useCountVehiclesByStateQuery,
  useDeleteTechnicalCardMutation,
} = vehiclesApi;
