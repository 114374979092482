import { createListenerMiddleware } from "@reduxjs/toolkit";
import { addAuthListeners } from "./slice/AuthSlice";
import { addDemoListeners } from "./slice/DemoSlice";
import { addTourListeners } from "./slice/TourSlice";
import { AppStartListening } from "./store";

export const listenerMiddleware = createListenerMiddleware();

addAuthListeners(listenerMiddleware.startListening as AppStartListening);
addTourListeners(listenerMiddleware.startListening as AppStartListening);
addDemoListeners(listenerMiddleware.startListening as AppStartListening);
