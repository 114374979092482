import NavBar from "./navbar/Navbar";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { styled } from "@mui/system";
import Footer from "./footer/Footer";
import { Suspense } from "react";
import { useOrganizationBrandingColor } from "../hooks/useOrganizationBrandingColor";

const Layout = () => {
  useOrganizationBrandingColor();
  return (
    <Wrapper>
      <NavBar />
      <Main>
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      </Main>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column",
  minHeight: "100vh",
  height: "100%",
}));

const Main = styled((props: any) => (
  <Container maxWidth="xl" component="main" {...props} />
))(() => ({
  flex: 1,
  paddingTop: "3rem",
  paddingBottom: "3rem",
}));

export default Layout;
