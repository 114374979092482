import React, { FC } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { VehicleDetail } from "../../../redux/api/VehiclesApi";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { ExternalAuthorizeEventResult } from "../../../redux/api/ExternalAuthorizeEvent";

interface VehicleStatusProps {
  data: VehicleDetail;
}

const VehicleStatus: FC<VehicleStatusProps> = ({ data }) => {
  const { t } = useTranslation();

  const renderDenyStatus = () => (
    <Section>
      <Stack direction="row" spacing={2}>
        <Typography variant="body1" color={"text.secondary"}>
          {t("vehicleDetail.status.allowAccess")}:
        </Typography>
        <Typography variant="body1" color={"text.primary"}>
          <UpperCaseText>
            {data.deny
              ? t("vehicleDetail.status.allowAccess.denied")
              : t("vehicleDetail.status.allowAccess.allowed")}
          </UpperCaseText>
        </Typography>
      </Stack>
    </Section>
  );

  const renderApprovalProcessInfo = () => (
    <Section>
      <Stack direction="row" spacing={2}>
        <Typography variant="body1" color={"text.secondary"}>
          {t("vehicleDetail.status.lifecycleStatus")}:
        </Typography>
        <Typography variant="body1" color={"text.primary"}>
          <UpperCaseText>{data.state}</UpperCaseText>
        </Typography>
      </Stack>
      {data.state === "DECLINED" && (
        <Stack direction="row" spacing={2}>
          <Typography variant="body1" color={"text.secondary"}>
            {t("vehicleDetail.status.lifecycleStatus.reasonOfDeclinature")}:
          </Typography>
          <Typography variant="body1" color={"text.primary"}>
            {data.reasonOfDecline}
          </Typography>
        </Stack>
      )}
    </Section>
  );

  const getCalendarAccessStatusValue = () => {
    return t(
      "vehicles.external.access.state." + data.externalLastLoginStatus?.result
    );
  };

  const renderLastCalendarAccessInfo = () => (
    <Section>
      <Stack direction="row" spacing={2}>
        <Typography variant="body1" color={"text.secondary"}>
          {t("vehicleDetail.status.calendarAccessStatus")}:
        </Typography>
        <Typography variant="body1" color={"text.primary"}>
          <UpperCaseText>{getCalendarAccessStatusValue()}</UpperCaseText>
        </Typography>
      </Stack>
      {data.externalLastLoginStatus?.result ===
        ExternalAuthorizeEventResult.FAIL && (
        <Stack direction="row" spacing={2}>
          <Typography variant="body1" color={"text.secondary"}>
            {t("vehicleDetail.status.calendarAccessStatus.reasonOfRejection")}:
          </Typography>
          <Typography variant="body1" color={"text.primary"}>
            {t(
              `vehicle.calendarAccessRejection.reason.${data.externalLastLoginStatus.failReason}`
            )}
          </Typography>
        </Stack>
      )}
    </Section>
  );

  return (
    <StyledCard>
      <CardHeader
        title={
          <Typography color="text.secondary">
            {t("vehicleDetail.status.title")}
          </Typography>
        }
      />
      <StyledCardContent>
        {renderDenyStatus()}
        {renderApprovalProcessInfo()}
        {renderLastCalendarAccessInfo()}
      </StyledCardContent>
    </StyledCard>
  );
};

const StyledCard = styled((props: any) => <Card elevation={0} {...props} />)(
  () => ({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  })
);

const StyledCardContent = styled(CardContent)(() => ({
  flex: 1,
}));

const Section = styled("div")(() => ({
  paddingBottom: "20px",
}));

export const UpperCaseText = styled("span")(() => ({
  textTransform: "uppercase",
}));

export default VehicleStatus;
