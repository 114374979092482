import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userApi, UserOrganization } from "../api/UserApi";
import { organizationApi } from "../api/OrganizationApi";

interface UserSettingsState {
  preferencesLoaded?: boolean;
  organization?: UserOrganization;
}

const initialState: UserSettingsState = {};

const userSettingsSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    selectOrganization: (
      state,
      {
        payload: { organization },
      }: PayloadAction<{ organization: UserOrganization }>
    ) => {
      state.organization = organization;
    },
    updateOrganizationName: (state, { payload }: PayloadAction<string>) => {
      if (state.organization) {
        state.organization.name = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userApi.endpoints.getUser.matchFulfilled,
        (state, { payload: { admin, preferences } }) => {
          if (admin && admin.organizations.length > 0) {
            const singleOrganization =
              !state.organization && admin.organizations.length === 1;

            const multiOrganizationWithPreference =
              !state.organization &&
              admin.organizations.length > 1 &&
              preferences?.login?.organizationId &&
              preferences?.login?.enabled;

            if (singleOrganization) {
              // Select the first organization on the initial load if only one is present
              [state.organization] = admin.organizations;
            } else if (multiOrganizationWithPreference) {
              // Select the preferred organization from the available organizations
              state.organization = admin.organizations.find(
                (o) => o.publicId === preferences.login?.organizationId
              );
            } else if (state.organization) {
              // Refresh the organization name if changed
              const updatedOrganization = admin.organizations.find(
                (organization) =>
                  organization.publicId === state?.organization?.publicId
              );
              // Update the currently selected organization
              if (updatedOrganization) {
                state.organization.name = updatedOrganization.name;
              }
            }
          }
          state.preferencesLoaded = true;
        }
      )
      .addMatcher(
        organizationApi.endpoints.createOrganization.matchFulfilled,
        (state, { payload: { name, publicId } }) => {
          state.organization = {
            name,
            publicId,
          };
        }
      );
  },
});

export const { selectOrganization, updateOrganizationName } =
  userSettingsSlice.actions;

export default userSettingsSlice;
