import { authClient } from "../../authConfig";
import { BASE_URL } from "../../common/constants";
import { Page, PageRequest, toPageSearchParams } from "../../common/pagination";
import { baseApi } from "./baseApi";

type Result = "APPROVED" | "DECLINED";

export interface ApprovalHistory extends VehicleApproval {
  reason: string;
  result: Result;
}

export interface VehicleApproval {
  publicId: string;
  organizationName: string;
  vin: string;
  technicalCardLink: string;
}

interface ApproveVehicleRequest {
  vehiclePublicId: string;
}

interface DeclineVehicleRequest {
  vehiclePublicId: string;
  reason: string;
}

export const vehicleApprovalsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVehicleApprovals: builder.query<
      Page<VehicleApproval[]>,
      PageRequest<VehicleApproval>
    >({
      query: (request) =>
        `vehicle-approvals/vehicles?${toPageSearchParams(request)}`,
      providesTags: (_) => ["VehicleApproval"],
    }),
    getApprovalHistory: builder.query<
      Page<ApprovalHistory[]>,
      PageRequest<ApprovalHistory>
    >({
      query: (request) => `vehicle-approvals?${toPageSearchParams(request)}`,
      providesTags: (_) => ["ApprovalHistory"],
    }),
    approve: builder.mutation<void, ApproveVehicleRequest>({
      query: (args) => ({
        url: `vehicle-approvals`,
        method: "POST",
        body: {
          ...args,
          result: "APPROVED",
        },
      }),
      invalidatesTags: (_) => ["ApprovalHistory", "VehicleApproval", "Vehicle"],
    }),
    decline: builder.mutation<void, DeclineVehicleRequest>({
      query: (args) => ({
        url: `vehicle-approvals`,
        method: "POST",
        body: {
          ...args,
          result: "DECLINED",
        },
      }),
      invalidatesTags: (_) => ["ApprovalHistory", "VehicleApproval", "Vehicle"],
    }),
  }),
});

export const getApprovalTechnicalCard = (
  approvalPublicId: string
): Promise<Blob> => {
  return fetch(
    `${BASE_URL}/api/vehicle-approvals/${approvalPublicId}/technical-card/`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authClient.token}`,
      },
    }
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.blob();
  });
};

export const getVehicleTechnicalCard = (
  vehiclePublicId: string
): Promise<Blob> => {
  return fetch(
    `${BASE_URL}/api/vehicle-approvals/vehicles/${vehiclePublicId}/technical-card/`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authClient.token}`,
      },
    }
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.blob();
  });
};

export const {
  useGetVehicleApprovalsQuery,
  useGetApprovalHistoryQuery,
  useApproveMutation,
  useDeclineMutation,
} = vehicleApprovalsApi;
