import { useTranslation } from "react-i18next";
import {
  DataGrid,
  getGridStringOperators,
  GridColumns,
  GridRowsProp,
} from "@mui/x-data-grid";
import { VehicleRow } from "../../../vehicles/vehicle-table/VehiclesTable";
import React, { FC, useMemo, useState } from "react";
import { VehicleStatisticsEntry } from "../../../../redux/api/StatisticsApi";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Pagination from "../../../pagination/Pagination";
import { tableComponentProps } from "../../../../common/styles";
import { LinearProgress } from "@mui/material";
import { Toolbar } from "../Toolbar";
import { CloseModalIconButton } from "../../../CloseModalIconButton";
import { getTableLocaleText } from "../../../getTableLocaleText";

const pageSizeStorageKey = "vehiclesAccessStatusTablePageSize";
const defaultPageSize = 10;

interface VehiclesAccessStatusModalProps {
  open: boolean;
  onClose: () => void;
  modalType: string;
  vehicles: VehicleStatisticsEntry[] | undefined;
}

const VehiclesAccessStatusModal: FC<VehiclesAccessStatusModalProps> = ({
  open,
  onClose,
  modalType,
  vehicles,
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(() => {
    const preferredPageSize = Number(localStorage.getItem(pageSizeStorageKey));
    return isNaN(defaultPageSize) || preferredPageSize === 0
      ? defaultPageSize
      : preferredPageSize;
  });

  const mapDataToTable = (
    vehicleStatisticsEntries: VehicleStatisticsEntry[]
  ): GridRowsProp<VehicleRow> => {
    return vehicleStatisticsEntries.map(({ publicId, ...rest }) => ({
      ...rest,
      id: publicId,
    }));
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "vin",
        headerName: "VIN",
        flex: 1,
        minWidth: 100,
        filterOperators: getGridStringOperators().filter(
          (op) => op.value === "contains"
        ),
      },
    ],
    [t]
  );

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    localStorage.setItem(pageSizeStorageKey, `${size}`);
  };

  const rows = useMemo(
    () => (vehicles ? mapDataToTable(vehicles) : []),
    [vehicles]
  );

  const localeText = getTableLocaleText();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {`${t("dashboard.vehicles.title")} / ${t(
          "dashboard.vehicles.accessStatus." + modalType.toLowerCase()
        )}`}
        <CloseModalIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <DataGrid
          localeText={localeText}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          rows={rows}
          rowCount={vehicles?.length || 0}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          components={{
            Toolbar,
            LoadingOverlay: LinearProgress,
            Pagination,
          }}
          disableSelectionOnClick
          autoHeight
          componentsProps={tableComponentProps}
          disableColumnFilter
        />
      </DialogContent>
    </Dialog>
  );
};

export default VehiclesAccessStatusModal;
