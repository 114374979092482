import { styled } from "@mui/system";
import {
  GridApi,
  gridColumnDefinitionsSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridVisibleColumnDefinitionsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import { Dispatch, FC, SetStateAction } from "react";
import { Button, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ViewColumn as ViewColumnIcon } from "@mui/icons-material/";
import AddAdmin from "./add-admin/AddAdmin";

interface ToolbarProps {
  setFilterButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const Toolbar: FC<ToolbarProps> = ({ setFilterButtonEl }) => {
  const apiRef = useGridApiContext<GridApi>();
  const { t } = useTranslation();
  const visibleColumnsLength = gridVisibleColumnDefinitionsSelector(
    apiRef
  ).filter(
    (column) => column.type !== "actions" && column.type !== "checkboxSelection"
  ).length;

  const onShowAll = () => {
    const columns = gridColumnDefinitionsSelector(apiRef).map((col) =>
      col.hideable !== false ? { field: col.field, hide: false } : col
    );
    apiRef.current.updateColumns(columns);
  };

  return (
    <Wrapper>
      <Fade in={visibleColumnsLength === 0}>
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          onClick={onShowAll}
          startIcon={<ViewColumnIcon />}
        >
          {t("table.show.columns")}
        </Button>
      </Fade>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
      <AddAdmin />
    </Wrapper>
  );
};

const Wrapper = styled(GridToolbarContainer)(({ theme }) => ({
  justifyContent: "flex-end",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

export default Toolbar;
