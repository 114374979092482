import { CircularProgress } from "@mui/material";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { RootState } from "../redux/store";
import {
  authorizationRoute,
  getRoutesByRole,
  isPathAllowed,
} from "./navbar/routes";

const AuthorizationRoute: FC<RouteProps> = () => {
  const {
    role,
    roles,
    identity,
    technicalUser,
    preferencesLoaded: rolePreferencesLoaded,
  } = useAuth();
  const location = useLocation();
  const { organization, preferencesLoaded: settingsPreferencesLoaded } =
    useSelector((state: RootState) => state.userSettings);

  const isAdminWithoutOrganization = () =>
    identity === "verified" &&
    role === "ROLE_organization_admin" &&
    organization === undefined;

  const isUnverifiedUser = () => identity === "unverified" && !role;

  const isLoading = () => {
    const nonTechnicalUser = identity === "verified" && !technicalUser;
    return (
      !identity ||
      identity === "pending" ||
      !roles ||
      (nonTechnicalUser && !rolePreferencesLoaded) ||
      (nonTechnicalUser && !settingsPreferencesLoaded)
    );
  };

  if (isUnverifiedUser()) {
    // Unverified user needs to select the initial role to proceed
    return <Navigate to={authorizationRoute.path} replace />;
  }

  if (isLoading()) {
    // Keep waiting until the initialization is finished
    return <CircularProgress />;
  }

  if (role === undefined || isAdminWithoutOrganization()) {
    // Display the Authorization page if any of the mandatory preference is missing
    return <Navigate to={authorizationRoute.path} replace />;
  }

  const routes = getRoutesByRole(role);
  const allowed = isPathAllowed(location.pathname, role);

  return allowed ? <Outlet /> : <Navigate to={routes[0].path} replace />;
};

export default AuthorizationRoute;
