import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { Suspense } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useGetOrganizationQuery } from "../../redux/api/OrganizationApi";
import NavigationLinks from "./NavigationLinks";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const NavigationMenu = () => {
  const { user, technicalUser, identity, role } = useAuth();
  const organization = useSelector(
    (state: RootState) => state.userSettings.organization
  );

  const { data } = useGetOrganizationQuery(
    !user || technicalUser === true || identity !== "verified" || !organization
      ? skipToken
      : organization.publicId
  );

  return (
    <Suspense fallback={<></>}>
      <Wrapper>
        {user && role && !data?.deletionTime && (
          <DesktopMenu>
            <NavigationLinks />
          </DesktopMenu>
        )}
      </Wrapper>
    </Suspense>
  );
};

const Wrapper = styled(Box)(() => ({
  flexGrow: 1,
}));

const DesktopMenu = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

export default NavigationMenu;
