import { Check as CheckIcon } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { selectRole } from "../../redux/slice/AuthSlice";
import { Role } from "../navbar/routes";
import AccountMenuItem from "./AccountMenuItem";

interface RoleSelectorSectionProps {
  onClick?: () => void;
}

const RoleSelectorSection: FC<RoleSelectorSectionProps> = ({ onClick }) => {
  const { role, roles: rolesState } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onMenuItemClick = (selectedRole: Role) => () => {
    if (onClick) {
      onClick();
    }
    if (role !== selectedRole) {
      dispatch(selectRole({ role: selectedRole }));
    }
  };

  // Data is immutable so we create a new array to have sorting later on
  const roles = [...rolesState];

  return (
    <>
      {roles.sort().map((entry) => {
        const checked = role === entry;
        return (
          <AccountMenuItem
            key={entry}
            checked={checked}
            onClick={onMenuItemClick(entry)}
          >
            {checked && (
              <ListItemIcon>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
            )}
            <ListItemText inset={!checked}>
              <Typography variant="inherit" noWrap>
                {t(`roleSelector.${entry}.name`)}
              </Typography>
            </ListItemText>
          </AccountMenuItem>
        );
      })}
    </>
  );
};

export default RoleSelectorSection;
