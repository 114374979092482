import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCreateVehicleMutation } from "../../redux/api/VehiclesApi";
import { validateSpecialCharacters } from "../validateSpecialCharacters";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";

interface FormValues {
  vin: string;
  licensePlate: string;
  description: string;
}

const vinRegExp = /^(TMB|WAU|BSK)[A-HJ-NPR-Z0-9]{14}$/;

const CreateVehicle = () => {
  const { t } = useTranslation();
  const router = useNavigate();
  const [createVehicle] = useCreateVehicleMutation();
  const organization = useSelectedOrganization();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();

  const onSubmit = async (values: FormValues) => {
    const newVehicle = {
      vin: values.vin.trim(),
      licensePlate: values.licensePlate.trim(),
      description: values.description.trim(),
    };

    await createVehicle({
      vehicle: newVehicle,
      organizationId: organization.publicId,
    })
      .unwrap()
      .then(() => router("/vehicles"))
      .catch(handleError);
  };

  const handleError = (errorResponse: any) => {
    if (errorResponse.status === 409) {
      setError("vin", {
        type: "custom",
        message: t(`${errorResponse.data.message}`),
      });
    }
  };

  const cancelOnClick = () => {
    router("/vehicles");
  };

  return (
    <>
      <Container component={"main"} maxWidth={"xs"}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label="VIN"
            autoComplete="vin"
            autoFocus
            error={!!errors.vin}
            helperText={errors.vin?.message}
            {...register("vin", {
              required: {
                value: true,
                message: t("validation.vin.blank"),
              },
              pattern: {
                value: vinRegExp,
                message: t("validation.vin.invalid"),
              },
            })}
          />
          <TextField
            margin="normal"
            fullWidth
            label={t("vehicles.licensePlate")}
            error={!!errors.licensePlate}
            helperText={errors.licensePlate?.message}
            {...register("licensePlate", {
              validate: validateSpecialCharacters("licensePlate"),
            })}
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="description"
            label={t("vehicles.description")}
            multiline
            error={!!errors.description}
            helperText={errors.description?.message}
            {...register("description", {
              validate: validateSpecialCharacters("description"),
            })}
            inputProps={{ maxLength: 1000 }}
          />
          <Button
            data-cy="confirm.create.vehicle"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("create")}
          </Button>
          <Button
            style={{ marginTop: 0 }}
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={cancelOnClick}
          >
            {t("cancel")}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default CreateVehicle;
