import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetVehicleByIdQuery } from "../../redux/api/VehiclesApi";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import VehicleGrid from "./vehicle-grid/VehicleGrid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";

const VehicleDetail = () => {
  const { t } = useTranslation();
  const router = useNavigate();
  const params = useParams();
  const langCountryCode = localStorage.getItem("i18nextLng");
  const language = langCountryCode
    ?.substring(0, 2)
    .toUpperCase()
    .replace("CS", "CZ"); //CS does not work on IA Services BE

  const organization = useSelectedOrganization();

  const { data } = useGetVehicleByIdQuery({
    id: params.vehicleId as string,
    language: language as string,
    organizationId: organization.publicId,
  });
  const cancelOnClick = () => {
    router("/vehicles");
  };
  return (
    <StyledBox>
      {data && <VehicleGrid data={data} />}
      <Button
        data-cy="cancel"
        variant="outlined"
        sx={{ mt: 3, mb: 2, ml: "auto", mr: "auto", display: "block" }}
        onClick={cancelOnClick}
      >
        {t("cancel")}
      </Button>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export default VehicleDetail;
