import { alpha, createTheme, PaletteMode, useTheme } from "@mui/material";
import { grey, common } from "@mui/material/colors";
//DO NOT REMOVE
import type {} from "@mui/x-data-grid/themeAugmentation";
import { darken, lighten } from "@material-ui/core/styles";

export const primaryColorDefault = "hsl(106, 56%, 42%)";
export const secondaryColorDefault = "hsl(0, 0%, 44%)";
export const darkBorder = "#424242";
export const darkBackground = "hsl(120, 54%, 2%)";
export const emphasizedDarkBackground = "hsl(195, 10%, 13%)";

const themeBase = (primaryColor: string, mode: PaletteMode) =>
  createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: "140px",
            minHeight: "45px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          contained: ({ ownerState }) => ({
            borderRadius: "22.5px",
            ...(ownerState.color === "primary" && {
              boxShadow: primaryColor,
              ":hover": {
                boxShadow: primaryColor,
              },
              ":disabled": {
                backgroundColor:
                  mode === "dark" ? emphasizedDarkBackground : undefined,
              },
            }),
          }),
          outlined: ({ ownerState }) => ({
            borderRadius: "22.5px",
            ...(ownerState.color === "secondary" && {
              color: mode === "dark" ? grey[400] : undefined,
              borderColor:
                mode === "dark"
                  ? darken(primaryColor, 0.3)
                  : "rgba(74,74,74,.2)",
              ":hover, :focus": {
                borderColor:
                  mode === "dark" ? lighten(primaryColor, 0.1) : "#4a4a4a",
                backgroundColor:
                  mode === "dark" ? darken(primaryColor, 0.9) : "transparent",
                color: mode === "dark" ? common.white : undefined,
              },
            }),
          }),
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            minHeight: "45px",
            minWidth: "125px",
            borderRadius: "22.5px",
            boxShadow: primaryColor,
            ":hover": {
              boxShadow: primaryColor,
            },
          },
        },
      },
      MuiAppBar: {
        defaultProps: {
          color: "default",
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
        ::selection {
          background-color: ${primaryColor};
          color: #fff;
        }
      `,
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: secondaryColorDefault,
            ":hover": {
              color: primaryColor,
            },
            ":focus": {
              color: primaryColor,
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 0,
            ".MuiDataGrid-cell:focus-within": {
              outline: "none",
              backgroundColor: alpha(primaryColor, 0.2),
            },
            ".MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
              outline: "none",
              backgroundColor: alpha(primaryColor, 0.2),
            },
            ".MuiDataGrid-columnHeader:focus": {
              outline: "none",
              backgroundColor: alpha(primaryColor, 0.2),
            },
            "@media print": {
              ".MuiDataGrid-columnHeaders": {
                border: "none",
              },
              ".MuiDataGrid-main": {
                ".MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
                  minWidth: "100px !important",
                  maxWidth: "100px !important",
                },
                ".MuiDataGrid-cellContent": {
                  overflow: "auto",
                  whiteSpace: "normal",
                  wordBreak: "break-all",
                },
              },
            },
          },
          menu: {
            ".MuiPaper-root": {
              borderRadius: "9px",
            },
            ".MuiMenuItem-root": {
              textTransform: "uppercase",
              fontSize: "0.8rem",
              letterSpacing: "0.4px",
              padding: "0.65rem 1rem",
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: "1.5rem",
            fontWeight: "bold",
            textTransform: "uppercase",
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            "&:not(:last-child)": {
              // Extra spacing for cases when theres an action inside the content
              marginBottom: "0.5rem",
            },
            color: "inherit",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "0.5rem 1rem 1rem",
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          fontFamily: "'SKODA Next', Verdana, sans-serif",
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fieldset: {
              borderColor:
                mode === "dark" ? darken(primaryColor, 0.5) : undefined,
            },
            "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
              borderColor: mode === "dark" ? primaryColor : undefined,
            },
          },
        },
      },
    },
    typography: {
      fontFamily: "'SKODA Next', Verdana, sans-serif",
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      h1: {
        fontSize: "clamp(1.7rem, 2.5vw + 1rem, 2.5rem)",
        lineHeight: "2.875rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        letterSpacing: ".01562rem",
        marginBottom: "1.5rem",
        "@media (min-width: 900px)": {
          marginBottom: "3.5rem",
        },
      },
      h2: {
        fontSize: "clamp(2.25rem, 4vw + 1rem, 3.125rem)",
        fontWeight: "bold",
        lineHeight: "clamp(2.875rem, 3vw + 1rem, 3.75rem)",
        letterSpacing: "0.01562rem",
        padding: 0,
        margin: "0 0 1.25rem",
        textTransform: "uppercase",
      },
      h3: {
        fontSize: "clamp(1rem, 0.75vw + 1rem, 1.5rem)",
        lineHeight: "1.5rem",
        fontWeight: "normal",
        textTransform: "uppercase",
        letterSpacing: ".01562rem",
      },
      subtitle1: {
        fontSize: "1.25rem",
        lineHeight: "1.875rem",
        fontWeight: "normal",
        letterSpacing: ".01562rem",
      },
      body1: {
        letterSpacing: ".01562rem",
      },
      h5: {
        textTransform: "uppercase",
      },
    },
    palette: {
      mode,
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColorDefault,
      },
      text: {
        primary: "hsl(0, 0%, 0%)",
        secondary: secondaryColorDefault,
      },
    },
  });

const themeDark = {
  palette: {
    background: {
      default: darkBackground,
      paper: emphasizedDarkBackground,
    },
    text: {
      primary: "#FFF",
    },
    action: {
      disabled: darkBorder,
      disabledBackground: "#1A1A1A",
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: darkBackground,
          borderBottom: `1px solid ${grey[600]}`,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          ".MuiDataGrid-footerContainer": {
            borderColor: darkBorder,
          },
          ".MuiDataGrid-iconSeparator": {
            color: darkBorder,
          },
          ".MuiDataGrid-columnHeaders": {
            borderColor: darkBorder,
          },
          ".MuiDataGrid-cell": {
            borderColor: darkBorder,
          },
          ".MuiDataGrid-row": {
            ":hover": {
              backgroundColor: "#1A1A1A",
            },
          },
          ".MuiDataGrid-row.Mui-selected": {
            backgroundColor: darkBorder,
            ":hover": {
              backgroundColor: "#5C5C5C",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ".Mui-disabled": {
            borderColor: "#424242",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: "hsl(0, 0%, 80%)",
          "& button": {
            color: "hsl(0, 0%, 56%)",
            borderColor: "hsl(0, 0%, 56%)",
          },
          "& button:hover, & button:focus": {
            color: "hsl(0, 0%, 100%)",
            borderColor: "hsl(0, 0%, 100%)",
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0.5rem 1rem 1rem",
          "& > button": {
            color: "hsl(0, 0%, 100%)",
            borderColor: "hsl(0, 0%, 56%)",
          },
        },
      },
    },
  },
};

const allowed = "#89c156";
const active = allowed;
const authorized = active;
const pending = "#f1ea1c";
const unused = pending;
const denied = "#f51b1e";
const declined = denied;

export const byDenyTheme = {
  palette: {
    pie: [allowed, denied],
  },
};

export const byActivationSelfConfirmedTheme = {
  palette: {
    pie: [pending, active],
  },
};

export const byStateTheme = {
  palette: {
    pie: [pending, active, declined],
  },
};

export const byAccessTheme = {
  palette: {
    pie: [authorized, denied, unused],
  },
};

export function useNivoTheme() {
  const muiTheme = useTheme();
  return {
    textColor: muiTheme.palette.text.primary,
    tooltip: {
      container: {
        background: muiTheme.palette.background.default,
      },
    },
  };
}

export function resolveTheme(primaryColor: string, mode: PaletteMode) {
  if (!primaryColor) {
    primaryColor = primaryColorDefault;
  }

  const themeMode = mode === "light" ? {} : themeDark;

  return createTheme(themeBase(primaryColor, mode), themeMode);
}
