import React, { Dispatch, FC, SetStateAction } from "react";
import { Box, Button, Fade, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import {
  GridApi,
  gridColumnDefinitionsSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridVisibleColumnDefinitionsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UploadButton from "./UploadButton";
import DownloadAssociatesButton from "./DownloadAssociatesButton";
import { ViewColumn as ViewColumnIcon } from "@mui/icons-material/";
import ExpandContainerButton from "../../../common/small-screen/ExpandContainerButton";
import { TableActions } from "../../../common/styles";

interface ToolbarProps {
  setFilterButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  setDisplayAll: Dispatch<SetStateAction<boolean>>;
  displayAll: boolean;
}

const Toolbar: FC<ToolbarProps> = ({
  setFilterButtonEl,
  displayAll,
  setDisplayAll,
}) => {
  const { t } = useTranslation();

  const apiRef = useGridApiContext<GridApi>();
  const visibleColumnsLength = gridVisibleColumnDefinitionsSelector(
    apiRef
  ).filter(
    (column) => column.type !== "actions" && column.type !== "checkboxSelection"
  ).length;

  const onShowAll = () => {
    const columns = gridColumnDefinitionsSelector(apiRef).map((col) =>
      col.hideable !== false ? { field: col.field, hide: false } : col
    );
    apiRef.current.updateColumns(columns);
  };

  return (
    <Wrapper>
      <Box></Box>
      <TableActions>
        {visibleColumnsLength === 0 && (
          <Fade in>
            <Tooltip title={t("table.show.columns.tooltip")}>
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                onClick={onShowAll}
                startIcon={<ViewColumnIcon />}
              >
                {t("table.show.columns")}
              </Button>
            </Tooltip>
          </Fade>
        )}
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <UploadButton />
        <DownloadAssociatesButton />
        <Link to="/associates/create">
          <Tooltip title={t("associates.create.tooltip")}>
            <Button data-cy="create.associate" variant="contained">
              {t("associates.create")}
            </Button>
          </Tooltip>
        </Link>
        <ExpandContainerButton
          setDisplayAll={setDisplayAll}
          displayAll={displayAll}
        />
      </TableActions>
    </Wrapper>
  );
};

const Wrapper = styled(GridToolbarContainer)(({ theme }) => ({
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    // Always position the buttons to the top (especially if wrapping)
    alignItems: "flex-start",
  },
}));

export default Toolbar;
