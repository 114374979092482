import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import {
  useDeleteLogoMutation,
  useGetOrganizationBrandingQuery,
} from "../../redux/api/OrganizationBrandingApi";
import { enqueueSnackbar } from "notistack";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";

const DeleteLogoButton = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const { data: branding } = useGetOrganizationBrandingQuery(
    organization.publicId
  );
  const [deleteLogo] = useDeleteLogoMutation();

  const tooltip = t("settings.organization.logo.delete");

  const onDeleteLogo = () => {
    deleteLogo({ organizationId: organization.publicId })
      .unwrap()
      .then(() => {
        enqueueSnackbar(t("settings.organization.logo.deleted"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t(`${error.data.message}`), {
          variant: "error",
        });
      });
  };

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onDeleteLogo} disabled={!branding?.logoURL}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteLogoButton;
