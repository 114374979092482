import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridRowsProp,
  GridSortModel,
} from "@mui/x-data-grid";
import {
  useGetVehiclesQuery,
  Vehicle,
} from "../../../../redux/api/VehiclesApi";
import { VehicleRow } from "../../../vehicles/vehicle-table/VehiclesTable";
import Pagination from "../../../pagination/Pagination";
import { tableComponentProps } from "../../../../common/styles";
import { PageRequest } from "../../../../common/pagination";
import { Toolbar } from "../Toolbar";
import { CloseModalIconButton } from "../../../CloseModalIconButton";
import { getTableLocaleText } from "../../../getTableLocaleText";
import { useSelectedOrganization } from "../../../../hooks/useSelectedOrganization";

const pageSizeStorageKey = "vehiclesModalTablePageSize";
const defaultPageSize = 10;

interface VehiclesModalProps {
  open: boolean;
  onClose: () => void;
  subTitle: string;
  filterField: keyof Vehicle;
  filterValue: string;
}

const VehiclesModal: FC<VehiclesModalProps> = ({
  open,
  onClose,
  subTitle,
  filterField,
  filterValue,
}) => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();

  const [queryOptions, setQueryOptions] = useState<PageRequest<Vehicle>>(() => {
    const preferredPageSize = Number(localStorage.getItem(pageSizeStorageKey));
    const size =
      isNaN(defaultPageSize) || preferredPageSize === 0
        ? defaultPageSize
        : preferredPageSize;
    return {
      page: 0,
      size,
      filterField: filterField,
      filterOperator: "equals",
      filterValue: filterValue,
      organizationId: organization.publicId,
    };
  });
  const { data, isFetching, isLoading } = useGetVehiclesQuery(queryOptions);

  const mapDataToTable = (vehicles: Vehicle[]): GridRowsProp => {
    return vehicles?.map((vehicle) => {
      return {
        id: vehicle.publicId,
        vin: vehicle.vin,
        deny: vehicle.deny,
      } as VehicleRow;
    });
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "vin",
        headerName: "VIN",
        flex: 1,
        minWidth: 100,
      },
    ],
    [t]
  );

  const rows = useMemo(
    () => (data ? mapDataToTable(data?.content) : []),
    [data]
  );

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
    localStorage.setItem(pageSizeStorageKey, `${size}`);
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof Vehicle,
      sortOrder: sort?.sort,
    });
  };

  const title = `${t("dashboard.vehicles.title")} / ${subTitle}`;

  const localeText = getTableLocaleText();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {title}
        <CloseModalIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <DataGrid
          localeText={localeText}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          rows={rows}
          rowCount={data?.totalElements || 0}
          columns={columns}
          page={queryOptions.page}
          onPageChange={onPageChange}
          pageSize={queryOptions.size}
          onPageSizeChange={onPageSizeChange}
          paginationMode="server"
          onSortModelChange={onSortModelChange}
          sortingMode="server"
          components={{
            Toolbar,
            LoadingOverlay: LinearProgress,
            Pagination,
          }}
          loading={isFetching || isLoading}
          disableSelectionOnClick
          autoHeight
          componentsProps={tableComponentProps}
          disableColumnFilter
        />
      </DialogContent>
    </Dialog>
  );
};

export default VehiclesModal;
