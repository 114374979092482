import { createSlice } from "@reduxjs/toolkit";
import { organizationSettingsApi } from "../api/OrganizationSettingsApi";
import { primaryColorDefault } from "../../common/theme";
import { organizationBrandingApi } from "../api/OrganizationBrandingApi";
import { selectRole } from "./AuthSlice";

export const VERIFY_VIN_KEY = "verifyVin";

const initialColor: string =
  sessionStorage.getItem("primaryColor") || primaryColorDefault;

interface OrganizationSettingsState {
  verifyVinSettingPublicId?: string;
  verifyVin: boolean;
  color: string;
}

const initialState: OrganizationSettingsState = {
  color: initialColor,
  verifyVin: true,
};

const organizationSettingsSlice = createSlice({
  name: "organizationSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(selectRole, (state) => {
        state.color = primaryColorDefault;
      })
      .addMatcher(
        organizationSettingsApi.endpoints.getOrganizationSettings
          .matchFulfilled,
        (state, { payload }) => {
          const verifyVinSetting = payload.find(
            (state) => state.key === VERIFY_VIN_KEY
          );
          state.verifyVin = verifyVinSetting?.value === "Y";
          state.verifyVinSettingPublicId = verifyVinSetting?.publicId;
        }
      );
    builder.addMatcher(
      organizationBrandingApi.endpoints.getOrganizationBranding.matchFulfilled,
      (state, { payload }) => {
        state.color = payload.uiColorCode || initialColor;
      }
    );
  },
});

export default organizationSettingsSlice;
