import React, { FC, Suspense } from "react";
import { Vehicle } from "../../redux/api/VehiclesApi";
import { useTranslation } from "react-i18next";
import { mapVehicleDataToCsv, translateHeaders } from "./VehiclesExportHelper";

const CSVDownload = React.lazy(() =>
  import("react-csv").then(({ CSVDownload }) => ({ default: CSVDownload }))
);

interface VehicleExportProps {
  content: Vehicle[];
}

const VehiclesExport: FC<VehicleExportProps> = ({ content }) => {
  const { t } = useTranslation();

  const vehicleRows = mapVehicleDataToCsv(content, t);
  return (
    <>
      <Suspense fallback={<></>}>
        <CSVDownload
          data={vehicleRows}
          filename={new Date().toISOString() + ".csv"}
          headers={translateHeaders(t)}
        />
      </Suspense>
    </>
  );
};

export default VehiclesExport;
