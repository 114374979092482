import {
  DeleteForever as DeleteIcon,
  Attachment as AttachmentIcon,
} from "@mui/icons-material";
import {
  CircularProgress,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  styled,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { downloadBlob } from "../../../redux/api/util";
import {
  ApproveState,
  getTechnicalCard,
  useDeleteTechnicalCardMutation,
} from "../../../redux/api/VehiclesApi";
import { RootState } from "../../../redux/store";
import TechnicalCardUploadButton from "../TechnicalCardUploadButton";
import { VehicleRow } from "./VehiclesTable";

interface VerificationModalProps {
  handleCancelDialog: () => void;
  isDialogOpened: boolean;
  row: VehicleRow;
}

const VerificationModal: FC<VerificationModalProps> = ({
  handleCancelDialog,
  isDialogOpened,
  row: { id, technicalCardUploaded, state, reasonOfDecline },
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteTechnicalCard, { isLoading: deleting }] =
    useDeleteTechnicalCardMutation();
  const organization = useSelector(
    (state: RootState) => state.userSettings.organization
  );

  const [downloading, setDownloading] = useState(false);

  const onCancel = () => {
    handleCancelDialog();
  };

  const onDeleteClick = () => {
    if (id && organization) {
      deleteTechnicalCard({
        publicId: id,
        organizationId: organization.publicId,
      });
    }
  };

  const onDownloadClick = async () => {
    if (id && organization) {
      setDownloading(true);
      getTechnicalCard(id, organization.publicId)
        .then((data) => downloadBlob(data, "technical-card"))
        .catch((e: any) => {
          enqueueSnackbar(t("verification.download.error"), {
            variant: "error",
            preventDuplicate: true,
            key: e.status,
          });
        })
        .finally(() => {
          setDownloading(false);
        });
    }
  };

  return (
    <Dialog
      open={isDialogOpened}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t("verification")}</DialogTitle>
      <DialogContent>
        <Description>{t("verification.description")}</Description>
        <DialogContentText>
          {t("vehicles.approval")}: {t(`vehicles.approve.state.${state}`)}
        </DialogContentText>
        {reasonOfDecline && (
          <DialogContentText>
            {t("vehicles.approval.reason")}: {reasonOfDecline}
          </DialogContentText>
        )}
      </DialogContent>
      <Actions>
        <Stack direction="row" spacing={0} alignItems="center">
          {technicalCardUploaded && (
            <Tooltip title={t("verification.download.tc")}>
              <IconButton
                size="medium"
                onClick={onDownloadClick}
                disabled={downloading}
              >
                {downloading ? (
                  <CircularProgress size={22.5} />
                ) : (
                  <AttachmentIcon />
                )}
              </IconButton>
            </Tooltip>
          )}
          {state !== ApproveState.ACTIVE && (
            <>
              <TechnicalCardUploadButton id={id} />
              {technicalCardUploaded && (
                <Tooltip title={t("verification.delete.tc")}>
                  <IconButton
                    size="medium"
                    onClick={onDeleteClick}
                    disabled={deleting}
                  >
                    {deleting ? (
                      <CircularProgress size={22.5} />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </Stack>
        <Button
          onClick={onCancel}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {t("close")}
        </Button>
      </Actions>
    </Dialog>
  );
};

export const Actions = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}));

export const Description = styled(DialogContentText)(() => ({
  "&:not(:last-child)": {
    marginBottom: "1.25rem",
  },
}));

export default VerificationModal;
