import {
  VehicleValidationError,
  VehicleValidationErrors,
} from "../../redux/api/VehiclesApi";
import {
  AssociateValidationError,
  AssociateValidationErrors,
} from "../../redux/api/AssociatesApi";
import { TFunction } from "react-i18next";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";

export const translateHeaders = (t: TFunction<string>): LabelKeyObject[] => {
  return [
    { label: t("table.export.errors.row"), key: "row" },
    { label: t("table.export.errors.cause"), key: "cause" },
  ];
};

interface ErrorRow {
  row: string;
  cause: string;
}

export const mapErrorsToCsv = (
  errors: VehicleValidationErrors | AssociateValidationErrors,
  t: TFunction<string>
): ErrorRow[] => {
  const elements = Object.entries(errors);
  return elements.flatMap(([key, value]) => {
    return value.map(
      (error: VehicleValidationError | AssociateValidationError) => {
        return {
          row: key || t("validation.item"),
          cause: t(`validation.${error}`),
        };
      }
    );
  });
};
