import { darken, lighten } from "@material-ui/core/styles";
import { MenuItem, MenuItemProps } from "@mui/material";
import { styled } from "@mui/system";

export const maxChars = 45;

type AccountMenuItemProps = MenuItemProps & {
  checked?: boolean;
  inactive?: boolean;
};

const AccountMenuItem = styled(
  ({ checked, inactive, ...rest }: AccountMenuItemProps) => (
    <MenuItem disableRipple {...rest} />
  )
)(({ theme, checked, inactive }) => ({
  margin: theme.spacing(0.5),
  maxWidth: `${maxChars}ch`,
  ...(checked && {
    borderRadius: theme.shape.borderRadius,
    backgroundColor:
      theme.palette.mode === "dark"
        ? darken(theme.palette.primary.main, 0.35)
        : lighten(theme.palette.primary.main, 0.35),
    ":hover": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? darken(theme.palette.primary.main, 0.35)
          : lighten(theme.palette.primary.main, 0.35),
    },
  }),
  ...(inactive && {
    cursor: "revert",
    "&:hover": {
      background: "initial",
    },
  }),
}));

export default AccountMenuItem;
