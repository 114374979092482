import {
  Box,
  Button,
  Fade,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  GridApi,
  gridColumnDefinitionsSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridVisibleColumnDefinitionsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { ViewColumn as ViewColumnIcon } from "@mui/icons-material/";
import ExpandContainerButton from "../../../common/small-screen/ExpandContainerButton";

interface ToolbarProps {
  setFilterButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  setDisplayAll: Dispatch<SetStateAction<boolean>>;
  displayAll: boolean;
}

const Toolbar: FC<ToolbarProps> = ({
  setFilterButtonEl,
  displayAll,
  setDisplayAll,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const view =
    pathname === "/vehicles/approvals/history" ? "history" : "vehicles";
  const apiRef = useGridApiContext<GridApi>();
  const visibleColumnsLength = gridVisibleColumnDefinitionsSelector(
    apiRef
  ).filter(
    (column) => column.type !== "actions" && column.type !== "checkboxSelection"
  ).length;

  const onShowAll = () => {
    const columns = gridColumnDefinitionsSelector(apiRef).map((col) =>
      col.hideable !== false ? { field: col.field, hide: false } : col
    );
    apiRef.current.updateColumns(columns);
  };

  return (
    <Wrapper>
      <Box>
        <ToggleButtonGroup color="primary" value={view} exclusive size="medium">
          <ToggleButton
            component={Link as any}
            value="vehicles"
            to="/vehicles/approvals"
          >
            {t("vehicles.approval.vehicles.tab")}
          </ToggleButton>
          <ToggleButton
            component={Link as any}
            value="history"
            to="/vehicles/approvals/history"
          >
            {t("vehicles.approval.history.tab")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <TableActions>
        <Fade in={visibleColumnsLength === 0}>
          <Button
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={onShowAll}
            startIcon={<ViewColumnIcon />}
          >
            {t("table.show.columns")}
          </Button>
        </Fade>
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <ExpandContainerButton
          setDisplayAll={setDisplayAll}
          displayAll={displayAll}
        />
      </TableActions>
    </Wrapper>
  );
};

const Wrapper = styled(GridToolbarContainer)(({ theme }) => ({
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  marginBottom: "1rem",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

const TableActions = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

export default Toolbar;
