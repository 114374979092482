import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import useFileUpload from "../../../hooks/useFileUpload";
import {
  useImportVehiclesMutation,
  VehicleValidationErrors,
} from "../../../redux/api/VehiclesApi";
import * as React from "react";
import ImportErrorDialog from "../../modal/ImportErrorDialog";
import { Tooltip } from "@mui/material";
import { TooltipTable } from "../../../common/styles";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";

const columnNames = ["vin", "description", "licensePlate"] as const;
type ColumnName = typeof columnNames[number];
const columns: Record<ColumnName, { translation: string; required: boolean }> =
  {
    vin: {
      translation: "vin",
      required: true,
    },
    description: {
      translation: "vehicles.detail",
      required: false,
    },
    licensePlate: {
      translation: "vehicles.licensePlate",
      required: false,
    },
  };

const UploadButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [importVehicles, { isLoading }] = useImportVehiclesMutation();
  const organization = useSelectedOrganization();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [validationErrors, setValidationErrors] =
    React.useState<VehicleValidationErrors>({});

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const title = t("upload.tooltip");

  const tooltip = t("vehicles.upload.tooltip");

  const onFileSelected = async (files: File[] | FileList) => {
    try {
      const vehicles = await importVehicles({
        file: files[0],
        organizationId: organization.publicId,
      }).unwrap();
      enqueueSnackbar(
        t("upload.successful", {
          count: vehicles.length,
        }),
        {
          variant: "success",
        }
      );
    } catch (err: any) {
      let message = "upload.failed";
      if (err.data?.message) {
        if (err.data?.validationErrors) {
          const errors: VehicleValidationErrors = err.data?.validationErrors;
          setValidationErrors(errors);
          setModalOpen(true);
        }
        message = err.data?.message;
      }
      enqueueSnackbar(t(message), {
        variant: "error",
        key: err.status,
        preventDuplicate: true,
      });
    }
  };

  const { openDialog } = useFileUpload({
    inputRef: fileInputRef,
    onFileSelected,
  });

  return (
    <>
      <Tooltip
        title={
          <>
            {tooltip}
            <TooltipTable>
              <tbody>
                <tr>
                  {columnNames.map((name) => (
                    <th key={name}>
                      {name}
                      {columns[name].required ? "*" : ""}
                    </th>
                  ))}
                </tr>
                <tr>
                  {columnNames.map((name) => (
                    <td key={name}>{t(columns[name].translation)}</td>
                  ))}
                </tr>
              </tbody>
            </TooltipTable>
          </>
        }
        placement="top"
      >
        <LoadingButton
          variant="outlined"
          size="medium"
          onClick={openDialog}
          startIcon={<FileUploadIcon />}
          loading={isLoading}
          loadingPosition="start"
          color="secondary"
        >
          {title}
        </LoadingButton>
      </Tooltip>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        multiple={false}
        hidden
      />
      <ImportErrorDialog
        onClose={handleModalClose}
        open={modalOpen}
        entries={validationErrors}
      />
    </>
  );
};

export default UploadButton;
