import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useMemo, useState } from "react";

const useSmallScreenProperties = (
  reducedColumnVisibilityModel: GridColumnVisibilityModel
): [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  GridColumnVisibilityModel,
  (model: GridColumnVisibilityModel) => void
] => {
  const [displayAll, setDisplayAll] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const shouldReturnReducedModel = isSmallScreen && !displayAll;
  const [selectedColumnVisibilityModel, setSelectedColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();
  const resolvedColumnVisibilityModel = useMemo(() => {
    return (
      selectedColumnVisibilityModel ||
      (shouldReturnReducedModel ? reducedColumnVisibilityModel : {})
    );
  }, [selectedColumnVisibilityModel, shouldReturnReducedModel]);
  return [
    displayAll,
    setDisplayAll,
    resolvedColumnVisibilityModel,
    setSelectedColumnVisibilityModel,
  ];
};

export default useSmallScreenProperties;
