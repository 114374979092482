import React, { Dispatch, FC, SetStateAction } from "react";
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";

interface PropsType {
  setDisplayAll: Dispatch<SetStateAction<boolean>>;
  displayAll: boolean;
}

const ExpandContainerButton: FC<PropsType> = ({
  setDisplayAll,
  displayAll,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const text = displayAll ? t("table.collapse") : t("table.expand");
  if (!isSmallScreen) {
    return <></>;
  }
  return (
    <Button
      variant="outlined"
      size="medium"
      onClick={() => setDisplayAll(!displayAll)}
    >
      {text}
    </Button>
  );
};

export default ExpandContainerButton;
