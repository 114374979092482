import { LinearProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGrid, GridColumns, GridSortModel } from "@mui/x-data-grid";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { tableComponentProps } from "../../../../common/styles";
import { PageRequest } from "../../../../common/pagination";
import {
  Associate,
  useGetAssociatesQuery,
} from "../../../../redux/api/AssociatesApi";
import { mapToGridRow } from "../../../associates/table/AssociateHelpers";
import Pagination from "../../../pagination/Pagination";
import { Toolbar } from "../Toolbar";
import { CloseModalIconButton } from "../../../CloseModalIconButton";
import { getTableLocaleText } from "../../../getTableLocaleText";
import { useSelectedOrganization } from "../../../../hooks/useSelectedOrganization";

const pageSizeStorageKey = "associatesModalTablePageSize";
const defaultPageSize = 10;

interface AssociatesModalProps {
  open: boolean;
  onClose: () => void;
  subTitle: string;
  filterField: keyof Associate;
  filterValue: string;
}

const AssociatesModal: FC<AssociatesModalProps> = ({
  open,
  onClose,
  subTitle,
  filterField,
  filterValue,
}) => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const [queryOptions, setQueryOptions] = useState<PageRequest<Associate>>(
    () => {
      const preferredPageSize = Number(
        localStorage.getItem(pageSizeStorageKey)
      );
      const size =
        isNaN(defaultPageSize) || preferredPageSize === 0
          ? defaultPageSize
          : preferredPageSize;
      return {
        page: 0,
        size,
        filterField: filterField,
        filterOperator: "equals",
        filterValue: filterValue,
        organizationId: organization.publicId,
      };
    }
  );
  const { data, isFetching, isLoading } = useGetAssociatesQuery(queryOptions);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("name"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "emailAddress",
        headerName: t("associate.email"),
        flex: 1,
        minWidth: 100,
      },
    ],
    [t]
  );

  const rows = useMemo(() => (data ? mapToGridRow(data?.content) : []), [data]);

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
    localStorage.setItem(pageSizeStorageKey, `${size}`);
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof Associate,
      sortOrder: sort?.sort,
    });
  };

  const title = `${t("dashboard.associates.title")} / ${subTitle}`;

  const localeText = getTableLocaleText();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {title}
        <CloseModalIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <DataGrid
          localeText={localeText}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          rows={rows}
          rowCount={data?.totalElements || 0}
          columns={columns}
          page={queryOptions.page}
          onPageChange={onPageChange}
          pageSize={queryOptions.size}
          onPageSizeChange={onPageSizeChange}
          paginationMode="server"
          onSortModelChange={onSortModelChange}
          sortingMode="server"
          components={{
            Toolbar,
            LoadingOverlay: LinearProgress,
            Pagination,
          }}
          loading={isFetching || isLoading}
          disableSelectionOnClick
          autoHeight
          componentsProps={tableComponentProps}
          disableColumnFilter
        />
      </DialogContent>
    </Dialog>
  );
};

export default AssociatesModal;
