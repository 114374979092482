import { useGetAssociateByIdQuery } from "../../redux/api/AssociatesApi";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";

const AssociateDetail = () => {
  const { t } = useTranslation();
  const router = useNavigate();
  const params = useParams();
  const organization = useSelectedOrganization();
  const { data } = useGetAssociateByIdQuery({
    id: params.associateId as string,
    organizationId: organization.publicId,
  });

  const cancelOnClick = () => {
    router("/associates");
  };
  return (
    <>
      <div>Associate detail {data?.emailAddress}</div>
      <Button
        data-cy="cancel"
        variant="outlined"
        sx={{ mt: 3, mb: 2, ml: "auto", mr: "auto", display: "block" }}
        onClick={cancelOnClick}
      >
        {t("cancel")}
      </Button>
    </>
  );
};

export default AssociateDetail;
