import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Routing from "./components/Routing";
import { CssBaseline } from "@mui/material";
import "./assets/styles/skodaNextFonts.scss";
import "./i18n";
import Tour from "./components/tour/Tour";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { authClient, authClientInitOptions, onAuthEvent } from "./authConfig";
import ApplicationColorsProvider from "./common/ApplicationColorsContext";

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={authClient}
      initOptions={authClientInitOptions}
      onEvent={onAuthEvent}
    >
      <Provider store={store}>
        <ApplicationColorsProvider>
          <CssBaseline />
          <BrowserRouter>
            <Suspense fallback={<></>}>
              <Tour />
            </Suspense>
            <Routing />
          </BrowserRouter>
        </ApplicationColorsProvider>
      </Provider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
