import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NavigationMenu from "./NavigationMenu";
import NavbarLogo from "./NavbarLogo";
import NavbarActions from "./NavbarActions";
import { Container } from "@mui/material";
import OrganizationLogo from "./OrganizationLogo";

const NavBar = () => {
  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <NavbarLogo />
          <NavigationMenu />
          <NavbarActions />
          <OrganizationLogo />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
