import { Grid, IconButton, Stack, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { ColorChangeHandler, SketchPicker } from "react-color";
import UploadLogoButton from "./table/UploadLogoButton";
import { CSSProperties, FC, useMemo, useState } from "react";
import { useApplicationColors } from "../../common/ApplicationColorsContext";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";
import { primaryColorDefault } from "../../common/theme";
import { useSetOrganizationBrandingMutation } from "../../redux/api/OrganizationBrandingApi";
import RenameOrganization from "./RenameOrganization";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";
import DeleteLogoButton from "./DeleteLogoButton";

const colorPickerStylePopover: CSSProperties = {
  position: "absolute",
  zIndex: 2,
};

const colorPickerStyleCover: CSSProperties = {
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};

export const BrandingSettings: FC = () => {
  const { t } = useTranslation();
  const [setOrganizationBranding] = useSetOrganizationBrandingMutation();

  const organization = useSelectedOrganization();
  const settingsColor = useSelector(
    (state: RootState) => state.organizationSettings.color
  );
  const [pickerColor, setPickerColor] = useState(settingsColor);
  useMemo(() => setPickerColor(settingsColor), [settingsColor]);

  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const handleColorChange: ColorChangeHandler = (color) => {
    setPickerColor(color.hex);
  };
  const applicationColors = useApplicationColors();

  const organizationColorTitle = t("settings.organization.color");
  const organizationColorPickTitle = t("settings.organization.color.pick");
  const organizationLogoTitle = t("settings.organization.logo");

  const handleColorPickerButtonClick = () => {
    setColorPickerOpen(!colorPickerOpen);
  };

  const handleColorPickerButtonClose = () => {
    setColorPickerOpen(false);
  };

  const onColorChangeComplete: ColorChangeHandler = (color) => {
    if (color != null) {
      applyColor(color.hex);
    }
  };
  const resetColor = () => {
    applyColor(primaryColorDefault);
  };
  const applyColor = (primaryColor: string) => {
    setOrganizationBranding({
      uiColorCode: primaryColor,
      organizationId: organization.publicId,
    })
      .unwrap()
      .then(() => {
        applicationColors?.changeColors(primaryColor);
      });
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        {t("settings.organization.name")}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid
          container
          sx={{
            flexDirection: "row",
            alignItems: "center",
            wrap: "nowrap",
          }}
          spacing={2}
        >
          <Grid item>{organization?.name}</Grid>
          <Grid item>
            <RenameOrganization />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        {organizationColorTitle}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack direction={"row"} spacing={1}>
          <Tooltip title={t("settings.organization.color.pick.tooltip")}>
            <Button
              onClick={handleColorPickerButtonClick}
              variant="outlined"
              size="medium"
              color="secondary"
            >
              {organizationColorPickTitle}
            </Button>
          </Tooltip>
          <Tooltip title={t("settings.color.reset")}>
            <IconButton
              onClick={resetColor}
              disabled={pickerColor === primaryColorDefault}
            >
              <FormatColorResetIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        {colorPickerOpen ? (
          <div style={colorPickerStylePopover}>
            <div
              style={colorPickerStyleCover}
              onClick={handleColorPickerButtonClose}
            />
            <SketchPicker
              color={pickerColor}
              onChange={handleColorChange}
              onChangeComplete={onColorChangeComplete}
            />
          </div>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {organizationLogoTitle}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack direction={"row"} spacing={1}>
          <UploadLogoButton />
          <DeleteLogoButton />
        </Stack>
      </Grid>
    </>
  );
};
