import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface InputErrorModalProps {
  open: boolean;
  onClose: () => void;
  message: string;
}

const InputErrorModal: FC<InputErrorModalProps> = ({
  open,
  onClose,
  message,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
      <DialogContent>
        <DialogContentText>
          <Typography
            component={"span"}
            display="flex"
            alignItems="center"
            margin="0 !important"
          >
            <ErrorOutlineIcon color="error" sx={{ marginRight: "0.5rem" }} />
            {message}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          data-cy="modal.update.confirmation"
          variant="contained"
          size="medium"
          color="primary"
        >
          {t("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputErrorModal;
