import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  FormControlLabel,
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FormPageDescription,
  FormPageSubmitButton,
  FormPageTitle,
  FormPageWrapper,
} from "../../common/styles";
import { useAuth } from "../../hooks/useAuth";
import {
  useGetUserQuery,
  useUpdatePreferencesMutation,
} from "../../redux/api/UserApi";
import { getValidatedRole, selectRole } from "../../redux/slice/AuthSlice";
import { selectOrganization } from "../../redux/slice/UserSettingsSlice";
import { dashboardRoute, Role } from "../navbar/routes";

interface FormValues {
  role?: Role;
  organizationId?: string;
  remember: boolean;
}

const AuthorizationComponent = () => {
  const { user, roles, identity, role } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useGetUserQuery(
    !user || identity !== "verified" ? skipToken : undefined
  );
  const [updatePreferences] = useUpdatePreferencesMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValues,
  } = useForm<FormValues>({ defaultValues: { organizationId: "" } });

  const defaultValues = useMemo(() => {
    const role =
      getValidatedRole(roles, data?.preferences?.login?.role) || ("" as Role);
    const organizationId = data?.preferences?.login?.organizationId || "";
    const remember = !!data?.preferences?.login?.enabled;

    return {
      role,
      organizationId,
      remember,
    };
  }, [data]);

  const getSelectedOrganization = (id: string) =>
    data?.admin?.organizations.find((o) => o.publicId === id);

  const onSubmit = async ({ organizationId, remember, role }: FormValues) => {
    if (role) {
      dispatch(selectRole({ role }));
    }

    if (organizationId) {
      const selected = getSelectedOrganization(organizationId);
      if (selected) {
        dispatch(selectOrganization({ organization: selected }));
      }
    }

    if (identity === "verified") {
      // Only execute preferences update for completely registered users
      await updatePreferences({
        organizationId,
        role,
        enabled: remember,
      }).unwrap();
    }

    navigate(dashboardRoute.path);
  };

  const selectedRole = watch("role");

  const showRoleSelector = roles.length > 1;

  const showOrganizationSelector =
    selectedRole !== "ROLE_approver" &&
    role !== "ROLE_approver" &&
    data?.admin?.organizations &&
    data?.admin?.organizations.length > 1;

  const displayRememberMe = identity === "verified";

  useEffect(() => {
    reset({
      ...getValues(),
      ...defaultValues,
    });
  }, [defaultValues]);

  return (
    <FormPageWrapper maxWidth="xs">
      <FormPageTitle variant="h1">{t("authorization.title")}</FormPageTitle>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormPageDescription>
          {t("authorization.description")}
        </FormPageDescription>

        <Stack spacing={2}>
          {showRoleSelector && (
            <FormControl required error={!!errors.role} hiddenLabel fullWidth>
              <Controller
                name="role"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("validation.role.blank"),
                  },
                }}
                render={({ field }) => (
                  <StyledToggleButtonGroup
                    aria-required
                    color="primary"
                    exclusive
                    size="medium"
                    aria-label={t("authorization.role")}
                    aria-invalid={!!errors.role}
                    {...field}
                  >
                    {roles.map((role) => (
                      <ToggleButton key={role} value={role}>
                        {t(`roleSelector.${role}.name`)}
                      </ToggleButton>
                    ))}
                  </StyledToggleButtonGroup>
                )}
              />
              <FormHelperText>{errors.role?.message}</FormHelperText>
            </FormControl>
          )}

          {showOrganizationSelector && (
            <Controller
              name="organizationId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("validation.organization.blank"),
                },
              }}
              render={({ field }) => (
                <FormControl required error={!!errors.organizationId} fullWidth>
                  <InputLabel id="organization-label">
                    {t("authorization.organization")}
                  </InputLabel>
                  <Select
                    required
                    labelId="organization-label"
                    label={t("authorization.organization")}
                    {...field}
                  >
                    {data?.admin?.organizations.map((o) => (
                      <MenuItem key={o.publicId} value={o.publicId}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.organizationId?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}

          {displayRememberMe && (
            <Controller
              name="remember"
              control={control}
              defaultValue={defaultValues.remember}
              render={({ field }) => (
                <FormControl error={!!errors.remember} hiddenLabel fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label={t("authorization.remember")}
                  />
                </FormControl>
              )}
            />
          )}
        </Stack>

        <FormPageSubmitButton
          color="primary"
          type="submit"
          variant="contained"
          size="medium"
          fullWidth
        >
          {t("ok")}
        </FormPageSubmitButton>
      </Box>
    </FormPageWrapper>
  );
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    minWidth: "auto",
    wordBreak: "break-word",
    flex: 1,
  },
  "&[aria-invalid='true'] .MuiToggleButtonGroup-grouped": {
    borderColor: theme.palette.error.main,
  },
}));

export default AuthorizationComponent;
