import { Associate } from "../../../redux/api/AssociatesApi";
import { GridRowModel, GridRowsProp } from "@mui/x-data-grid";
import { AssociateRow } from "./AssociatesTable";

export const mapToGridRow = (
  associates: Associate[]
): GridRowsProp<AssociateRow> =>
  associates?.map(
    ({
      publicId,
      activationSelfConfirmed,
      externalLastLoginStatus,
      ...rest
    }) => ({
      ...rest,
      id: publicId,
      activationSelfConfirmed: activationSelfConfirmed ? "true" : "false",
      externalLastLoginResult: externalLastLoginStatus?.result,
      externalLastLoginReason: externalLastLoginStatus?.failReason,
    })
  );

export const mapToEntity = (row: GridRowModel<AssociateRow>): Associate => ({
  publicId: row.id,
  name: row.name,
  deny: row.deny,
  activationSelfConfirmed: "true" === row.activationSelfConfirmed,
});

export const emailRegExp = new RegExp(
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
);
