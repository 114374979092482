import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  GridApi,
  gridFilterModelSelector,
  gridSortModelSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import { Operator, PageRequest } from "../../../common/pagination";
import { useTranslation } from "react-i18next";
import {
  Associate,
  useLazyGetAssociatesQuery,
} from "../../../redux/api/AssociatesApi";
import AssociatesExport from "../../csv-export/AssociatesExport";
import { Tooltip } from "@mui/material";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

const prepareRequest = (
  apiRef: React.MutableRefObject<GridApi>,
  organizationId: string
): PageRequest<Associate> => {
  // don't reflect pagination
  const sortModel = gridSortModelSelector(apiRef);
  const filterModel = gridFilterModelSelector(apiRef);
  const filter = filterModel.items[0];
  const sort = sortModel[0];
  return {
    filterField: filter?.columnField as keyof Associate,
    filterOperator: filter?.operatorValue as Operator,
    filterValue: filter?.value,
    sortField: sort?.field as keyof Associate,
    sortOrder: sort?.sort,
    organizationId: organizationId,
  };
};

const DownloadAssociatesButton = () => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext<GridApi>();
  const [timestamp, setTimestamp] = useState(0);
  const [trigger, { data, isLoading }] = useLazyGetAssociatesQuery();
  const organization = useSelector(
    (state: RootState) => state.userSettings.organization
  );

  const getData = async () => {
    if (organization) {
      await trigger(prepareRequest(apiRef, organization.publicId));
      setTimestamp(Date.now);
    }
  };

  return (
    <>
      <Tooltip title={t("table.export.csv.tooltip")}>
        <LoadingButton
          variant="outlined"
          size="medium"
          onClick={getData}
          startIcon={<FileDownloadIcon />}
          loading={isLoading}
          loadingPosition="start"
          color="secondary"
        >
          {t("table.export.csv")}
          {!!timestamp && data && (
            // CSVDownload triggers download on mount
            <AssociatesExport key={timestamp} content={data.content} />
          )}
        </LoadingButton>
      </Tooltip>
    </>
  );
};

export default DownloadAssociatesButton;
