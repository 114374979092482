import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

export function CreateButton() {
  const { t } = useTranslation();
  return (
    <Link to="/vehicles/create">
      <Tooltip title={t("vehicles.create.tooltip")}>
        <Button data-cy="create.vehicle" variant="contained">
          {t("vehicles.create")}
        </Button>
      </Tooltip>
    </Link>
  );
}
