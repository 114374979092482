import {
  GridApi,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import TablePagination, {
  LabelDisplayedRowsArgs,
} from "@mui/material/TablePagination";
import { ChangeEvent, MouseEvent, useMemo } from "react";
import {
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { createSelector } from "@mui/x-data-grid/utils/createSelector";
import { GridStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

const Pagination = () => {
  const apiRef = useGridApiContext<GridApi>();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(
    apiRef,
    createSelector(
      (state: GridStateCommunity) => state.pagination,
      (p) => p.rowCount
    )
  );
  const { t } = useTranslation();

  const pages = useMemo(
    () => (pageCount === 0 ? [0] : Array.from(Array(pageCount).keys())),
    [pageCount]
  );

  const onPageChange = (
    _: MouseEvent | SelectChangeEvent | ChangeEvent | null,
    value: number
  ) => {
    apiRef.current.setPage(value);
  };

  const onGoToPage = (e: SelectChangeEvent) => {
    const page = Number(e.target.value);
    if (page > -1 && page < pageCount) {
      onPageChange(e, page);
    }
  };

  const onRowsPerPageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const size = Number(e.target.value);
    apiRef.current.setPageSize(size);
    onPageChange(e, 0);
  };

  return (
    <Stack direction="row" spacing={2}>
      <PageLabel color="inherit" variant="caption">
        {t("table.pagination.page")}
      </PageLabel>
      <Select
        variant="standard"
        value={`${page}`}
        onChange={onGoToPage}
        input={<SelectInput />}
      >
        {pages.map((p) => (
          <MenuItem key={p} value={p} disabled={p === page}>
            {p + 1}
          </MenuItem>
        ))}
      </Select>
      <StyledPagination
        count={rowCount}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={onRowsPerPageChange}
        labelRowsPerPage={t("table.pagination.rowsperpage")}
        labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) =>
          t("table.pagination.displayed", {
            from: paginationInfo.from,
            to: paginationInfo.to,
            total: paginationInfo.count,
          })
        }
        getItemAriaLabel={(type: string) =>
          t("table.pagination.goto", { page: t(`table.pagination.${type}`) })
        }
      />
    </Stack>
  );
};

const SelectInput = styled(InputBase)(() => ({
  fontSize: "0.875rem",
  fontWeight: 400,
  textAlign: "center",
}));

const StyledPagination = styled((props: any) => (
  <TablePagination component="div" {...props} />
))(() => ({
  "& > .MuiToolbar-root": {
    paddingLeft: 0,
  },
}));

const PageLabel = styled(Typography)(() => ({
  fontSize: "0.875rem",
  lineHeight: "1.43",
  fontWeight: 400,
  marginTop: "14px",
  marginBottom: "14px",
}));

export default Pagination;
