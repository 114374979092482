import ViewWrapper from "./ViewWrapper";
import UploadButton from "../../associates/table/UploadButton";
import { useTranslation } from "react-i18next";

const UploadAssociates = () => {
  const { t } = useTranslation()
  const title = t("dashboard.upload.associates.title")
  return (
    <ViewWrapper id="upload-associates" title={title} align="center">
      <UploadButton />
    </ViewWrapper>
  );
};

export default UploadAssociates;