import { useTranslation } from "react-i18next";
import { VehicleValidationErrors } from "../../redux/api/VehiclesApi";
import { AssociateValidationErrors } from "../../redux/api/AssociatesApi";
import { ErrorList } from "./ErrorList";
import DownloadErrorsButton from "./DownloadErrorsButton";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";

export interface ImportErrorDialogProps {
  open: boolean;
  entries: VehicleValidationErrors | AssociateValidationErrors;
  onClose: () => void;
}

const ImportErrorDialog = (props: ImportErrorDialogProps) => {
  const { t } = useTranslation();
  const { onClose, entries, open } = props;
  if (Object.keys(entries).length === 0) return <></>;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{t("import.failed")}</DialogTitle>
      <ErrorList errors={entries} />
      <DialogActions>
        <DownloadErrorsButton data={entries} />
      </DialogActions>
    </Dialog>
  );
};
export default ImportErrorDialog;
