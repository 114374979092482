import { Button, Card, CardMedia, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ViewWrapper from "./ViewWrapper";
import VideoModal from "./VideoModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { finish, start } from "../../../redux/slice/DemoSlice";

const DemoVideo = () => {
  const { t } = useTranslation();
  const title = t("dashboard.demo.title");
  const tooltip = t("dashboard.demo.tooltip");
  const { finished } = useSelector((state: RootState) => state.demo);
  const dispatch = useDispatch();

  const closeVideo = () => {
    dispatch(finish());
  };

  const openVideo = () => {
    dispatch(start());
  };

  return (
    <ViewWrapper id="demo-video" title={title} align="center">
      {finished !== undefined && (
        <VideoModal isOpen={!finished} onClose={closeVideo} />
      )}
      <Tooltip title={tooltip}>
        <Button onClick={openVideo} sx={{ padding: 0 }}>
          <Card>
            <CardMedia component="img" image="/images/video.png" height="200" />
          </Card>
        </Button>
      </Tooltip>
    </ViewWrapper>
  );
};

export default DemoVideo;
