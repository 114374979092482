import Grid from "@mui/material/Grid";
import { Switch, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  SettingUpdate,
  useSetVerifyVinSettingMutation,
} from "../../redux/api/OrganizationSettingsApi";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";

const OrganizationSettings = () => {
  const { t } = useTranslation();
  const [updateVerifyVinSetting] = useSetVerifyVinSettingMutation();
  const verifyVin = useSelector(
    (state: RootState) => state.organizationSettings.verifyVin
  );
  const verifyVinSettingPublicId = useSelector(
    (state: RootState) => state.organizationSettings.verifyVinSettingPublicId
  );
  const organization = useSelectedOrganization();

  const toggleVerifyVin = () => {
    const update: SettingUpdate = {
      shouldVerify: !verifyVin,
      publicId: verifyVinSettingPublicId || "",
      organizationId: organization.publicId,
    };
    updateVerifyVinSetting(update).unwrap();
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <span>{t("settings.verifyVin")}</span>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title={t("settings.verifyVin.description")}>
          <Switch
            data-cy={"verifyVinToggle"}
            checked={verifyVin}
            onChange={toggleVerifyVin}
          />
        </Tooltip>
      </Grid>
    </>
  );
};

export default OrganizationSettings;
