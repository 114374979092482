import { Outlet, Route, Routes } from "react-router-dom";
import AssociatesComponent from "./associates/AssociatesComponent";
import SignUp from "./account/SignUp";
import SignIn from "./account/SignIn";
import Layout from "./Layout";
import ProtectedRoute from "./ProtectedRoute";
import AssociateDetail from "./associates/AssociateDetail";
import CreateAssociate from "./associates/CreateAssociate";
import VehiclesComponent from "./vehicles/VehiclesComponent";
import CreateVehicle from "./vehicles/CreateVehicle";
import VehicleDetail from "./vehicles/VehicleDetail";
import Settings from "./settings/Settings";
import Landing from "./landing/Landing";
import LandingLayout from "./LandingLayout";
import DashboardComponent from "./dashboard/DashboardComponent";
import AboutMeComponent from "./about-me/AboutMeComponent";
import IdentityComponent from "./identity/IdentityComponent";
import MyVehicleComponent from "./my-vehicle/MyVehicleComponent";
import VehiclesTable from "./vehicles/vehicle-table/VehiclesTable";
import AssociatesTable from "./associates/table/AssociatesTable";
import ApprovalVehiclesComponent from "./vehicles/vehicle-approval/ApprovalVehiclesComponent";
import ApprovalHistoryComponent from "./vehicles/vehicle-approval/ApprovalHistoryComponent";
import Confirmation from "./confirmation/Confirmation";
import { Suspense } from "react";
import NotFound from "./error/NotFound";
import AccountComponent from "./account/AccountComponent";
import DeactivatedComponent from "./deactivated/DeactivatedComponent";
import ActiveOrganizationRoute from "./ActiveOrganizationRoute";
import AuthorizationComponent from "./authorization/AuthorizationComponent";
import AuthorizationRoute from "./AuthorizationRoute";

const Routing = () => {
  return (
    <Routes>
      <Route>
        <Route element={<LandingLayout />}>
          <Route index element={<Landing />} />
        </Route>
        <Route
          path="confirmation/:id"
          element={
            <Suspense fallback={<></>}>
              <Confirmation />
            </Suspense>
          }
        />
        <Route path="login" element={<SignIn />} />
        <Route element={<Layout />}>
          <Route path="register" element={<SignUp />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<AuthorizationRoute />}>
              <Route element={<ActiveOrganizationRoute />}>
                <Route element={<Outlet />}>
                  <Route path="dashboard" element={<DashboardComponent />} />
                  <Route path="associates" element={<AssociatesComponent />}>
                    <Route index element={<AssociatesTable />} />
                    <Route
                      path="detail/:associateId"
                      element={<AssociateDetail />}
                    />
                    <Route path="create" element={<CreateAssociate />} />
                  </Route>
                  <Route path="vehicles" element={<VehiclesComponent />}>
                    <Route index element={<VehiclesTable />} />
                    <Route
                      path="detail/:vehicleId"
                      element={<VehicleDetail />}
                    />
                    <Route path="create" element={<CreateVehicle />} />
                    <Route path="approvals">
                      <Route index element={<ApprovalVehiclesComponent />} />
                      <Route
                        path="history"
                        element={<ApprovalHistoryComponent />}
                      />
                    </Route>
                  </Route>
                  <Route path="my-vehicle" element={<MyVehicleComponent />} />
                  <Route path="organization-settings" element={<Settings />} />
                </Route>
              </Route>
              <Route path="account" element={<AccountComponent />} />
              <Route path="about-me" element={<AboutMeComponent />} />
              <Route path="identity" element={<IdentityComponent />} />
              <Route path="deactivated" element={<DeactivatedComponent />} />
            </Route>
            <Route path="authorization" element={<AuthorizationComponent />} />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<></>}>
              <NotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default Routing;
