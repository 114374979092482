import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import {Icon, Link, Tooltip} from "@mui/material";
import { styled } from "@mui/system";
import { FC, MouseEvent } from "react";

interface ExternalLinkProps {
  href?: string;
  text?: string;
  onClick?: (e: MouseEvent) => void;
  tooltipTitle?: string;
}

const ExternalLink: FC<ExternalLinkProps> = ({ href, text, onClick, tooltipTitle= ""}) => {
  return (
      //if tooltipTitle is empty, tooltip will not be displayed at all
      <Tooltip title={tooltipTitle}>
        <Wrapper
          href={href}
          rel="noreferrer"
          target="_blank"
          onClick={onClick}
          tabIndex={0}
        >
          <LinkText>{text || href}</LinkText>
          <LinkIcon fontSize="small">
            <OpenInNewIcon fontSize="small" />
          </LinkIcon>
        </Wrapper>
      </Tooltip>
  );
};

const Wrapper = styled(Link)(() => ({
  display: "inline-flex",
  alignItems: "center",
}));

const LinkText = styled("span")(() => ({
  display: "block",
  height: "1.1rem", // Including the link underline
  maxWidth: "250px",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const LinkIcon = styled(Icon)(() => ({
  display: "block",
  margin: "0 0.125rem",
}));

export default ExternalLink;
