import { baseApi } from "./baseApi";

export const confirmationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    confirm: builder.mutation<void, string>({
      query: (id) => ({
        url: `/associate-confirmation/${id}/confirm`,
        method: "POST",
      }),
      invalidatesTags: (_) => ["Confirmation"],
    }),
  }),
});

export const { useConfirmMutation } = confirmationApi;
