import { Link as RouteLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/brand/logo.svg";
import { styled } from "@mui/system";
import { SvgIcon } from "@mui/material";
import { grey } from "@mui/material/colors";
import { getRoutesByRole } from "./routes";
import { useAuth } from "../../hooks/useAuth";

const NavbarLogo = () => {
  const { role } = useAuth();
  const routes = getRoutesByRole(role);
  const to = routes && routes.length > 0 ? routes[0].path : "/";
  return (
    <HomeLink to={to}>
      <SvgIcon component={Logo} inheritViewBox fontSize="large" />
    </HomeLink>
  );
};

const HomeLink = styled(RouteLink)(({ theme }) => {
  // Subtle light color on dark mode and default colors on light mode
  const fill = theme.palette.mode === "dark" ? grey[200] : undefined;
  return {
    display: "flex",
    svg: {
      width: "9.25rem",

      "#Lettering": {
        fill,
      },
      "#Inner_Ring_Background": {
        fill,
      },
      "#Inner_Ring_Edge": {
        fill,
      },
    },
    marginRight: "2.25rem",
  };
});

export default NavbarLogo;
