import { baseApi } from "./baseApi";
import { toSearchParams } from "./util";

export enum AccessStatus {
  AUTHORIZED = "AUTHORIZED",
  REJECTED = "REJECTED",
  UNUSED = "UNUSED",
}

export interface VehicleStatisticsEntry {
  publicId: string;
  vin: string;
}

export interface VehicleAccessStatistics {
  authorizedVehicles?: VehicleStatisticsEntry[];
  rejectedVehicles?: VehicleStatisticsEntry[];
  unusedVehicles?: VehicleStatisticsEntry[];
}

export interface AssociateStatisticsEntry {
  publicId: string;
  name: string;
  emailAddress: string;
}

export interface AssociateAccessStatistics {
  authorizedAssociates?: AssociateStatisticsEntry[];
  rejectedAssociates?: AssociateStatisticsEntry[];
  unusedAssociates?: AssociateStatisticsEntry[];
}

export const statisticsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVehiclesAccessStatistics: builder.query<VehicleAccessStatistics, string>(
      {
        query: (organizationId) =>
          `/statistics/vehicles/access?${toSearchParams({ organizationId })}`,
        providesTags: (_) => ["VehicleStats"],
      }
    ),
    getAssociatesAccessStatistics: builder.query<
      AssociateAccessStatistics,
      string
    >({
      query: (organizationId) =>
        `/statistics/associates/access?${toSearchParams({ organizationId })}`,
      providesTags: (_) => ["AssociateStats"],
    }),
  }),
});

export const {
  useGetVehiclesAccessStatisticsQuery,
  useGetAssociatesAccessStatisticsQuery,
} = statisticsApi;
