import Typography from "@mui/material/Typography";

export function Copyright(props: any) {
  return (
    <Typography variant="body2" {...props}>
      &copy; ŠKODA AUTO a.s.&nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}
