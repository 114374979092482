import { baseApi, OrganizationRequest } from "./baseApi";
import { Page, PageRequest, toPageSearchParams } from "../../common/pagination";
import { toSearchParams } from "./util";

export interface Admin {
  publicId: string;
  name?: string;
  email?: string;
}

export interface CheckAdminResponse {
  exists: boolean;
}

interface IntroState {
  tourFinished: boolean;
  demoFinished: boolean;
}

interface RegisterAdminRequest extends OrganizationRequest {
  email: string;
  name: string;
}

interface DeleteRequest extends OrganizationRequest {
  id: string;
}

export const adminsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    checkAdminExistence: builder.query<CheckAdminResponse, void>({
      query: () => ({
        url: "/admins/check-admin",
        method: "GET",
      }),
    }),
    getAdmins: builder.query<Page<Admin[]>, PageRequest<Admin>>({
      query: (request) => {
        return `/admins?${toPageSearchParams(request)}`;
      },
      providesTags: (_) => ["Admin"],
    }),
    registerAdmin: builder.mutation<void, RegisterAdminRequest>({
      query: ({ organizationId, ...registerAdminRequest }) => ({
        url: `/admins/register-admin?${toSearchParams({ organizationId })}`,
        method: "POST",
        body: registerAdminRequest,
      }),
      invalidatesTags: (_) => ["Associate", "Admin"],
    }),
    deleteAdminById: builder.mutation<void, DeleteRequest>({
      query: ({ id, organizationId }) => ({
        url: `/admins/${id}?${toSearchParams({ organizationId })}`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["Admin", "Associate"],
    }),
    getIntroState: builder.query<IntroState, void>({
      query: () => {
        return "/admins/intro/state";
      },
    }),
    setTourState: builder.mutation<void, boolean>({
      query: (finished) => ({
        url: "/admins/intro/state/tour-finished",
        method: "PUT",
        body: {
          finished,
        },
      }),
    }),
    setDemoState: builder.mutation<void, boolean>({
      query: (finished) => ({
        url: "/admins/intro/state/demo-finished",
        method: "PUT",
        body: {
          finished,
        },
      }),
    }),
    // the point of this method is to prevent the admin data from being re-fetched after the admin losing the
    // auth to access them, resulting in error being returned from backend and logout being prevented
    deleteAdminByIdNoInvalidation: builder.mutation<void, DeleteRequest>({
      query: ({ id, organizationId }) => ({
        url: `/admins/${id}?${toSearchParams({ organizationId })}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useRegisterAdminMutation,
  useDeleteAdminByIdMutation,
  useDeleteAdminByIdNoInvalidationMutation,
} = adminsApi;
