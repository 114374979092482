import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface ConfirmUpdateModalProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmUpdateModal: FC<ConfirmUpdateModalProps> = ({
  open,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
      <DialogTitle id="alert-dialog-title">
        {t("save.confirm.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("save.confirm.description")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {t("cancel")}
        </Button>
        <Button
          data-cy="modal.update.confirmation"
          onClick={onConfirm}
          variant="contained"
          size="medium"
          color="primary"
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUpdateModal;
