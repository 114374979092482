import { Button, DialogActions } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Step, TooltipRenderProps } from "react-joyride";

interface FooterProps extends TooltipRenderProps {
  continuous: boolean;
  index: number;
  step: Step;
}

const Footer: FC<FooterProps> = ({
  continuous,
  index,
  step,
  size,
  backProps,
  primaryProps,
  skipProps,
}) => {
  const { t } = useTranslation();
  const showSkipButton = step.showSkipButton;
  const showBackButton = index > 0 && !step.hideBackButton;
  const showNextButton = continuous;
  const showFinishButton = index === size - 1;

  return (
    <DialogActions>
      {!showFinishButton && (
        <>
          <div>
            {showSkipButton && (
              <Button variant="outlined" size="medium" {...skipProps}>
                {t("tooltip.skip")}
              </Button>
            )}
            {showBackButton && (
              <Button variant="outlined" size="medium" {...backProps}>
                {t("tooltip.back")}
              </Button>
            )}
          </div>

          {showNextButton && (
            <div>
              <Button variant="contained" size="medium" {...primaryProps}>
                {`${t("tooltip.next")} ${
                  step.showProgress ? `(${index + 1}/${size})` : ""
                }`}
              </Button>
            </div>
          )}
        </>
      )}
      {showFinishButton && (
        <Button variant="contained" size="medium" {...primaryProps}>
          {t("tooltip.finish")}
        </Button>
      )}
    </DialogActions>
  );
};

export default Footer;
