import React, { FC } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Stack,
    Typography,
} from "@mui/material";
import { IASVehicleData } from "../../../redux/api/VehiclesApi";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import {paintToWebColor} from '../../../common/styles';
import {Circle as CircleIcon} from '@mui/icons-material';

interface VehicleEquipmentProps {
    iasVehicleData?: IASVehicleData;
    colorCode?: string;
}

const VehicleEquipment: FC<VehicleEquipmentProps> = ({ iasVehicleData, colorCode}) => {

    const { t } = useTranslation();

    const renderEquipmentRow = (label: string, value: string, viewColorPreview: boolean=false) => (
        <Stack direction="row" spacing={2}>
            <Typography variant="body1" color={"text.secondary"}>
                {label}:
            </Typography>
            <Typography variant="body1" color={"text.primary"}>
                {value}
            </Typography>
            {viewColorPreview && renderColorPreview()}
        </Stack>
    )

    const renderColorPreview = () => {
        const color =  colorCode && paintToWebColor(colorCode);
        return (
            color && <CircleIcon sx={{ color }} />
        );
    }

    const renderEquipment = () => (
        iasVehicleData &&
        <StyledCardContent>
            <Stack direction="column" spacing={2}>
                {renderEquipmentRow(t("vehicleDetail.equipment.model"), iasVehicleData.model)}
                {renderEquipmentRow(t("vehicleDetail.equipment.modelYear"), iasVehicleData.modelYear)}
                {renderEquipmentRow(t("vehicleDetail.equipment.color"), iasVehicleData.colorDescription, true)}
                {renderEquipmentRow(t("vehicleDetail.equipment.infotainmentType"), iasVehicleData.infotainmentTypes.join(", "))}
            </Stack>
        </StyledCardContent>
    );

    return (
        <>
            <StyledCard>
                <CardHeader
                    title={<Typography color="text.secondary">{t("vehicleDetail.equipment.title")}</Typography>}
                />
                {renderEquipment()}
            </StyledCard>
        </>
    );
};

const StyledCard = styled((props: any) => <Card elevation={0} {...props} />)(
    () => ({
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    })
);

const StyledCardContent = styled(CardContent)(() => ({
    flex: 1,
}));

export default VehicleEquipment;
