import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { useLazyGetOrganizationBrandingQuery } from "../../redux/api/OrganizationBrandingApi";
import { Tooltip } from "@mui/material";
import { RootState } from "../../redux/store";

export default function OrganizationLogo() {
  const organization = useSelector(
    (state: RootState) => state.userSettings.organization
  );
  const [triggerGetBrandingQuery, { data: branding, fulfilledTimeStamp }] =
    useLazyGetOrganizationBrandingQuery();
  const organizationName = organization?.name || "";

  const { authenticated, identity, technicalUser } = useAuth();

  useEffect(() => {
    if (
      authenticated &&
      identity === "verified" &&
      technicalUser === false &&
      organization
    ) {
      triggerGetBrandingQuery(organization.publicId);
    }
  }, [authenticated, identity, technicalUser, organization]);

  if (!branding?.logoURL) {
    return <></>;
  }
  return (
    <Tooltip title={organizationName}>
      <img
        key={fulfilledTimeStamp}
        style={{
          maxWidth: "21rem",
          height: "4rem",
          lineHeight: "4rem",
          textAlign: "center",
        }}
        src={branding?.logoURL + "?" + fulfilledTimeStamp || ""}
        alt={""}
      />
    </Tooltip>
  );
}
