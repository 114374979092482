import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AssociatesView from "./views/AssociatesView";
import VehiclesView from "./views/VehiclesView";
import DemoVideo from "./views/DemoVideo";
import WizardRecall from "./views/WizardRecall";
import UploadVehicles from "./views/UploadVehicles";
import UploadAssociates from "./views/UploadAssociates";

const DashboardComponent = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="h1">{t("dashboard")}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <DemoVideo />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <UploadAssociates />
            </Grid>
            <Grid item md={6} xs={12}>
              <UploadVehicles />
            </Grid>
            <Grid item xs={12}>
              <WizardRecall />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AssociatesView />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VehiclesView />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardComponent;
