import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  styled,
} from "@mui/material";
import { FC } from "react";
import { Step, TooltipRenderProps } from "react-joyride";
import Footer from "./Footer";

interface TooltipProps extends TooltipRenderProps {
  continuous: boolean;
  index: number;
  step: Step;
}

const Tooltip: FC<TooltipProps> = (props) => {
  const { step, closeProps, tooltipProps } = props;
  return (
    <Paper {...tooltipProps}>
      <DialogTitle>
        {step.title}
        {!step.hideCloseButton && (
          <CloseButton {...closeProps}>
            <CloseIcon />
          </CloseButton>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{step.content}</DialogContentText>
      </DialogContent>
      {!step.hideFooter && <Footer {...props} />}
    </Paper>
  );
};

const CloseButton = styled(IconButton)(() => ({
  position: "absolute",
  right: "0.5rem",
  top: "0.5rem",
}));

export default Tooltip;
