import {
  Action,
  createSlice,
  ListenerEffect,
  PayloadAction,
} from "@reduxjs/toolkit";
import { adminsApi } from "../api/AdminsApi";
import { AppDispatch, AppStartListening, RootState } from "../store";

interface TourState {
  running: boolean;
  finished?: boolean;
  step: number;
  mobileMenuOpen: boolean;
}

const initialState: TourState = {
  running: false,
  step: 0,
  mobileMenuOpen: false,
};

const tourFinishedEffect: ListenerEffect<Action, RootState, AppDispatch> = (
  action,
  listenerApi
) => {
  if (action.type === finish.type || action.type === start.type) {
    listenerApi.dispatch(
      adminsApi.endpoints.setTourState.initiate(action.type === finish.type)
    );
  }
};

export const addTourListeners = (startListening: AppStartListening) => {
  startListening({
    predicate: (action, currentState, previousState) =>
      (action.type === finish.type || action.type === start.type) &&
      previousState.tour.finished !== currentState.tour.finished,
    effect: tourFinishedEffect,
  });
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    start: (state) => {
      state.running = true;
      state.step = 0;
      state.finished = false;
    },
    stop: (state) => {
      state.running = false;
    },
    reset: (state) => {
      state.running = false;
      // Rewind to the first step if we skipped in the middle
      state.step = 0;
    },
    back: (state) => {
      --state.step;
    },
    next: (state) => {
      ++state.step;
    },
    goto: (state, { payload }: PayloadAction<number>) => {
      state.running = true;
      state.step = payload;
    },
    finish: (state) => {
      // Need to set our running state to false, so we can restart if we click start again.
      state.step = 0;
      state.finished = true;
      state.running = false;
    },
    toggleMobileMenu: (state, { payload }: PayloadAction<boolean>) => {
      state.mobileMenuOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      adminsApi.endpoints.getIntroState.matchFulfilled,
      (state, { payload }) => {
        state.finished = !!payload.tourFinished;
      }
    );
  },
});

export const {
  start,
  stop,
  back,
  next,
  reset,
  goto,
  finish,
  toggleMobileMenu,
} = tourSlice.actions;

export default tourSlice;
