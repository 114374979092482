import {
  Check as CheckIcon,
  DeleteForever as DeleteIcon,
  Email as InviteIcon,
  PendingActions as PendingActionsIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Switch,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridCellParams,
  GridColumns,
  GridFilterModel,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowModel,
  GridRowParams,
  GridSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  PrintableSwitch,
  tableComponentProps,
  TableContentWrapper,
} from "../../../common/styles";
import { Operator, Page } from "../../../common/pagination";
import {
  Associate,
  useDeleteAssociateByIdMutation,
  useDeleteAssociatesByIdsMutation,
  useDenyAssociateMutation,
  useGetAssociatesQuery,
  useUpdateAssociateMutation,
} from "../../../redux/api/AssociatesApi";
import { useResendAssociatesConfirmationsMutation } from "../../../redux/api/AssociatesConfirmationApi";
import Pagination from "../../pagination/Pagination";
import { mapToEntity, mapToGridRow } from "./AssociateHelpers";
import DeleteModal from "./DeleteModal";
import ResendConfirmMessageModal from "./ResendConfirmMessageModal";
import { RowActions } from "./RowActions";
import Toolbar from "./Toolbar";
import { AssociatesContext } from "../AssociatesComponent";
import { useOutletContext } from "react-router-dom";
import ConfirmUpdateModal from "../../confirm-update-modal/ConfirmUpdateModal";
import {
  ExternalAuthorizeEventResult,
  ExternalAuthorizeEventStatusReason,
} from "../../../redux/api/ExternalAuthorizeEvent";
import { TableTextEditComponent } from "../../TableTextEditComponent";
import { getTableLocaleText } from "../../getTableLocaleText";
import useSmallScreenProperties from "../../../common/small-screen/useSmallScreenProperties";
import { reducedAssociatesVisibilityModel } from "../../../common/small-screen/visibilityModel";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { renderStatusColumn } from "../../renderStatusColumn";
import { validateSpecialCharacters } from "../../validateSpecialCharacters";
import InputErrorModal from "../../modal/InputErrorModal";

interface AssociatesTableProps {
  associates?: Page<Associate[]>;
}

export interface AssociateRow {
  id: string;
  name?: string;
  emailAddress?: string;
  deny?: boolean;
  activationSelfConfirmed?: "true" | "false";
  externalLastLoginResult?: ExternalAuthorizeEventResult;
  externalLastLoginReason?: ExternalAuthorizeEventStatusReason;
}

export interface ActionButtonProps {
  id: string;
  onClick?: (id: string) => void;
  emailAddress?: string;
  name?: string;
}

interface UpdatePromise {
  newRow: GridRowModel;
  oldRow: GridRowModel;
  reject: (value: unknown) => void;
  resolve: (value: unknown) => void;
}
const AssociatesTable: React.FC<AssociatesTableProps> = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();

  const [
    displayAll,
    setDisplayAll,
    columnVisibilityModel,
    setColumnVisibilityModel,
  ] = useSmallScreenProperties(reducedAssociatesVisibilityModel);

  const [updatePromise, setUpdatePromiseArguments] = useState<UpdatePromise>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [reconfirmModalOpen, setBulkReconfirmIsOpen] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [inputErrorModalOpen, setInputErrorModalOpen] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [queryOptions, setQueryOptions] = useOutletContext<AssociatesContext>();

  const [deleteAssociate] = useDeleteAssociateByIdMutation();
  const [deleteAssociates] = useDeleteAssociatesByIdsMutation();
  const [reconfirmAssociates] = useResendAssociatesConfirmationsMutation();
  const [denyAssociate] = useDenyAssociateMutation();

  const { data, isFetching, isLoading } = useGetAssociatesQuery(queryOptions);

  const [updateAssociate] = useUpdateAssociateMutation();

  const onDenyChange =
    (id: string) =>
    async (e: ChangeEvent<HTMLInputElement>, allowAccessChecked: boolean) => {
      await denyAssociate({
        publicId: id,
        deny: !allowAccessChecked,
        organizationId: organization.publicId,
      });
    };

  const specialCharactersValidator = validateSpecialCharacters("name");
  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) =>
    new Promise<GridRowModel>((resolve, reject) => {
      const validationResult = specialCharactersValidator(newRow.name);
      if (validationResult === true) {
        setInputError(false);
      } else {
        throw new Error(validationResult);
      }
      setUpdatePromiseArguments({ resolve, reject, newRow, oldRow });
    });
  const onProcessRowUpdateError = (error: any) => {
    setInputError(true);
    setInputErrorMessage(error.message);
    setInputErrorModalOpen(true);
  };

  const onDeleteClick = (id?: string) => {
    setDeleteModalOpen(true);
    if (id) {
      setSelectionModel([id]);
    }
  };

  const handleBulkReconfirm = async () => {
    setBulkReconfirmIsOpen(!reconfirmModalOpen);
    reconfirmAssociates({
      ids: selectionModel as string[],
      organizationId: organization.publicId,
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar(t("reconfirm.success.message"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("reconfirm.error.message"), {
          variant: "error",
          preventDuplicate: true,
          key: error.status,
        });
      });
    setSelectionModel([]);
  };

  const onConfirmDelete = () => {
    setDeleteModalOpen(false);
    try {
      if (selectionModel.length === 1) {
        deleteAssociate({
          id: `${selectionModel[0]}`,
          organizationId: organization.publicId,
        }).unwrap();
      } else if (selectionModel.length > 1) {
        deleteAssociates({
          ids: selectionModel,
          organizationId: organization.publicId,
        }).unwrap();
      }
    } catch (error: any) {
      enqueueSnackbar(t("delete.fail"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
    setSelectionModel([]);
  };

  const onCancelDialog = () => {
    setDeleteModalOpen(false);
  };

  const onConfirmUpdate = async () => {
    const { newRow, oldRow, reject, resolve } = updatePromise!;
    newRow.name = newRow.name.trim();
    try {
      await updateAssociate({
        associate: mapToEntity(newRow),
        organizationId: organization.publicId,
      }).unwrap();
      resolve(newRow);
    } catch (error: any) {
      reject(oldRow);
      enqueueSnackbar("Failed to update row", {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    } finally {
      setUpdatePromiseArguments(undefined);
    }
  };

  const onCancelUpdate = () => {
    const { oldRow, resolve } = updatePromise!;
    resolve(oldRow);
    setUpdatePromiseArguments(undefined);
  };

  const renderDenyToggle = (
    params: GridRenderCellParams<boolean, AssociateRow>
  ) => {
    const tooltip = t(
      params.value
        ? "associates.access.denied.tooltip"
        : "associates.access.allowed.tooltip"
    );
    return (
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <PrintableSwitch aria-checked={!params.value}>
          <Tooltip title={tooltip}>
            <Switch
              checked={!params.value}
              onChange={onDenyChange(params.row.id)}
            />
          </Tooltip>
        </PrintableSwitch>
      </Box>
    );
  };

  const getActions = useCallback(
    (params: GridRowParams) => RowActions(params.row.id, onDeleteClick),
    []
  );

  const isBulkReconfirmDisabled = () => {
    const notSentInvites = data?.content
      ? data?.content
          .filter(
            (associate) =>
              associate.publicId && selectionModel.includes(associate.publicId)
          )
          .filter((associate) => associate.activationSelfConfirmed)
      : [];
    return notSentInvites.length > 0;
  };

  const onBulkReconfirmClick = () => {
    setBulkReconfirmIsOpen(true);
  };

  const renderTableActions = () => {
    return (
      <>
        {selectionModel.length > 0 && (
          <Tooltip title={t("bulkDelete")}>
            <IconButton onClick={() => onDeleteClick()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {selectionModel.length > 0 && (
          <Tooltip
            title={
              isBulkReconfirmDisabled()
                ? t("reconfirm.button.label.disabled")
                : t("reconfirm.button.label")
            }
          >
            <div>
              <IconButton
                onClick={onBulkReconfirmClick}
                disabled={isBulkReconfirmDisabled()}
              >
                <InviteIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </>
    );
  };

  const renderConfirmationStatus = ({
    row: { activationSelfConfirmed },
  }: GridCellParams<unknown, AssociateRow>) => (
    <Chip
      label={t(`associate.confirmedStatus.${activationSelfConfirmed}`)}
      size="small"
      icon={
        activationSelfConfirmed === "true" ? (
          <CheckIcon />
        ) : (
          <PendingActionsIcon />
        )
      }
    />
  );

  const columns: GridColumns = [
    {
      field: "name",
      headerName: t("associate.name"),
      flex: 1,
      minWidth: 100,
      editable: true,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
      renderEditCell: (props: GridRenderEditCellParams) => (
        <TableTextEditComponent renderParams={props} maxLength={255} />
      ),
    },
    {
      field: "emailAddress",
      headerName: t("associate.email"),
      flex: 1,
      minWidth: 100,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "activationSelfConfirmed",
      headerName: t("associate.activationSelfConfirmed"),
      flex: 0.5,
      minWidth: 50,
      valueOptions: [
        { label: t("associate.confirmedStatus.true"), value: true },
        { label: t("associate.confirmedStatus.false"), value: false },
      ],
      filterOperators: getGridSingleSelectOperators().filter(
        (op) => op.value === "is"
      ),
      type: "singleSelect",
      renderCell: renderConfirmationStatus,
    },
    {
      field: "status",
      headerName: t("associates.last.evaluation.result"),
      flex: 0.6,
      editable: false,
      renderCell: renderStatusColumn,
      filterable: true,
      valueOptions: Object.values(ExternalAuthorizeEventResult),
      valueFormatter: ({ value }) =>
        t("associates.external.access.state." + value),
      filterOperators: getGridSingleSelectOperators().filter(
        (s) => s.value === "is"
      ),
      sortable: false,
    },
    {
      field: "deny",
      headerName: t("associates.allowAccess"),
      flex: 0.5,
      renderCell: renderDenyToggle,
      valueOptions: ["false", "true"],
      valueFormatter: ({ value }) =>
        value === "true"
          ? t("associates.allowAccess.denied")
          : t("associates.allowAccess.allowed"),
      filterOperators: getGridSingleSelectOperators().filter(
        (s) => s.value === "is"
      ),
    },
    {
      field: "actions",
      headerName: t("table.columns.actions"),
      type: "actions",
      minWidth: 120,
      getActions,
      renderHeader: renderTableActions,
    },
  ];

  const rows = useMemo(() => (data ? mapToGridRow(data?.content) : []), [data]);

  const localeText = getTableLocaleText();

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof Associate,
      sortOrder: sort?.sort,
    });
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter =
      filterModel.items.length === 0 ? undefined : filterModel.items[0];
    setQueryOptions({
      ...queryOptions,
      filterField: filter?.columnField as keyof Associate,
      filterOperator: filter?.operatorValue as Operator,
      filterValue: filter?.value,
    });
  };

  const sortModel = useMemo(() => {
    return queryOptions.sortField
      ? [
          {
            sort: queryOptions.sortOrder,
            field: queryOptions.sortField,
          },
        ]
      : [];
  }, [queryOptions.sortField, queryOptions.sortOrder]);

  const filterModel = useMemo(() => {
    const filter = queryOptions.filterField
      ? [
          {
            columnField: queryOptions.filterField,
            operatorValue: queryOptions.filterOperator,
            value: queryOptions.filterValue,
          },
        ]
      : [];
    return {
      items: filter,
    };
  }, [
    queryOptions.filterField,
    queryOptions.filterOperator,
    queryOptions.filterValue,
  ]);

  useEffect(() => {
    if (organization) {
      setQueryOptions({
        ...queryOptions,
        organizationId: organization.publicId,
      });
    }
  }, [organization]);

  return (
    <TableContentWrapper displayAll={displayAll}>
      <DataGrid
        localeText={localeText}
        editMode="row"
        rows={rows}
        rowCount={data?.totalElements || 0}
        columns={columns}
        page={queryOptions.page}
        onPageChange={onPageChange}
        pageSize={queryOptions.size}
        onPageSizeChange={onPageSizeChange}
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        filterMode="server"
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar,
          LoadingOverlay: LinearProgress,
          Pagination,
          OpenFilterButtonIcon: SearchIcon,
        }}
        loading={isFetching || isLoading}
        disableSelectionOnClick
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        componentsProps={{
          ...tableComponentProps,
          toolbar: { displayAll, setDisplayAll },
        }}
        keepNonExistentRowsSelected
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
      />
      <DeleteModal
        isDialogOpened={deleteModalOpen}
        count={selectionModel.length}
        handleCancelDialog={onCancelDialog}
        handleDeleteDialog={onConfirmDelete}
      />
      <ResendConfirmMessageModal
        isDialogOpened={reconfirmModalOpen}
        count={selectionModel.length}
        handleCancelDialog={() => setBulkReconfirmIsOpen(false)}
        handleReconfirmDialog={() => handleBulkReconfirm()}
      />
      <ConfirmUpdateModal
        open={!!updatePromise && !inputError}
        onClose={onCancelUpdate}
        onConfirm={onConfirmUpdate}
      />
      <InputErrorModal
        open={inputErrorModalOpen}
        onClose={() => setInputErrorModalOpen(false)}
        message={inputErrorMessage}
      />
    </TableContentWrapper>
  );
};

export default AssociatesTable;
