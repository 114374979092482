import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import { PaletteMode, ThemeProvider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { primaryColorDefault, resolveTheme } from "./theme";
import { SnackbarProvider } from "notistack";

const initialColor =
  sessionStorage.getItem("primaryColor") || primaryColorDefault;

interface ColorsContextType {
  primaryColor: string;
  mode: PaletteMode;
  changeMode: () => void;
  changeColors: (primaryColor: string) => void;
}

const initialState: ColorsContextType = {
  mode: "light",
  changeMode: () => null,
  changeColors: () => null,
  primaryColor: initialColor,
};
const ApplicationColorsContext = createContext<ColorsContextType>(initialState);

export function useApplicationColors() {
  return useContext(ApplicationColorsContext);
}

export default function ApplicationColorsProvider({ children }: PropsWithChildren<any>) {
  const isSystemDark = useMediaQuery('(prefers-color-scheme: dark)')
  const systemMode = useMemo(() => isSystemDark ? 'dark' : 'light', [isSystemDark])
  const sessionMode = sessionStorage.getItem('mode') as PaletteMode
  const [selectedMode, setSelectedMode] = useState<'light'|'dark'|null>(null)
  const applicationMode = useMemo(
      () => (selectedMode || sessionMode || systemMode),
      [selectedMode, sessionMode, systemMode]
  )

  const [applicationColor, setApplicationColor] =
    useState<string>(initialColor);

  const theme = useMemo(() => {
    return resolveTheme(applicationColor, applicationMode);
  }, [applicationMode, applicationColor]);

  const changeMode = () => {
    const newMode = applicationMode === "light" ? "dark" : "light";
    setSelectedMode(newMode);
    sessionStorage.setItem("mode", newMode);
  };
  const changeColors = (primaryColor: string) => {
    setApplicationColor(primaryColor);
    sessionStorage.setItem("primaryColor", primaryColor);
  };

  return (
    <ApplicationColorsContext.Provider
      value={{
        mode: applicationMode,
        primaryColor: applicationColor,
        changeColors,
        changeMode,
      }}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider>{children}</SnackbarProvider>
      </ThemeProvider>
    </ApplicationColorsContext.Provider>
  );
}
