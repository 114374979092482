import { baseApi } from "./baseApi";

export interface UserOrganization {
  publicId: string;
  name: string;
}

interface UserAdmin {
  publicId: string;
  organizations: UserOrganization[];
}

interface LoginPreferences {
  role: string;
  organizationId: string;
  enabled: boolean;
}

interface Preferences {
  login?: Partial<LoginPreferences>;
}

export interface GetUserResponse {
  publicId: string;
  name: string;
  email: string;
  username: string;
  admin?: UserAdmin;
  preferences?: Preferences;
}

interface UpdateUserRequest {
  name: string;
  username: string;
}

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<GetUserResponse, void>({
      query: () => ({
        url: "/user/me",
        method: "GET",
      }),
      providesTags: (_) => ["User"],
    }),
    updateUser: builder.mutation<void, UpdateUserRequest>({
      query: (body) => ({
        url: "/user/me",
        method: "PUT",
        body,
      }),
      // Associates & Organization Admins can change their name that is also reflected on the Associate & Admin tables
      invalidatesTags: (_) => ["User", "Admin", "Associate"],
    }),
    updatePreferences: builder.mutation<void, Partial<LoginPreferences>>({
      query: ({ organizationId, role, enabled }) => ({
        url: "/user/me/preferences/login",
        method: "PUT",
        body: {
          role,
          organizationId,
          enabled,
        },
      }),
      invalidatesTags: (_) => ["User"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdatePreferencesMutation,
} = userApi;
