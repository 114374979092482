import { Add as AddIcon, Check as CheckIcon } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useGetUserQuery, UserOrganization } from "../../redux/api/UserApi";
import { selectOrganization } from "../../redux/slice/UserSettingsSlice";
import { RootState } from "../../redux/store";
import { dashboardRoute } from "../navbar/routes";
import AccountMenuItem from "./AccountMenuItem";

interface OrganizationSelectorSectionProps {
  onNewClick?: () => void;
}

const OrganizationSelectorSection: FC<OrganizationSelectorSectionProps> = ({
  onNewClick,
}) => {
  const { user, identity, technicalUser } = useAuth();
  const { data } = useGetUserQuery(
    !user || identity !== "verified" || technicalUser ? skipToken : undefined
  );
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(
    (state: RootState) => state.userSettings.organization
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onMenuItemClick = (organization: UserOrganization) => () => {
    if (organization?.publicId !== selectedOrganization?.publicId) {
      dispatch(selectOrganization({ organization }));
      navigate(dashboardRoute.path);
      enqueueSnackbar(
        t("menu.change.organization.success", {
          organization: organization.name,
        }),
        {
          variant: "success",
        }
      );
    }
  };

  // Data is immutable so we create a new array to have sorting later on
  const organizations = data?.admin?.organizations
    ? [...data?.admin?.organizations]
    : [];

  const sortByName = (left: UserOrganization, right: UserOrganization) =>
    left.name.localeCompare(right.name);

  return (
    <>
      {organizations.sort(sortByName).map((entry) => {
        const checked =
          selectedOrganization &&
          selectedOrganization.publicId === entry.publicId;
        return (
          <AccountMenuItem
            key={entry.publicId}
            checked={checked}
            onClick={onMenuItemClick(entry)}
          >
            {checked && (
              <ListItemIcon>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
            )}
            <ListItemText inset={!checked}>
              <Typography variant="inherit" noWrap>
                {entry.name}
              </Typography>
            </ListItemText>
          </AccountMenuItem>
        );
      })}
      <AccountMenuItem onClick={onNewClick}>
        <ListItemIcon>
          <AddIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="inherit" noWrap>
            {t("menu.new.organization")}
          </Typography>
        </ListItemText>
      </AccountMenuItem>
    </>
  );
};

export default OrganizationSelectorSection;
