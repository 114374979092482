import React from "react";
import { useGetOrganizationSettingsQuery } from "../../redux/api/OrganizationSettingsApi";
import { useTranslation } from "react-i18next";
import { styled, Typography } from "@mui/material";
import { BrandingSettings } from "./BrandingSettings";
import Grid from "@mui/material/Grid";
import OrganizationSettings from "./OrganizationSettings";
import DeleteOrganization from "./DeleteOrganization";
import { isAdminUser } from "../navbar/routes";
import AdminsTable from "../admins/table/AdminsTable";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";
import { useAuth } from "../../hooks/useAuth";

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const { role } = useAuth();
  const admin = isAdminUser(role);
  const organization = useSelectedOrganization();

  const { data } = useGetOrganizationSettingsQuery(organization.publicId, {
    refetchOnMountOrArgChange: true,
  });
  if (!data) return <></>;

  return (
    <>
      <Typography variant="h1">{t("settings")}</Typography>
      <Section>
        <Typography variant="h3">{t("settings.preferences.title")}</Typography>
        <Content>
          <Grid container spacing={2} alignItems="center">
            <OrganizationSettings />
            <BrandingSettings />
          </Grid>
        </Content>
      </Section>
      <Section>
        <Typography variant="h3">{t("admins")}</Typography>
        <Content>
          <AdminsTable />
        </Content>
      </Section>
      {admin && (
        <Section>
          <Typography variant="h3">{t("settings.restricted.title")}</Typography>
          <Description>{t("settings.restricted.description")}</Description>
          <Content>
            <DeleteOrganization />
          </Content>
        </Section>
      )}
    </>
  );
};

const Section = styled("div")(() => ({
  marginBlock: "3.5rem",
}));

const Description = styled("div")(() => ({
  marginBlock: "1rem",
  maxWidth: "70ch",
}));

const Content = styled("div")(() => ({
  marginBlock: "2rem",
}));

export default Settings;
