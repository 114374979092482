import { styled } from "@mui/system";
import { Stack, Tooltip } from "@mui/material";
import Link from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useTranslation } from "react-i18next";

export function SocialMedia() {
  const { t } = useTranslation();
  const tooltipFacebook = t("footer.facebook.tooltip");
  const tooltipTwitter = t("footer.twitter.tooltip");
  const tooltipYoutube = t("footer.youtube.tooltip");
  const tooltipInstagram = t("footer.instagram.tooltip");

  return (
    <Stack direction="row" spacing={1}>
      <SocialLink href="https://www.facebook.com/skoda.cz">
        <Tooltip title={tooltipFacebook}>
          <FacebookIcon />
        </Tooltip>
      </SocialLink>
      <SocialLink href="https://twitter.com/skodacz">
        <Tooltip title={tooltipTwitter}>
          <TwitterIcon />
        </Tooltip>
      </SocialLink>
      <SocialLink href="https://www.youtube.com/user/SKODACeskarepublika">
        <Tooltip title={tooltipYoutube}>
          <YouTubeIcon />
        </Tooltip>
      </SocialLink>
      <SocialLink href="https://www.instagram.com/skodacr/">
        <Tooltip title={tooltipInstagram}>
          <InstagramIcon />
        </Tooltip>
      </SocialLink>
    </Stack>
  );
}

const SocialLink = styled((props: any) => (
  <Link target="_blank" rel="noreferrer" {...props} />
))(() => ({}));
