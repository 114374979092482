import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useMemo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import {
  useGetUserQuery,
  useUpdatePreferencesMutation,
  useUpdateUserMutation,
} from "../../redux/api/UserApi";
import { getValidatedRole } from "../../redux/slice/AuthSlice";
import { Role } from "../navbar/routes";
import { validateSpecialCharacters } from "../validateSpecialCharacters";

interface Profile {
  name: string;
  username: string;
}

interface Preferences {
  role: Role;
  organizationId: string;
  remember: boolean;
}

type FormValues = Profile & Preferences;

const AccountComponent = () => {
  const { roles } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [updateUser] = useUpdateUserMutation();
  const [updatePreferences] = useUpdatePreferencesMutation();
  const { data } = useGetUserQuery();

  const defaultValues = useMemo(() => {
    const name = data?.name || "";
    const username = data?.username || "";
    const role =
      getValidatedRole(roles, data?.preferences?.login?.role) || ("" as Role);
    const organizationId = data?.preferences?.login?.organizationId || "";
    const remember = !!data?.preferences?.login?.enabled;

    return {
      name,
      username,
      role,
      organizationId,
      remember,
    };
  }, [data]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm<FormValues>({
    defaultValues,
  });
  const navigate = useNavigate();

  const onSubmit = async ({
    name,
    organizationId,
    username,
    role,
    remember,
  }: FormValues) => {
    try {
      await updateUser({
        name,
        username,
      }).unwrap();
      await updatePreferences({
        organizationId,
        role,
        enabled: remember,
      }).unwrap();
      enqueueSnackbar(t("account.update.success"), {
        variant: "success",
      });
    } catch (error: any) {
      enqueueSnackbar(t("account.update.error"), {
        variant: "error",
        key: error.status,
        preventDuplicate: true,
      });
    }
  };

  const selectedRole = watch("role");

  const showOrganizationSelector = selectedRole === "ROLE_organization_admin";

  const showRememberMe =
    roles.length > 1 ||
    (data?.admin?.organizations && data.admin.organizations.length > 1);

  useEffect(() => {
    reset({
      ...getValues(),
      ...defaultValues,
    });
  }, [defaultValues]);

  return (
    <>
      <Typography variant="h1">{t("account")}</Typography>
      <Container maxWidth="xs" disableGutters>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Subtitle variant="h3">{t("account.profile.title")}</Subtitle>
          <Description variant="body1">
            {t("account.profile.description")}
          </Description>
          <TextField
            defaultValue={defaultValues.name}
            required
            margin="normal"
            fullWidth
            label={t("account.name")}
            error={!!errors.name}
            helperText={errors.name?.message}
            {...register("name", {
              validate: validateSpecialCharacters("name"),
              required: { value: true, message: t("validation.name.blank") },
            })}
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            defaultValue={defaultValues.username}
            required
            margin="normal"
            fullWidth
            label={t("account.username")}
            error={!!errors.username}
            helperText={errors.username?.message}
            {...register("username", {
              validate: validateSpecialCharacters("username"),
              required: {
                value: true,
                message: t("validation.username.blank"),
              },
            })}
            inputProps={{ maxLength: 255 }}
          />

          <Separator />

          <Subtitle variant="h3">{t("account.preferences.title")}</Subtitle>
          <Description variant="body1">
            {t("account.preferences.description")}
          </Description>

          <FormControl error={!!errors.role} fullWidth margin="normal">
            <ButtonGroupLabel>{t("account.role")}</ButtonGroupLabel>
            <Controller
              name="role"
              control={control}
              defaultValue={defaultValues.role}
              render={({ field }) => (
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  size="medium"
                  aria-label={t("account.role")}
                  aria-invalid={!!errors.role}
                  {...field}
                  fullWidth
                >
                  {roles.map((role) => (
                    <ToggleButton key={role} value={role}>
                      {t(`roleSelector.${role}.name`)}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              )}
            />
            <FormHelperText>{errors.role?.message}</FormHelperText>
          </FormControl>

          {showOrganizationSelector && (
            <Controller
              name="organizationId"
              control={control}
              defaultValue={defaultValues.organizationId}
              render={({ field }) => (
                <FormControl
                  error={!!errors.organizationId}
                  fullWidth
                  margin="normal"
                >
                  <InputLabel id="organization-label">
                    {t("account.organization")}
                  </InputLabel>
                  <Select
                    id="organization-select"
                    labelId="organization-label"
                    label={t("account.organization")}
                    {...field}
                  >
                    {data?.admin?.organizations.map((o) => (
                      <MenuItem key={o.publicId} value={o.publicId}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.organizationId?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}

          {showRememberMe && (
            <Controller
              name="remember"
              control={control}
              defaultValue={defaultValues.remember}
              render={({ field }) => (
                <FormControl
                  error={!!errors.remember}
                  hiddenLabel
                  margin="dense"
                >
                  <Tooltip title={t("account.remember.tooltip")}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      }
                      label={t("account.remember")}
                    />
                  </Tooltip>
                </FormControl>
              )}
            />
          )}

          <SaveButton type="submit" fullWidth variant="contained">
            {t("save")}
          </SaveButton>
          <CancelButton
            fullWidth
            variant="outlined"
            onClick={() => navigate(-1)}
          >
            {t("cancel")}
          </CancelButton>
        </Box>
      </Container>
    </>
  );
};

const SaveButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const CancelButton = styled(Button)(() => ({
  marginTop: 0,
}));

const Subtitle = styled(Typography)(() => ({
  marginBottom: "0.25rem",
}));

const Description = styled(Typography)(() => ({
  marginBottom: "1rem",
}));

const Separator = styled(Divider)(() => ({
  marginTop: "1.5rem",
  marginBottom: "2.75rem",
}));

const ButtonGroupLabel = styled(FormLabel)(() => ({
  marginBottom: "0.5rem",
  paddingLeft: "0.5rem",
}));

export default AccountComponent;
