import { styled } from "@mui/system";
import BusinessSection from "./section/BusinessSection";
import IntroSection from "./section/IntroSection";
import LoginSection from "./section/LoginSection";

const Hero = () => (
  <Wrapper>
    <LoginSection />
    <IntroSection />
    <BusinessSection />
  </Wrapper>
);

const Wrapper = styled("div")(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export default Hero;
