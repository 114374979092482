import {
  VehicleValidationError,
  VehicleValidationErrors,
} from "../../redux/api/VehiclesApi";
import {
  AssociateValidationError,
  AssociateValidationErrors,
} from "../../redux/api/AssociatesApi";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { ListItem, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";

interface ErrorListProps {
  errors: VehicleValidationErrors | AssociateValidationErrors;
}

const ErrorsContainer = styled(Box)(() => ({
  display: "grid",
  gap: 1,
  gridTemplateRows: "repeat(2, 1fr)",
}));

type ValidationErrorType = [
  string,
  AssociateValidationError[] | VehicleValidationError[]
];

export const ErrorList: React.FC<ErrorListProps> = ({ errors }) => {
  const { t } = useTranslation();
  const elements = Object.entries(errors);
  return (
    <List>
      <ErrorsContainer>
        {elements.map((value, i) => {
          const [entity, errors]: ValidationErrorType = value;
          return (
            <ListItem key={i}>
              <Typography
                variant="body2"
                sx={{ width: "18ch" }}
                noWrap
                title={entity}
              >
                {entity || t("validation.item")}
              </Typography>
              <Box>
                <Stack direction="row" spacing={2}>
                  {errors.map((reason, i) => {
                    return (
                      <Typography key={i} variant={"body2"}>
                        {t(`validation.${reason}`)}
                      </Typography>
                    );
                  })}
                </Stack>
              </Box>
            </ListItem>
          );
        })}
      </ErrorsContainer>
    </List>
  );
};
