import { matchPath, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { getMenuRoutesByRole } from "./routes";
import { styled } from "@mui/system";
import { FC } from "react";
import { useAuth } from "../../hooks/useAuth";

interface NavigationLinksProps {
  onNavigate?: () => void;
}

const NavigationLinks: FC<NavigationLinksProps> = ({ onNavigate }) => {
  const { identity, technicalUser, role } = useAuth();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const routes = getMenuRoutesByRole(role);

  const route = routes.find(
    (route) => matchPath(`${route.path}/*`, pathname) !== null
  );

  const orientation = desktop ? "horizontal" : "vertical";

  return (
    <Wrapper>
      <StyledTabs value={route?.path || false} orientation={orientation}>
        {(identity === "verified" || technicalUser === true) &&
          routes.map((route) => (
            <StyledTab
              id={`nav-button-${route.page}`}
              key={route.path}
              label={t(route.page)}
              value={route.path}
              to={route.path}
              component={NavLink}
              onClick={onNavigate}
              data-testid={route.path}
            />
          ))}
      </StyledTabs>
    </Wrapper>
  );
};

const Wrapper = styled("nav")(({ theme }) => ({
  height: "100%",
  // On mobile screen-size we want a vertical direction
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    display: "block",
    flexDirection: "revert",
    justifyContent: "revert",
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  // Hide the Tab indicator on mobile screen-size
  "& .MuiTabs-indicator": {
    display: "none",
    // Prevents the mount bug on the first render
    height: "2px !important",
    width: 0,
  },
  [theme.breakpoints.up("md")]: {
    "& .MuiTabs-indicator": {
      display: "block",
    },
  },
}));

const StyledTab = styled((props: any) => (
  <Tab disableRipple {...props} tabIndex={0} />
))(({ theme }) => ({
  fontSize: "1.75rem",
  padding: "2rem 1rem",
  maxWidth: "revert",
  outline: "revert",
  "&.Mui-selected": {
    color: theme.palette.text.primary,
  },
  "&.Mui-focusVisible": {},
  "&:hover": {
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.up("md")]: {
    padding: "1.4rem 1rem",
    // On Desktop we fall back to the original style
    fontSize: "revert",
  },
}));

export default NavigationLinks;
