import { Action, createSlice, ListenerEffect } from "@reduxjs/toolkit";
import { adminsApi } from "../api/AdminsApi";
import { AppDispatch, AppStartListening, RootState } from "../store";

interface DemoState {
  finished?: boolean;
}

const initialState: DemoState = {
  finished: undefined,
};

const demoFinishedEffect: ListenerEffect<Action, RootState, AppDispatch> = (
  action,
  listenerApi
) => {
  if (action.type === finish.type || action.type === start.type) {
    listenerApi.dispatch(
      adminsApi.endpoints.setDemoState.initiate(action.type === finish.type)
    );
  }
};

export const addDemoListeners = (startListening: AppStartListening) => {
  startListening({
    predicate: (action, currentState, previousState) =>
      (action.type === finish.type || action.type === start.type) &&
      previousState.demo.finished !== currentState.demo.finished,
    effect: demoFinishedEffect,
  });
};

const demoSlice = createSlice({
  name: "demo",
  initialState,
  reducers: {
    start: (state) => {
      state.finished = false;
    },
    finish: (state) => {
      state.finished = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      adminsApi.endpoints.getIntroState.matchFulfilled,
      (state, { payload }) => {
        state.finished = !!payload.demoFinished;
      }
    );
  },
});

export const { start, finish } = demoSlice.actions;

export default demoSlice;
