import {useState} from "react";
import {ComputedDatum, ResponsivePie} from "@nivo/pie";
import {useTranslation} from "react-i18next";
import {byActivationSelfConfirmedTheme, useNivoTheme} from "../../../../common/theme";
import AssociatesModal from "./AssociatesModal";
import {CountByActivationSelfConfirmed} from "../../../../common/pagination";
import { Box } from "@mui/material";

interface AssociateByActivationSelfConfirmedChartDatum {
  id: string;
  label: string;
  value: number;
  activationSelfConfirmed: boolean;
}

const AssociatesByActivationSelfConfirmed = (props: { data?: CountByActivationSelfConfirmed }) => {
  const { t } = useTranslation();
  const titlePending = t("dashboard.associates.confirmation.pending");
  const titleActive = t("dashboard.associates.confirmation.active");
  const countPending = props.data?.pending ?? 0;
  const countActive = props.data?.active ?? 0;

  const chartData: AssociateByActivationSelfConfirmedChartDatum[] = [
    { id: titlePending, label: `${titlePending} (${countPending})`, value: countPending, activationSelfConfirmed: false },
    { id: titleActive, label: `${titleActive} (${countActive})`, value: countActive, activationSelfConfirmed: true },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalActive, setModalActive] = useState(false);

  const openModal = (activationSelfConfirmed: boolean) => {
    setModalActive(activationSelfConfirmed)
    setModalOpen(true)
  }

  return (
    <>
      <Box textAlign={"center"}>{t("dashboard.associates.confirmation.title")}</Box>
      <ResponsivePie
        margin={{ top: 10, right: 0, bottom: 80, left: 0 }}
        data={chartData}
        animate={true}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeInnerRadiusOffset={6}
        layers={["arcs", "legends"]}
        colors={byActivationSelfConfirmedTheme.palette.pie}
        theme={useNivoTheme()}
        onClick={(datum: ComputedDatum<AssociateByActivationSelfConfirmedChartDatum>) => { openModal(datum.data.activationSelfConfirmed) }}
        legends={[
          {
            anchor: "bottom-left",
            direction: "column",
            symbolShape: "circle",
            itemWidth: 100,
            itemHeight: 20,
            translateY: 50,
            translateX: 30,
            onClick: (d) => { openModal(d.id === titleActive) },
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 0.5
                }
              }
            ]
          }
        ]}
      />
      {
        modalOpen && (
          <AssociatesModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            subTitle={modalActive ? titleActive : titlePending}
            filterField="activationSelfConfirmed"
            filterValue={modalActive.toString()}
          />
        )
      }
    </>
  );
};

export default AssociatesByActivationSelfConfirmed
