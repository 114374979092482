import { DialogContent, DialogContentText, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";

interface DeclineModalProps {
  open: boolean;
  onConfirm: (reason: string) => void;
  onClose: () => void;
}

const DeclineModal: FC<DeclineModalProps> = ({
  open,
  onConfirm: onConfirmProp,
  onClose,
}) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState("");

  const onReasonChange = (e: ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const onConfirm = () => {
    onConfirmProp(reason);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="decline-dialog-title"
    >
      <DialogTitle id="decline-dialog-title">
        {t("vehicle.approval.decline.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("vehicle.approval.decline.description")}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={t("vehicle.approval.decline.reason")}
          type="text"
          fullWidth
          variant="standard"
          value={reason}
          onChange={onReasonChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {t("cancel")}
        </Button>
        <Button
          data-cy="modal.approval.confirmation"
          onClick={onConfirm}
          variant="contained"
          size="medium"
          color="primary"
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeclineModal;
