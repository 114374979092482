import { useState } from "react";
import { ComputedDatum, ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { byAccessTheme, useNivoTheme } from "../../../../common/theme";
import {
  AccessStatus,
  AssociateAccessStatistics,
  AssociateStatisticsEntry,
} from "../../../../redux/api/StatisticsApi";
import AssociatesAccessStatusModal from "./AssociatesAccessStatusModal";
import { Box } from "@mui/material";

interface AssociateByAccessChartDatum {
  id: string;
  label: string;
  value: number;
  accessStatus: AccessStatus;
}

const AssociatesByAccess = (props: { data?: AssociateAccessStatistics }) => {
  const { t } = useTranslation();
  const titleAuthorized = t("dashboard.associates.accessStatus.authorized");
  const titleRejected = t("dashboard.associates.accessStatus.rejected");
  const titleUnused = t("dashboard.associates.accessStatus.unused");
  const countAuthorizedAssociates = props.data?.authorizedAssociates?.length ?? 0;
  const countRejectedAssociates = props.data?.rejectedAssociates?.length ?? 0;
  const countUnusedAssociates = props.data?.unusedAssociates?.length ?? 0;

  const associatesByAccessStatusChartData = [
    {
      id: titleAuthorized,
      label: `${titleAuthorized} (${countAuthorizedAssociates})`,
      value: countAuthorizedAssociates,
      accessStatus: AccessStatus.AUTHORIZED,
    },
    {
      id: titleRejected,
      label: `${titleRejected} (${countRejectedAssociates})`,
      value: countRejectedAssociates,
      accessStatus: AccessStatus.REJECTED,
    },
    {
      id: titleUnused,
      label: `${titleUnused} (${countUnusedAssociates})`,
      value: countUnusedAssociates,
      accessStatus: AccessStatus.UNUSED,
    },
  ];

  const [accessStatusModalOpen, setAccessStatusModalOpen] = useState(false);
  const [accessStatusModalType, setAccessStatusModalType] = useState("");

  const openAccessStatusModal = (accessStatusType: string) => {
    setAccessStatusModalType(accessStatusType);
    setAccessStatusModalOpen(true);
  };

  const accessStatusModalAssociates = (
    accessStatusType: string
  ): AssociateStatisticsEntry[] | undefined => {
    switch (accessStatusType.toUpperCase()) {
      case AccessStatus.AUTHORIZED:
        return props.data?.authorizedAssociates;
      case AccessStatus.REJECTED:
        return props.data?.rejectedAssociates;
      case AccessStatus.UNUSED:
        return props.data?.unusedAssociates;
      default:
        return [];
    }
  };

  return (
    <>
      <Box textAlign={"center"}>{t("dashboard.associates.accessStatus.title")}</Box>
      <ResponsivePie
        margin={{ top: 10, right: 0, bottom: 80, left: 0 }}
        data={associatesByAccessStatusChartData}
        animate={true}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeInnerRadiusOffset={6}
        layers={["arcs", "legends"]}
        colors={byAccessTheme.palette.pie}
        theme={useNivoTheme()}
        onClick={(datum: ComputedDatum<AssociateByAccessChartDatum>) => {
          openAccessStatusModal(datum.data.accessStatus);
        }}
        legends={[
          {
            anchor: "bottom-left",
            direction: "column",
            symbolShape: "circle",
            itemWidth: 100,
            itemHeight: 20,
            translateY: 70,
            translateX: 30,
            onClick: (d) => {
              openAccessStatusModal(d.id as string);
            },
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 0.5,
                },
              },
            ],
          },
        ]}
      />
      {accessStatusModalOpen && (
        <AssociatesAccessStatusModal
          open={accessStatusModalOpen}
          onClose={() => setAccessStatusModalOpen(false)}
          modalType={accessStatusModalType}
          associates={accessStatusModalAssociates(accessStatusModalType)}
        />
      )}
    </>
  );
};

export default AssociatesByAccess;
