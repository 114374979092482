import { useState } from "react";
import { ComputedDatum, ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { byAccessTheme, useNivoTheme } from "../../../../common/theme";
import {
  AccessStatus,
  VehicleAccessStatistics,
  VehicleStatisticsEntry,
} from "../../../../redux/api/StatisticsApi";
import VehiclesAccessStatusModal from "./VehiclesAccessStatusModal";
import { Box } from "@mui/material";

interface VehicleByAccessChartDatum {
  id: string;
  label: string;
  value: number;
  accessStatus: AccessStatus;
}

const VehiclesByAccess = (props: { data?: VehicleAccessStatistics }) => {
  const { t } = useTranslation();
  const titleAuthorized = t("dashboard.vehicles.accessStatus.authorized");
  const titleRejected = t("dashboard.vehicles.accessStatus.rejected");
  const titleUnused = t("dashboard.vehicles.accessStatus.unused");
  const countAuthorizedVINs = props.data?.authorizedVehicles?.length ?? 0;
  const countRejectedVINs = props.data?.rejectedVehicles?.length ?? 0;
  const countUnusedVINs = props.data?.unusedVehicles?.length ?? 0;

  const vehiclesByAccessStatusChartData = [
    {
      id: titleAuthorized,
      label: `${titleAuthorized} (${countAuthorizedVINs})`,
      value: countAuthorizedVINs,
      accessStatus: AccessStatus.AUTHORIZED,
    },
    {
      id: titleRejected,
      label: `${titleRejected} (${countRejectedVINs})`,
      value: countRejectedVINs,
      accessStatus: AccessStatus.REJECTED,
    },
    {
      id: titleUnused,
      label: `${titleUnused} (${countUnusedVINs})`,
      value: countUnusedVINs,
      accessStatus: AccessStatus.UNUSED,
    },
  ];

  const [accessStatusModalOpen, setAccessStatusModalOpen] = useState(false);
  const [accessStatusModalType, setAccessStatusModalType] = useState("");

  const openAccessStatusModal = (accessStatusType: string) => {
    setAccessStatusModalType(accessStatusType);
    setAccessStatusModalOpen(true);
  };

  const accessStatusModalVehicles = (
    accessStatusType: string
  ): VehicleStatisticsEntry[] | undefined => {
    switch (accessStatusType.toUpperCase()) {
      case AccessStatus.AUTHORIZED:
        return props.data?.authorizedVehicles;
      case AccessStatus.REJECTED:
        return props.data?.rejectedVehicles;
      case AccessStatus.UNUSED:
        return props.data?.unusedVehicles;
      default:
        return [];
    }
  };

  return (
    <>
      <Box textAlign={"center"}>{t("dashboard.vehicles.accessStatus.title")}</Box>
      <ResponsivePie
        margin={{ top: 10, right: 0, bottom: 80, left: 0 }}
        data={vehiclesByAccessStatusChartData}
        animate={true}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeInnerRadiusOffset={6}
        layers={["arcs", "legends"]}
        colors={byAccessTheme.palette.pie}
        theme={useNivoTheme()}
        onClick={(datum: ComputedDatum<VehicleByAccessChartDatum>) => {
          openAccessStatusModal(datum.data.accessStatus);
        }}
        legends={[
          {
            anchor: "bottom-left",
            direction: "column",
            symbolShape: "circle",
            itemWidth: 100,
            itemHeight: 20,
            translateY: 70,
            translateX: 30,
            onClick: (d) => {
              openAccessStatusModal(d.id as string);
            },
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 0.5,
                },
              },
            ],
          },
        ]}
      />
      {accessStatusModalOpen && (
        <VehiclesAccessStatusModal
          open={accessStatusModalOpen}
          onClose={() => setAccessStatusModalOpen(false)}
          modalType={accessStatusModalType}
          vehicles={accessStatusModalVehicles(accessStatusModalType)}
        />
      )}
    </>
  );
};

export default VehiclesByAccess;
