import { Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { useGetUserQuery } from "../../redux/api/UserApi";
import AccountMenuItem, { maxChars } from "./AccountMenuItem";

const buffer = 15;

const UsernameSection = () => {
  const { user, username, name, identity, technicalUser } = useAuth();
  const { data } = useGetUserQuery(
    !user || identity !== "verified" || technicalUser ? skipToken : undefined
  );
  const { t } = useTranslation();

  const getUser = () => {
    let usr = data?.name || "";
    // Use the IdP details if the user details is empty yet
    if (!usr) {
      if (username) {
        usr = username;
      } else if (name) {
        usr = name;
      } else if (user) {
        usr = user;
      }
    }

    if (usr.length > maxChars - buffer && usr.includes("@")) {
      const parts = usr.split("@");
      usr = `${usr[0]}...@${parts[1]}`;
    }

    return {
      user: usr,
    };
  };

  return (
    <AccountMenuItem inactive>
      <Typography variant="inherit" noWrap>
        {t("menu.signed.in.as", getUser())}
      </Typography>
    </AccountMenuItem>
  );
};

export default UsernameSection;
