interface FileUploadProps {
  inputRef: React.RefObject<HTMLInputElement>;
  onFileSelected: (files: File[] | FileList) => void;
}

const useFileUpload = ({ inputRef, onFileSelected }: FileUploadProps) => {
  const openDialog = () => {
    const input = inputRef.current;

    if (input) {
      input.onchange = (event: Event) => {
        const target = event.target;
        if (target) {
          const htmlInputElement = target as HTMLInputElement;
          const files = htmlInputElement.files;

          if (onFileSelected && files) {
            onFileSelected(files);
            htmlInputElement.value = "";
          }
        }
      };

      input.click();
    }
  };

  return { openDialog };
};

export default useFileUpload;
