import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import colorCodes from "../assets/styles/colorCodes.json";
import { GridSlotsComponentsProps } from "@mui/x-data-grid";
import { Button, Container, Typography } from "@mui/material";

export const TablePageWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  flexGrow: "1",
  maxWidth: `${theme.breakpoints.values.lg}px`,
  margin: "0 auto",
  width: "100%",
}));

export const TableContentWrapper = styled(ContentWrapper)<{
  displayAll?: boolean;
}>(({ theme, displayAll }) => ({
  minHeight: "600px",
  overflow: "auto",
  ...(displayAll && {
    "& .MuiDataGrid-root": {
      [theme.breakpoints.down("sm")]: {
        minWidth: "1000px",
      },
    },
  }),
}));

export const ChartView = styled("div")(() => ({
  width: "100%",
  height: "auto",
}));

export const PrintableSwitch = styled("div")(
  ({ "aria-checked": ariaChecked }) => ({
    "@media print": {
      ".MuiSwitch-root": {
        display: "none",
      },
      ":after": {
        content: `"${ariaChecked}"`,
      },
    },
  })
);

export const tableComponentProps: GridSlotsComponentsProps = {
  baseButton: {
    size: "medium",
    color: "secondary",
    variant: "outlined",
  },
  panel: {
    placement: "bottom",
  },
  filterPanel: {
    sx: {
      p: 2,
    },
    filterFormProps: {
      columnInputProps: {
        size: "small",
        sx: { mt: "auto", mx: 2 },
      },
      operatorInputProps: {
        size: "small",
        sx: { mt: "auto", mx: 2 },
      },
    },
  },
};

export const paintToWebColor = (color: string): string =>
  (color && (colorCodes as Record<string, string>)[color]) ||
  colorCodes.default;

export const TooltipTable = styled("table")(({ theme }) => ({
  margin: "0.5rem",
  borderCollapse: "collapse",

  "& > tbody > tr": {
    "&:first-of-type": {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },

    "th, td": {
      padding: "0.5rem",
    },
  },
}));

export const FormPageWrapper = styled(Container)(() => ({
  marginTop: "3rem",
}));

export const FormPageTitle = styled(Typography)(() => ({
  // Overriding the default header margin
  "@media (min-width: 900px)": {
    marginBottom: "1.5rem",
  },
}));

export const FormPageDescription = styled(Typography)(() => ({
  marginBottom: "1rem",
}));

export const FormPageSubmitButton = styled(Button)(() => ({
  marginTop: "1.5rem",
}));

export const TableActions = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  // If its wrapping then the last element will be positioned at the top
  flexWrap: "wrap-reverse",
  justifyContent: "end",

  "& .MuiButton-root": {
    // Stretch buttons to equal width on small screen sizes
    width: "100%",
  },

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",

    "& .MuiButton-root": {
      width: "auto",
    },
  },
}));
