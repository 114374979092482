import React, { FC } from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import failoverImg from "../../../assets/images/car-shade.svg";

interface VehicleRenderProps {
  imgUrl?: string;
}

const VehicleRender: FC<VehicleRenderProps> = ({ imgUrl }) => {
  return (
    <Wrapper>
      <Image
        src={imgUrl}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = failoverImg;
        }}
        width="100%"
        loading={"lazy"}
        alt="render"
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  height: "250px",
}));

const Image = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

export default VehicleRender;
