import { ChangeEvent, FC, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useDeleteOrganizationMutation,
  useGetOrganizationQuery,
} from "../../redux/api/OrganizationApi";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { deactivatedRoute } from "../navbar/routes";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";

const DeleteOrganization: FC = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const { data } = useGetOrganizationQuery(organization.publicId);
  const navigate = useNavigate();

  const [deleteOrganization] = useDeleteOrganizationMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [organizationName, setOrganizationName] = useState("");

  const onClose = () => {
    setOrganizationName("");
    setDeleteModalOpen(false);
  };
  const onConfirm = async () => {
    setOrganizationName("");
    setDeleteModalOpen(false);
    try {
      await deleteOrganization(organization.publicId).unwrap();
      navigate(deactivatedRoute.path);
      enqueueSnackbar(t("settings.restricted.delete.success"), {
        variant: "success",
      });
    } catch (error: any) {
      enqueueSnackbar(t("settings.restricted.delete.error"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
  };

  const onDeleteClick = () => {
    if (organization) {
      setDeleteModalOpen(true);
    }
  };

  const onOrganizationNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrganizationName(e.target.value);
  };

  return (
    <>
      <Tooltip title={t("delete.organization.tooltip")}>
        <Button
          variant="outlined"
          size="medium"
          color="error"
          onClick={onDeleteClick}
        >
          {t("delete.organization")}
        </Button>
      </Tooltip>

      {data && (
        <Dialog
          open={deleteModalOpen}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            {t("delete.confirm.organization.title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("delete.confirm.organization.description")}
            </DialogContentText>
            <TextField
              autoFocus
              focused
              fullWidth
              label={t("settings.organization.name")}
              placeholder={organization.name}
              type="text"
              variant="standard"
              value={organizationName}
              onChange={onOrganizationNameChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              variant="outlined"
              size="medium"
              color="secondary"
            >
              {t("cancel")}
            </Button>
            <Button
              data-cy="modal.delete.confirmation"
              onClick={onConfirm}
              variant="contained"
              size="medium"
              color="primary"
              disabled={organizationName !== data.name}
            >
              {t("delete")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeleteOrganization;
