import { configureStore, TypedStartListening } from "@reduxjs/toolkit";
import authSlice from "./slice/AuthSlice";
import organizationSettingsSlice from "./slice/OrganizationSettingsSlice";
import { useDispatch } from "react-redux";
import { baseApi } from "./api/baseApi";
import tourSlice from "./slice/TourSlice";
import { vehicleApprovalsApi } from "./api/VehicleApprovalApi";
import { requestErrorMiddleware } from "./requestErrorMiddleware";
import { authErrorMiddleware } from "./authErrorMiddleware";
import demoSlice from "./slice/DemoSlice";
import userSettingsSlice from "./slice/UserSettingsSlice";
import { listenerMiddleware } from "./listenerMiddleware";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    organizationSettings: organizationSettingsSlice.reducer,
    vehicleApprovals: vehicleApprovalsApi.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
    tour: tourSlice.reducer,
    demo: demoSlice.reducer,
    userSettings: userSettingsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseApi.middleware)
      .concat(authErrorMiddleware)
      .concat(requestErrorMiddleware)
      .prepend(listenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
