import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { useCreateAssociateMutation } from "../../redux/api/AssociatesApi";
import { useTranslation } from "react-i18next";
import { emailRegExp } from "./table/AssociateHelpers";
import { useForm } from "react-hook-form";
import { validateSpecialCharacters } from "../validateSpecialCharacters";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";
interface FormValues {
  name: string;
  emailAddress: string;
}

const CreateAssociate = () => {
  const { t } = useTranslation();
  const router = useNavigate();
  const [createAssociate] = useCreateAssociateMutation();
  const organization = useSelectedOrganization();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();

  const onSubmit = async (data: FormValues) => {
    try {
      await createAssociate({
        associate: data,
        organizationId: organization.publicId,
      }).unwrap();
      router("/associates");
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (errorResponse: any) => {
    if (errorResponse.status === 409) {
      setError("emailAddress", {
        type: "custom",
        message: t(`${errorResponse.data.message}`),
      });
    }
  };

  const cancelOnClick = () => {
    router("/associates");
  };

  return (
    <>
      <Container component={"main"} maxWidth={"xs"}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            required
            margin="normal"
            fullWidth
            label={t("associate.name")}
            type="text"
            autoComplete="name"
            error={!!errors.name}
            autoFocus
            multiline
            helperText={errors.name?.message}
            {...register("name", {
              validate: validateSpecialCharacters("name"),
              required: { value: true, message: t("validation.name.blank") },
            })}
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            required
            margin="normal"
            fullWidth
            id="email"
            label={t("associate.email")}
            autoComplete="email"
            error={!!errors.emailAddress}
            helperText={errors.emailAddress?.message}
            multiline
            {...register("emailAddress", {
              required: {
                value: true,
                message: t("validation.email.blank"),
              },
              pattern: {
                value: emailRegExp,
                message: t("validation.email.invalid"),
              },
            })}
            inputProps={{ maxLength: 255 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("create")}
          </Button>
          <Button
            style={{ marginTop: 0 }}
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={cancelOnClick}
          >
            {t("cancel")}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default CreateAssociate;
