import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { emphasizedDarkBackground } from "../../../common/theme";

const IntroSection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Container maxWidth="md">
        <Title>{t("landing.intro.title")}</Title>
        <Description>{t("landing.intro.description")}</Description>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled("article")(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? emphasizedDarkBackground
      : theme.palette.background.default,
  flex: "1 0 70vh",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const Title = styled("h2")(({ theme }) => {
  const typography = theme.typography as TypographyOptions;
  return {
    ...typography.h1,
    fontSize: "clamp(2.25rem, 4vw + 1rem, 3.125rem)",
    fontWeight: "bold",
    lineHeight: "clamp(2.875rem, 3vw + 1rem, 3.75rem)",
    letterSpacing: "0.01562rem",
    padding: 0,
    margin: "0 0 1.25rem",
  };
});

const Description = styled("p")(({ theme }) => {
  const typography = theme.typography as TypographyOptions;
  return {
    ...typography.subtitle1,
    fontSize: "clamp(1.125rem, 0.5vw + 1rem, 1.25rem)",
    lineHeight: "1.875rem",
    letterSpacing: ".01562rem",
    fontWeight: 300,
    margin: "0 0 1.875rem",
    maxWidth: "50ch",
  };
});

export default IntroSection;
