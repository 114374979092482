import { Button, CircularProgress, Container, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useConfirmMutation } from "../../redux/api/ConfirmationApi";
import {
  Verified as VerifiedIcon,
  NewReleases as UnverifiedIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Confirmation = () => {
  const { t } = useTranslation();
  const [confirm, { isLoading }] = useConfirmMutation();

  const [success, setSuccess] = useState<boolean>();
  let { id } = useParams();

  const sendConfirmation = async () => {
    if (!id) {
      return;
    }

    try {
      await confirm(id).unwrap();
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
    }
  };

  useEffect(() => {
    sendConfirmation();
  }, []);

  const getTitle = () => {
    let key;
    switch (success) {
      case true:
        key = "confirmation.success.title";
        break;
      case false:
        key = "confirmation.failed.title";
        break;
      default:
        key = "confirmation.pending.title";
        break;
    }
    return t(key);
  };

  const getSuccessMessage = () => {
    return (
      <Content>
        <VerifiedIcon color="success" fontSize="large" />
        <Text>{t("confirmation.success.description")}</Text>
      </Content>
    );
  };

  const getErrorMessage = () => {
    return (
      <Content>
        <UnverifiedIcon color="error" fontSize="large" />
        <Text>{t("confirmation.failed.description")}</Text>
      </Content>
    );
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <Content>
          {/* The size matches the size of the result icons */}
          <CircularProgress size={35} />
          <Text>{t("confirmation.pending.description")}</Text>
        </Content>
      );
    }

    return success ? getSuccessMessage() : getErrorMessage();
  };

  return (
    <Wrapper>
      <Typography variant="h2">{getTitle()}</Typography>
      {getContent()}
      <Button
        component={Link}
        variant="contained"
        size="medium"
        color="primary"
        to="/"
      >
        {t("confirmation.login")}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled((props: any) => <Container maxWidth="md" {...props} />)(
  () => ({
    marginTop: "8rem",
  })
);

const Content = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "2rem",
}));

const Text = styled(Typography)(() => ({
  marginLeft: "1rem",
}));

export default Confirmation;
