import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TableContentWrapper, TablePageWrapper } from "../../common/styles";

const MyVehicleComponent = () => {
  const { t } = useTranslation();
  return (
    <TablePageWrapper>
      <Typography variant="h1">{t("myVehicle")}</Typography>
      <TableContentWrapper></TableContentWrapper>
    </TablePageWrapper>
  );
};

export default MyVehicleComponent;
