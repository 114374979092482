import { t } from "i18next";

export const getTableLocaleText = () => {
  const filtersCountTooltip = (count: number) =>
    t(`table.filters.${count !== 1 ? "plural" : "singular"}`, {
      count: count,
    });

  return {
    toolbarExport: t("table.export"),
    toolbarExportCSV: t("table.export.csv"),
    toolbarExportPrint: t("table.export.print"),
    toolbarFilters: t("table.filter"),
    filterValueAny: "",
    filterValueTrue: t("yes"),
    filterValueFalse: t("no"),
    noRowsLabel: t("table.empty"),
    toolbarFiltersTooltipShow: t("table.filters.show"),
    toolbarFiltersTooltipHide: t("table.filters.hide"),
    toolbarFiltersTooltipActive: filtersCountTooltip,
    columnsPanelTextFieldLabel: t("table.columns.find"),
    checkboxSelectionHeaderName: t("table.columns.selection"),
    columnsPanelTextFieldPlaceholder: t("table.columns.find.placeholder"),
    columnsPanelShowAllButton: t("table.columns.showall"),
    columnsPanelHideAllButton: t("table.columns.hideall"),
    filterPanelColumns: t("table.filter.columns"),
    filterPanelOperators: t("table.filter.operators"),
    filterPanelInputLabel: t("table.filter.value"),
    filterPanelInputPlaceholder: t("table.filter.value.placeholder"),
    filterPanelDeleteIconLabel: t("table.filter.delete"),
    filterOperatorContains: t("table.filter.operator.contains"),
    filterOperatorIs: t("table.filter.operator.is"),
    columnMenuLabel: t("table.column.menu"),
    columnMenuShowColumns: t("table.column.show"),
    columnMenuFilter: t("table.column.filter"),
    columnMenuHideColumn: t("table.column.hide"),
    columnMenuUnsort: t("table.column.unsort"),
    columnMenuSortAsc: t("table.column.sort.asc"),
    columnMenuSortDesc: t("table.column.sort.desc"),
    columnHeaderSortIconLabel: t("table.column.sort"),
    columnHeaderFiltersTooltipActive: filtersCountTooltip,
    footerRowSelected: (count: number) =>
      t(`table.selectedrows.${count !== 1 ? "plural" : "singular"}`, {
        count: count,
      }),
  };
};
