export enum ExternalAuthorizeEventStatusReason {
  ASSOCIATE_DOES_NOT_EXIST = "ASSOCIATE_DOES_NOT_EXIST",
  ASSOCIATE_NOT_ACTIVATED = "ASSOCIATE_NOT_ACTIVATED",
  ASSOCIATE_DISABLED = "ASSOCIATE_DISABLED",
  VIN_DOES_NOT_EXIST = "VIN_DOES_NOT_EXIST",
  VIN_DISABLED = "VIN_DISABLED",
  VIN_ASSOCIATE_GROUP_ID_DIFFERENT = "VIN_ASSOCIATE_GROUP_ID_DIFFERENT",
}

export enum ExternalAuthorizeEventResult {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  UNUSED = "UNUSED",
}

export interface ExternalAuthorizeEvent {
  time: number; //unix timestamp (in seconds)
  vin: string;
  email: string;
  result: boolean;
  reason: ExternalAuthorizeEventStatusReason;
}
