import React, { FC } from "react";
import { ChartView } from "../../../common/styles";
import ViewWrapper from "./ViewWrapper";
import { Property } from "csstype";

interface ChartViewWrapperProps {
  id?: string;
  title?: string;
  align?: Property.TextAlign;
}

const ChartViewWrapper: FC<ChartViewWrapperProps> = ({
  id,
  title,
  children,
}) => {
  return (
    <ViewWrapper id={id} title={title}>
      <ChartView>{children}</ChartView>
    </ViewWrapper>
  );
};

export default ChartViewWrapper;
