import {styled} from "@mui/system";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Link from '@mui/material/Link';
import {Grid} from "@mui/material";

export function Infoline() {
    return (
        <Wrapper>
            <Grid item>
                <PhoneIcon sx={{ color: 'primary.main', display: 'flex' }} />
            </Grid>
            <Grid item>
                <ContactLink href="tel:+420800600000">
                    +420 800 600 000
                </ContactLink>
            </Grid>
        </Wrapper>
    );
}

export function Email() {
    return (
        <Wrapper>
            <Grid item>
                <EmailIcon sx={{ color: 'primary.main', display: 'flex' }} />
            </Grid>
            <Grid item>
                <ContactLink href="mailto:infoline@skoda-auto.cz">
                    infoline@skoda-auto.cz
                </ContactLink>
            </Grid>
        </Wrapper>
    );
}

const ContactLink = styled((props: any) => (
    <Link {...props}/>
))(() => ({
    color: "white",
    textDecoration: "none",
    "&:hover": {
        textDecoration: "underline"
    }
}))

const Wrapper = styled((props: any) => (
    <Grid container spacing={1} {...props}/>
))(() => ({
    flexDirection: "row",
    alignItems: "center",
    wrap: "nowrap"
}))
