import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, RouteProps } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useGetOrganizationQuery } from "../redux/api/OrganizationApi";
import { RootState } from "../redux/store";
import IdentityComponent from "./identity/IdentityComponent";
import { deactivatedRoute } from "./navbar/routes";

const ActiveOrganizationRoute: FC<RouteProps> = () => {
  const { user, technicalUser, identity, role } = useAuth();
  const organization = useSelector(
    (state: RootState) => state.userSettings.organization
  );
  const { data } = useGetOrganizationQuery(
    !user || technicalUser || identity !== "verified" || !organization
      ? skipToken
      : organization.publicId
  );

  const identityUnverified =
    user && !technicalUser && identity === "unverified";

  if (identityUnverified) {
    return <IdentityComponent />;
  }

  if (
    user &&
    identity === "verified" &&
    role === "ROLE_organization_admin" &&
    !organization
  ) {
    return <></>;
  }

  return data?.deletionTime ? (
    <Navigate to={deactivatedRoute.path} replace />
  ) : (
    <Outlet />
  );
};

export default ActiveOrganizationRoute;
