/**
 * Downloads the blob content as a file.
 * @param blob encoded content
 * @param filename (optional) filename
 * @returns reference to the anchor
 */
export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  return click({ href: url, download: filename });
};

/**
 * Downloads the given Base64 encoded content as a file.
 * @param encoded encoded content
 * @param filename (optional) filename
 * @returns reference to the anchor
 */
export const downloadBase64 = (encoded: string, filename?: string) => {
  return click({ href: encoded, download: filename || "download" });
};

/**
 * Downloads the given link target as a file.
 * @param href link target
 * @param filename (optional) file name to be used for the download
 * @returns reference to the anchor
 */
export const download = (href: string, filename?: string) => {
  return click({ href, download: filename || "download" });
};

export const open = (blob: Blob) => {
  const url = URL.createObjectURL(blob);
  return click({ href: url, target: "_blank", rel: "noreferrer" });
};

/**
 * Simulates the download user-event. This is particularly useful if we request a file from the server through ajax.
 * @param linkProps
 * @returns
 */
const click = (
  linkProps: Partial<
    Pick<HTMLAnchorElement, "href" | "download" | "target" | "rel">
  >
) => {
  // Temporary anchor element
  const element = document.createElement("a");

  Object.assign(element, linkProps);

  // Release the object URL after the element has been clicked
  const clickHandler = () => {
    setTimeout(() => {
      if (element.href) {
        URL.revokeObjectURL(element.href);
      }
      element.removeEventListener("click", clickHandler);
    }, 150);
  };

  element.addEventListener("click", clickHandler, false);

  // Kick off the download
  element.click();
  return element;
};

export const toSearchParams = (request: object) => {
  const params = { ...request };
  Object.keys(request).forEach((key) => {
    const objKey = key as keyof typeof params;
    if (request[objKey] === undefined) {
      delete request[objKey];
    }
  });
  return new URLSearchParams(params).toString();
};
