import { isRejectedWithValue, PayloadAction } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getI18n } from "react-i18next";

type RequestErrorAction = PayloadAction<{ status?: number }>;

const isSuccessful = (status: number) => status >= 200 && status < 300;

export const requestErrorMiddleware: Middleware =
  (_: MiddlewareAPI) => (next) => (action: RequestErrorAction) => {
    const result = next(action);

    if (
      isRejectedWithValue(action) &&
      action.payload.status &&
      !isSuccessful(action.payload.status)
    ) {
      showNotification(action);
    }

    return result;
  };

const showNotification = (action: RequestErrorAction) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      const { status } = action.payload;
      if (enqueueSnackbar) {
        enqueueSnackbar(getI18n().t("error.request.message"), {
          variant: "error",
          preventDuplicate: true,
          key: status,
        });
      }
      resolve();
    }, 200);
  });
};
