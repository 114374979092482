import { ManageAccounts as ManageAccountsIcon } from "@mui/icons-material";
import { ListItemIcon, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { accountRoute } from "../navbar/routes";
import AccountMenuItem from "./AccountMenuItem";

interface ManagementSectionProps {
  onClick?: () => void;
}

const ManagementSection: FC<ManagementSectionProps> = ({ onClick }) => {
  const { identity, roles } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onAccountClick = () => {
    if (onClick) {
      onClick();
    }
    navigate(accountRoute.path);
  };

  return identity === "verified" &&
    roles.includes("ROLE_organization_admin") ? (
    <AccountMenuItem disableRipple onClick={onAccountClick}>
      <ListItemIcon>
        <ManageAccountsIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="inherit" noWrap>
        {t("menu.account.management")}
      </Typography>
      &nbsp;
    </AccountMenuItem>
  ) : (
    <></>
  );
};

export default ManagementSection;
