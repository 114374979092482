import { FC, useEffect, useMemo } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Joyride, { ACTIONS, CallBackProps } from "react-joyride";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { routes } from "../navbar/routes";
import Tooltip from "../tooltip/Tooltip";
import { useAuth } from "../../hooks/useAuth";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { finish, start, goto, reset, stop } from "../../redux/slice/TourSlice";
import { useTranslation } from "react-i18next";
import {
  endStates,
  mobileStepIds,
  mobileSteps,
  proceedingStates,
  stepIds,
  steps,
} from "./constants";
import { Action } from "history";

const Tour: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, identity, technicalUser } = useAuth();
  const theme = useTheme();
  const dispatch = useDispatch();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const { running, step, mobileMenuOpen, finished } = useSelector(
    (state: RootState) => state.tour
  );
  const { finished: isDemoFinished } = useSelector(
    (state: RootState) => state.demo
  );
  const { t } = useTranslation();
  const navigationType = useNavigationType();

  const continueTourDesktop = () => {
    window.scrollTo(0, 0);
    if (pathname === routes[1].path) {
      // Arrived on the Associates page ...
      setTimeout(() => {
        // ... step to upload Associate
        dispatch(goto(stepIds.uploadAssociates));
      }, 500);
    } else if (pathname === routes[2].path) {
      // Arrived on the Vehicles page ...
      setTimeout(() => {
        // ... step to select vehicle details
        dispatch(goto(stepIds.selectVehicleDetails));
      }, 500);
    } else if (pathname.match(`${routes[2].path}/detail/*`)) {
      // Arrived on the Vehicle detail page ...
      setTimeout(() => {
        // ... finish tour
        dispatch(goto(stepIds.finishTour));
      }, 750);
    }
  };

  const continueTourMobile = () => {
    if (pathname === routes[0].path && mobileMenuOpen) {
      // Arrived on the Dashboard page & drawer open ...
      setTimeout(() => {
        // ... step to select Associates
        dispatch(goto(mobileStepIds.selectAssociates));
      }, 500);
    } else if (pathname === routes[1].path) {
      // Arrived on the Associates page ...
      setTimeout(() => {
        // ... step to upload Associate
        dispatch(goto(mobileStepIds.uploadAssociates));
      }, 500);
    } else if (pathname === routes[2].path) {
      // Arrived on the Vehicles page ...
      setTimeout(() => {
        // ... step to select vehicle details
        dispatch(goto(mobileStepIds.selectVehicleDetails));
      }, 500);
    } else if (pathname.match(`${routes[2].path}/detail/*`)) {
      // Arrived on the Vehicle detail page ...
      setTimeout(() => {
        // ... finish tour
        dispatch(goto(mobileStepIds.finishTour));
      }, 750);
    }
  };

  const continueTour = () => {
    if (desktop) {
      continueTourDesktop();
    } else {
      continueTourMobile();
    }
  };

  const nextTourStepDesktop = (index: number, action: string) => {
    window.scrollTo(0, 0);
    if (index === stepIds.selectAssociates) {
      // After Associates step reached, navigate to Associates page
      navigate(routes[1].path);
    } else if (index === stepIds.selectVehicles) {
      // After Vehicles step reached, navigate to Vehicles page
      navigate(routes[2].path);
    } else if (index === steps.length - 1) {
      // After the last step, navigate back to the Dashboard and step towards the finish
      navigate(routes[0].path);
      dispatch(goto(steps.length));
    } else {
      const stepIndex = action === ACTIONS.PREV ? index - 1 : index + 1;
      dispatch(goto(stepIndex));
    }
  };

  const nextTourStepMobile = (index: number, action: string) => {
    if (index === mobileStepIds.selectAssociates) {
      // After Associates step reached, navigate to Associates page
      navigate(routes[1].path);
    } else if (index === mobileStepIds.uploadAssociates) {
      // After the Upload Associates step reached, navigate to Vehicles page
      navigate(routes[2].path);
    } else if (index === mobileSteps.length - 1) {
      // After the last step, navigate back to the Dashboard and step towards the finish
      navigate(routes[0].path);
      dispatch(goto(steps.length));
    } else {
      const stepIndex = action === ACTIONS.PREV ? index - 1 : index + 1;
      dispatch(goto(stepIndex));
    }
  };

  const nextTourStep = (index: number, action: string) => {
    if (desktop) {
      nextTourStepDesktop(index, action);
    } else {
      nextTourStepMobile(index, action);
    }
  };

  const finishTour = () => {
    dispatch(finish());
  };

  const startTour = () => {
    dispatch(start());
  };

  const onTourNavigation = (data: CallBackProps) => {
    if (finished) {
      if (running) {
        dispatch(stop());
      }
      return;
    }

    const { action, index, type, status } = data;

    if (endStates.includes(status)) {
      finishTour();
    } else if (proceedingStates.includes(type)) {
      nextTourStep(index, action);
    }
  };

  const tourSteps = useMemo(() => {
    const rawSteps = desktop ? steps : mobileSteps;
    return rawSteps.map((step) => ({
      ...step,
      title: t(step.title as string),
      content: t(step.content as string),
    }));
  }, [desktop, t]);

  useEffect(() => {
    if (
      !user ||
      identity !== "verified" ||
      technicalUser === true ||
      finished === undefined
    ) {
      return;
    }
    // Only start the tour if we are on the Dashboard page
    if (
      isDemoFinished &&
      !finished &&
      step === 0 &&
      pathname === routes[0].path
    ) {
      setTimeout(() => {
        startTour();
      }, 750);
    }
  }, [user, identity, isDemoFinished, finished, step, pathname]);

  useEffect(() => {
    // Continue the tour if navigation happened (only allow forward)
    if (running) {
      continueTour();
    }
  }, [desktop, pathname, mobileMenuOpen, running]);

  useEffect(() => {
    if (navigationType === Action.Pop) {
      dispatch(reset());
    }
  }, [navigationType]);

  useEffect(() => {
    if (!user && running) {
      finishTour();
    }
  }, [user]);

  return (
    <Joyride
      steps={tourSteps}
      run={running}
      stepIndex={step}
      showProgress
      continuous
      styles={{
        options: {
          zIndex: 10_000,
          primaryColor: theme.palette.primary.main,
        },
      }}
      callback={onTourNavigation}
      tooltipComponent={Tooltip}
      disableScrolling
    />
  );
};

export default Tour;
