import { Paper, styled } from "@mui/material";
import Box from "@mui/material/Box";
import { FC } from "react";
import { VehicleDetail } from "../../../redux/api/VehiclesApi";
import VehicleInfo from "./VehicleInfo";
import VehicleEquipment from "./VehicleEquipment";
import VehicleRender from "./VehicleRender";
import VehicleCalendarAccess from "./VehicleCalendarAccess";
import VehicleStatus from "./VehicleStatus";

interface VehicleGridProps {
  data: VehicleDetail;
}

const VehicleGrid: FC<VehicleGridProps> = ({ data }) => {
  return (
    <VehicleGridBox>
      <Box sx={{ gridArea: "info" }}>
        <CardSection>
          <VehicleInfo data={data} />
        </CardSection>
      </Box>
      <Box sx={{ gridArea: "render" }}>
        <CardSection>
          <VehicleRender imgUrl={data?.vehicleImages?.mainImageUrl} />
        </CardSection>
      </Box>
      <Box sx={{ gridArea: "equipment" }}>
        <CardSection>
          <VehicleEquipment
            iasVehicleData={data.iasVehicleData}
            colorCode={data.paintColor}
          />
        </CardSection>
      </Box>
      <Box sx={{ gridArea: "status" }}>
        <CardSection>
          <VehicleStatus data={data} />
        </CardSection>
      </Box>
      <Box sx={{ gridArea: "calendarAccess" }}>
        <CardSection>
          <VehicleCalendarAccess data={data} />
        </CardSection>
      </Box>
    </VehicleGridBox>
  );
};

const VehicleGridBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    gridTemplateAreas: `
      "render info"
      "status status"
      "calendarAccess equipment"
      `,
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(auto-fill, 300px)",
    height: "1000px",
  },
  display: "grid",
  gridTemplateAreas: `"render" 
    "info"
    "equipment"
    "status"
    "calendarAccess"
    `,
  gridTemplateRows: "3fr repeat(2, 2fr) repeat(3, 1fr)",
  gap: "10px",
  width: "100%",
  minWidth: "320px",
  maxWidth: `${theme.breakpoints.values.md}px`,
}));

const CardSection = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  color: theme.palette.text.secondary,
}));

export default VehicleGrid;
