import { Box, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCreateOrganizationMutation } from "../../redux/api/OrganizationApi";
import {
  FormPageDescription,
  FormPageSubmitButton,
  FormPageTitle,
  FormPageWrapper,
} from "../../common/styles";
import { validateSpecialCharacters } from "../validateSpecialCharacters";

interface FormValues {
  name: string;
}

const IdentityComponent = () => {
  const ORGANIZATION_NAME_CHARACTER_LIMIT = 255;
  const [createOrganization] = useCreateOrganizationMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();

  const onSubmit = async (data: FormValues) => {
    const request = {
      organizationName: data.name,
    };

    await createOrganization(request).unwrap().catch(handleError);
  };

  const handleError = (errorResponse: any) => {
    if (errorResponse.status === 400) {
      setError("name", {
        type: "custom",
        message: t(`${errorResponse.data.message}`),
      });
    } else {
      enqueueSnackbar(t("identity.verification.failed"), {
        variant: "error",
        preventDuplicate: true,
        key: errorResponse.status,
      });
    }
  };

  return (
    <FormPageWrapper maxWidth="xs">
      <FormPageTitle variant="h1">{t("identity.title")}</FormPageTitle>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormPageDescription>{t("identity.description")}</FormPageDescription>
        <TextField
          required
          autoFocus
          fullWidth
          label={t("identity.organization.label")}
          type="text"
          error={!!errors.name}
          helperText={errors.name?.message}
          {...register("name", {
            validate: validateSpecialCharacters("name"),
            required: { value: true, message: t("validation.name.blank") },
          })}
          inputProps={{
            maxLength: ORGANIZATION_NAME_CHARACTER_LIMIT,
          }}
        />
        <FormPageSubmitButton
          color="primary"
          type="submit"
          variant="contained"
          size="medium"
          fullWidth
        >
          {t("identity.finish.button")}
        </FormPageSubmitButton>
      </Box>
    </FormPageWrapper>
  );
};

export default IdentityComponent;
