import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { dashboardRoute, getRoutesByRole } from "../navbar/routes";

const SignIn = () => {
  const { authenticated, login, initialized, role } = useAuth();
  const location = useLocation();
  const routes = getRoutesByRole(role);

  useEffect(() => {
    if (initialized && !authenticated) {
      login({ redirectUri: `${window.location.origin}${dashboardRoute.path}` });
    }
  }, [initialized, authenticated]);

  return authenticated ? (
    <Navigate to={routes[0].path} state={{ from: location }} replace />
  ) : (
    <div>Redirecting...</div>
  );
};

export default SignIn;
