import Keycloak from "keycloak-js";
import { AuthClientInitOptions } from "@react-keycloak/core";
import {
  OAUTH_PROVIDER_URL,
  OAUTH_PROVIDER_REALM,
  OAUTH_PROVIDER_CLIENT_ID,
} from "./common/constants";
import {
  authSuccess,
  clientError,
  clientReady,
  resetAuth,
} from "./redux/slice/AuthSlice";
import { AuthClientEvent, AuthClientError } from "@react-keycloak/core";
import { store } from "./redux/store";
import { baseApi } from "./redux/api/baseApi";
import { enqueueSnackbar } from "notistack";
import { getI18n } from "react-i18next";

export const authClient = new Keycloak({
  url: OAUTH_PROVIDER_URL,
  realm: OAUTH_PROVIDER_REALM || "master",
  clientId: OAUTH_PROVIDER_CLIENT_ID || "",
});

export const authClientInitOptions: AuthClientInitOptions = {
  pkceMethod: "S256",
};

export const onAuthEvent = (
  eventType: AuthClientEvent,
  error?: AuthClientError
) => {
  switch (eventType) {
    case "onInitError":
      store.dispatch(clientError(error?.error));
      break;

    case "onReady":
      store.dispatch(clientReady(authClient.tokenParsed?.roles));
      break;

    case "onAuthSuccess":
      store.dispatch(authSuccess(authClient.tokenParsed?.roles));
      break;

    case "onAuthRefreshError":
      // The redirect to the Login screen is handled by the OAuth client lib + ProtectedRoute
      // Plus, we reset the store if the re-authentication failed
      store.dispatch(resetAuth());
      store.dispatch(baseApi.util.resetApiState());
      enqueueSnackbar(getI18n().t("authentication.expired"), {
        preventDuplicate: true,
        variant: "error",
      });
      break;

    default:
      break;
  }
};
