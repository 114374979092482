import {
  Article as DetailIcon,
  Check as CheckIcon,
  Circle as CircleIcon,
  DeleteForever as DeleteIcon,
  FactCheck as VerificationIcon,
  PendingActions as PendingActionsIcon,
  Search as SearchIcon,
  UploadFile as UploadTcIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridActionsCellItem,
  GridApi,
  GridCellParams,
  GridColumns,
  GridFilterModel,
  GridRenderEditCellParams,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridSelectionModel,
  GridSortModel,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Operator, Page } from "../../../common/pagination";
import {
  ApproveState,
  useDeleteVehicleByIdMutation,
  useDeleteVehiclesByIdsMutation,
  useDenyVehicleMutation,
  useGetVehiclesQuery,
  useUpdateVehicleMutation,
  Vehicle,
} from "../../../redux/api/VehiclesApi";
import DeleteModal from "./DeleteModal";
import Toolbar from "./Toolbar";
import { useSnackbar } from "notistack";
import Pagination from "../../pagination/Pagination";
import {
  paintToWebColor,
  tableComponentProps,
  TableContentWrapper,
} from "../../../common/styles";
import { VehiclesContext } from "../VehiclesComponent";
import VerificationModal from "./VerificationModal";
import ConfirmUpdateModal from "../../confirm-update-modal/ConfirmUpdateModal";
import {
  ExternalAuthorizeEventResult,
  ExternalAuthorizeEventStatusReason,
} from "../../../redux/api/ExternalAuthorizeEvent";
import { EditButton } from "./EditButton";
import { TableTextEditComponent } from "../../TableTextEditComponent";
import { SaveButton } from "./SaveButton";
import { CancelButton } from "./CancelButton";
import { getTableLocaleText } from "../../getTableLocaleText";
import { reducedVehiclesVisibilityModel } from "../../../common/small-screen/visibilityModel";
import useSmallScreenProperties from "../../../common/small-screen/useSmallScreenProperties";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { renderStatusColumn } from "../../renderStatusColumn";
import { validateSpecialCharacters } from "../../validateSpecialCharacters";
import InputErrorModal from "../../modal/InputErrorModal";

interface VehiclesTableProps {
  vehicles?: Page<Vehicle[]>;
}

export interface VehicleRow {
  id: string;
  vin?: string;
  deny?: boolean;
  licensePlate?: string;
  externalLastLoginResult?: ExternalAuthorizeEventResult;
  externalLastLoginReason?: ExternalAuthorizeEventStatusReason;
  modelType?: string;
  paintColor?: string;
  state?: ApproveState;
  reasonOfDecline?: string;
  technicalCardUploaded?: boolean;
}

export interface ActionButtonProps {
  rowId: string;
}

interface UpdatePromise {
  newRow: GridRowModel;
  oldRow: GridRowModel;
  reject: (value: unknown) => void;
  resolve: (value: unknown) => void;
}

const mapDataToTable = (vehicles: Vehicle[]): GridRowsProp<VehicleRow> =>
  vehicles?.map(({ publicId, externalLastLoginStatus, ...rest }) => ({
    ...rest,
    id: publicId,
    externalLastLoginResult: externalLastLoginStatus?.result,
    externalLastLoginReason: externalLastLoginStatus?.failReason,
  }));

const mapToEntity = (row: GridRowModel<VehicleRow>): Vehicle => ({
  publicId: row.id,
  licensePlate: row.licensePlate,
  state: row.state,
});

const VehiclesTable: React.FC<VehiclesTableProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [
    displayAll,
    setDisplayAll,
    columnVisibilityModel,
    setColumnVisibilityModel,
  ] = useSmallScreenProperties(reducedVehiclesVisibilityModel);

  const [updatePromise, setUpdatePromiseArguments] = useState<UpdatePromise>();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [inputErrorModalOpen, setInputErrorModalOpen] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [selectedRow, setSelectedRow] = useState<VehicleRow>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [queryOptions, setQueryOptions] = useOutletContext<VehiclesContext>();

  const { data, isFetching, isLoading } = useGetVehiclesQuery(queryOptions);
  const [deleteVehicle] = useDeleteVehicleByIdMutation();
  const [deleteVehicles] = useDeleteVehiclesByIdsMutation();

  const [updateVehicle] = useUpdateVehicleMutation();

  const [deny] = useDenyVehicleMutation();

  const organization = useSelectedOrganization();

  const specialCharactersValidator = validateSpecialCharacters("licensePlate");
  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) =>
    new Promise<GridRowModel>((resolve, reject) => {
      const validationResult = specialCharactersValidator(newRow.licensePlate);
      if (validationResult === true) {
        setInputError(false);
      } else {
        throw new Error(validationResult);
      }
      setUpdatePromiseArguments({ resolve, reject, newRow, oldRow });
    });
  const onProcessRowUpdateError = (error: any) => {
    setInputError(true);
    setInputErrorMessage(error.message);
    setInputErrorModalOpen(true);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteOpen(true);
  };

  const handleOpenVerifyModal = (row: VehicleRow) => {
    setSelectedRow(row);
    setVerificationModalOpen(true);
  };

  const onDeleteClick = (id?: string) => {
    setIsDeleteOpen(true);
    if (id) {
      setSelectionModel([id]);
    }
  };

  const DetailsSection = (params: GridRowParams<VehicleRow>) => {
    const verificationLabel = t("verification");
    const detailLabel = t("detail");
    const deleteLabel = t("delete");
    const apiRef = useGridApiContext<GridApi>();
    return apiRef.current.getRowMode(params.row.id) === "edit"
      ? [
          <SaveButton rowId={params.row.id} />,
          <CancelButton rowId={params.row.id} />,
        ]
      : [
          <EditButton rowId={params.row.id} />,
          params.row.state === ApproveState.ACTIVE ? (
            <GridActionsCellItem
              id={`edit-vehicle-${params.row.vin}-button`}
              className="edit-vehicle-button"
              icon={
                <Tooltip title={detailLabel}>
                  <DetailIcon />
                </Tooltip>
              }
              label={detailLabel}
              onClick={() => navigate(`/vehicles/detail/${params.row.id}`)}
              color="primary"
            />
          ) : (
            <GridActionsCellItem
              id={`edit-vehicle-${params.row.vin}-button`}
              className="edit-vehicle-button"
              icon={
                <Tooltip title={verificationLabel}>
                  <VerificationIcon />
                </Tooltip>
              }
              label={verificationLabel}
              onClick={() => handleOpenVerifyModal(params.row)}
              color="primary"
            />
          ),
          <GridActionsCellItem
            icon={
              <Tooltip title={deleteLabel}>
                <DeleteIcon onClick={() => onDeleteClick()} />
              </Tooltip>
            }
            label={deleteLabel}
            onClick={() => {
              setIsDeleteOpen(!isDeleteOpen);
              setSelectionModel([params.row.id]);
            }}
            color="primary"
          />,
        ];
  };

  const onConfirmDelete = () => {
    setIsDeleteOpen(false);
    try {
      if (selectionModel.length > 0) {
        deleteVehicles({
          ids: selectionModel,
          organizationId: organization.publicId,
        }).unwrap();
      } else if (selectionModel.length === 1) {
        deleteVehicle({
          id: `${selectionModel[0]}`,
          organizationId: organization.publicId,
        }).unwrap();
      }
    } catch (error: any) {
      enqueueSnackbar(t("delete.fail"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
    setSelectionModel([]);
  };

  const onCancelDeleteDialog = () => {
    setIsDeleteOpen(false);
  };

  const onCancelVerifyDialog = () => {
    setVerificationModalOpen(false);
  };

  const onConfirmUpdate = async () => {
    const { newRow, oldRow, reject, resolve } = updatePromise!;
    newRow.licensePlate = newRow.licensePlate.trim();
    try {
      await updateVehicle({
        vehicle: mapToEntity(newRow),
        organizationId: organization.publicId,
      }).unwrap();
      resolve(newRow);
    } catch (error: any) {
      reject(oldRow);
      enqueueSnackbar("Failed to update row", {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    } finally {
      setUpdatePromiseArguments(undefined);
    }
  };

  const onCancelUpdate = () => {
    const { oldRow, resolve } = updatePromise!;
    resolve(oldRow);
    setUpdatePromiseArguments(undefined);
  };

  const onChangeDeny =
    (id: string) => async (_: ChangeEvent, allowAccessChecked: boolean) => {
      try {
        await deny({
          publicId: id,
          deny: !allowAccessChecked,
          organizationId: organization.publicId,
        });
      } catch (error: any) {
        enqueueSnackbar("Failed to switch deny state", {
          variant: "error",
          preventDuplicate: true,
          key: error.status,
        });
      }
    };

  const renderBulkDeleteHeader = () => (
    <>
      {selectionModel.length > 0 && (
        <Tooltip title={t("bulkDelete")}>
          <IconButton onClick={handleOpenDeleteModal}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  const renderDetailColumn = (params: GridCellParams<unknown, VehicleRow>) => {
    const color =
      params.row.paintColor && paintToWebColor(params.row.paintColor);
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <div>{params.row.modelType}</div>
        {color && <CircleIcon sx={{ color }} />}
      </Stack>
    );
  };

  const renderApprovalStatus = (
    params: GridCellParams<unknown, VehicleRow>
  ) => {
    const label = t("vehicles.approve.state." + params.row.state);
    let tooltip = "";
    let icon = undefined;

    switch (params.row.state) {
      case ApproveState.ACTIVE:
        tooltip = t("vehicles.approval.approved.tooltip");
        icon = <CheckIcon />;
        break;
      case ApproveState.DECLINED:
        tooltip = `${t("vehicles.approval.reason")}: ${
          params.row.reasonOfDecline ?? ""
        }`;
        icon = <WarningIcon />;
        break;
      case ApproveState.PENDING:
        if (params.row.technicalCardUploaded) {
          tooltip = t("vehicles.approval.tcUploaded.tooltip");
          icon = <PendingActionsIcon />;
        } else {
          tooltip = t("vehicles.approval.tcNotUploaded.tooltip");
          icon = <UploadTcIcon />;
        }
        break;
      default:
        break;
    }

    return (
      <Tooltip title={tooltip}>
        <Chip label={label} icon={icon} size="small" />
      </Tooltip>
    );
  };

  const renderDenyToggle = (params: GridCellParams<boolean, VehicleRow>) => {
    const tooltip = t(
      params.value
        ? "vehicles.access.denied.tooltip"
        : "vehicles.access.allowed.tooltip"
    );
    return (
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Tooltip title={tooltip}>
          <Switch
            checked={!params.value}
            onChange={onChangeDeny(params.row.id)}
          />
        </Tooltip>
      </Box>
    );
  };

  const columns: GridColumns = [
    {
      field: "vin",
      headerName: "VIN",
      flex: 0.8,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "detail",
      headerName: t("vehicles.detail"),
      flex: 1,
      editable: false,
      renderCell: renderDetailColumn,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "licensePlate",
      headerName: t("vehicles.licensePlate"),
      flex: 0.7,
      editable: true,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
      renderEditCell: (props: GridRenderEditCellParams) => (
        <TableTextEditComponent renderParams={props} maxLength={20} />
      ),
    },
    {
      field: "state",
      headerName: t("vehicles.approval"),
      flex: 0.6,
      valueOptions: Object.values(ApproveState),
      valueFormatter: ({ value }) => t("vehicles.approve.state." + value),
      filterOperators: getGridSingleSelectOperators().filter(
        (s) => s.value === "is"
      ),
      editable: false,
      renderCell: renderApprovalStatus,
      type: "singleSelect",
    },
    {
      field: "status",
      headerName: t("vehicles.last.evaluation.result"),
      flex: 0.7,
      editable: false,
      filterable: true,
      valueOptions: Object.values(ExternalAuthorizeEventResult),
      valueFormatter: ({ value }) =>
        t("vehicles.external.access.state." + value),
      filterOperators: getGridSingleSelectOperators().filter(
        (s) => s.value === "is"
      ),
      sortable: false,
      renderCell: renderStatusColumn,
    },
    {
      field: "deny",
      headerName: t("vehicles.allowAccess"),
      flex: 0.6,
      renderCell: renderDenyToggle,
      valueOptions: ["false", "true"],
      valueFormatter: ({ value }) =>
        value === "true"
          ? t("vehicles.allowAccess.denied")
          : t("vehicles.allowAccess.allowed"),
      filterOperators: getGridSingleSelectOperators().filter(
        (s) => s.value === "is"
      ),
    },
    {
      field: "actions",
      headerName: t("table.columns.actions"),
      type: "actions",
      hideable: true,
      minWidth: 80,
      getActions: DetailsSection,
      renderHeader: renderBulkDeleteHeader,
    },
  ];

  const rows = useMemo(() => {
    return data ? mapDataToTable(data?.content) : [];
  }, [data]);

  const localeText = getTableLocaleText();

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof Vehicle,
      sortOrder: sort?.sort,
    });
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter =
      filterModel.items.length === 0 ? undefined : filterModel.items[0];
    setQueryOptions({
      ...queryOptions,
      filterField: filter?.columnField as keyof Vehicle,
      filterOperator: filter?.operatorValue as Operator,
      filterValue: filter?.value,
    });
  };

  const sortModel = useMemo(() => {
    return queryOptions.sortField
      ? [
          {
            sort: queryOptions.sortOrder,
            field: queryOptions.sortField,
          },
        ]
      : [];
  }, [queryOptions.sortField, queryOptions.sortOrder]);

  const filterModel = useMemo(() => {
    const filter = queryOptions.filterField
      ? [
          {
            columnField: queryOptions.filterField,
            operatorValue: queryOptions.filterOperator,
            value: queryOptions.filterValue,
          },
        ]
      : [];
    return {
      items: filter,
    };
  }, [
    queryOptions.filterField,
    queryOptions.filterOperator,
    queryOptions.filterValue,
  ]);

  useEffect(() => {
    if (selectedRow && data) {
      const selectedVehicleRow = rows.find((row) => row.id === selectedRow.id);
      if (selectedVehicleRow) {
        setSelectedRow(selectedVehicleRow);
      }
    }
  }, [rows, selectedRow]);

  useEffect(() => {
    if (organization) {
      setQueryOptions({
        ...queryOptions,
        organizationId: organization.publicId,
      });
    }
  }, [organization]);

  return (
    <TableContentWrapper displayAll={displayAll}>
      <DataGrid
        localeText={localeText}
        rows={rows}
        rowCount={data?.totalElements || 0}
        columns={columns}
        page={queryOptions.page}
        onPageChange={onPageChange}
        pageSize={queryOptions.size}
        onPageSizeChange={onPageSizeChange}
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        filterMode="server"
        components={{
          Toolbar,
          LoadingOverlay: LinearProgress,
          Pagination,
          OpenFilterButtonIcon: SearchIcon,
        }}
        loading={isFetching || isLoading}
        disableSelectionOnClick
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        editMode="row"
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        componentsProps={{
          ...tableComponentProps,
          toolbar: { displayAll, setDisplayAll },
        }}
        keepNonExistentRowsSelected
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
      />
      <DeleteModal
        isDialogOpened={isDeleteOpen}
        count={selectionModel.length}
        handleCancelDialog={onCancelDeleteDialog}
        handleDeleteDialog={onConfirmDelete}
      />
      {selectedRow && (
        <VerificationModal
          isDialogOpened={verificationModalOpen}
          handleCancelDialog={onCancelVerifyDialog}
          row={selectedRow}
        />
      )}
      <ConfirmUpdateModal
        open={!!updatePromise && !inputError}
        onClose={onCancelUpdate}
        onConfirm={onConfirmUpdate}
      />
      <InputErrorModal
        open={inputErrorModalOpen}
        onClose={() => setInputErrorModalOpen(false)}
        message={inputErrorMessage}
      />
    </TableContentWrapper>
  );
};

export default VehiclesTable;
