import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { start } from "../../../redux/slice/TourSlice";
import ViewWrapper from "./ViewWrapper";

const WizardRecall = () => {
  const { t } = useTranslation();
  const title = t("dashboard.wizard.title");
  const label = t("dashboard.wizard.start");
  const tooltip = t("dashboard.wizard.tooltip");
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(start());
  };

  return (
    <ViewWrapper id="wizard-recall" title={title} align="center">
      <Tooltip title={tooltip}>
        <Button
          onClick={onClick}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {label}
        </Button>
      </Tooltip>
    </ViewWrapper>
  );
};

export default WizardRecall;
