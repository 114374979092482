import { FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteValue,
  Box,
  SvgIcon,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as CzechIcon } from "../../assets/flags/CZ.svg";
import { ReactComponent as EnglishIcon } from "../../assets/flags/EN.svg";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

interface LangOptionType {
  languageCountryCode: string;
  languageName: string;
  icon: FunctionComponent;
}

const languages: LangOptionType[] = [
  { languageCountryCode: "en-GB", languageName: "English", icon: EnglishIcon },
  { languageCountryCode: "cs-CZ", languageName: "Čeština", icon: CzechIcon },
];

const renderLanguageOption = (language: LangOptionType) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          columnGap: "10px",
        }}
      >
        <SvgIcon component={language.icon} inheritViewBox />
        <Typography>{language.languageName}</Typography>
      </Box>
    </>
  );
};

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(languages[0]);
  useEffect(() => {
    if (i18n.language) {
      setCurrentLang(
        languages.find((value) =>
          value.languageCountryCode.includes(i18n.language)
        ) || languages[0]
      );
    }
  }, [i18n.language]);

  if (!i18n.language) return <></>;

  const onLanguageChange = (
    _: SyntheticEvent,
    value: AutocompleteValue<LangOptionType, undefined, undefined, undefined>
  ) => {
    if (value) setCurrentLang(value);
    i18n.changeLanguage(value?.languageCountryCode);
  };

  return (
    <Wrapper>
      {renderLanguageOption(currentLang)}
      <Autocomplete
        size={"small"}
        style={{ width: "200px" }}
        options={languages}
        getOptionLabel={(option) => option.languageName}
        isOptionEqualToValue={(option, value) =>
          option.languageName === value.languageName
        }
        onChange={onLanguageChange}
        renderOption={(props, option) => {
          return <li {...props}>{renderLanguageOption(option)}</li>;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("language.search")}
            sx={{ input: { color: "secondary.main" } }}
          />
        )}
        clearText={t("language.clear")}
        closeText={t("language.close")}
        noOptionsText={t("language.nooptions")}
        openText={t("language.open")}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export default LanguageSelector;
