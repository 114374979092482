import { FC } from "react";
import { ListItemIcon } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { useAppDispatch } from "../../redux/store";
import { Logout as LogoutIcon } from "@mui/icons-material";
import { resetAuth } from "../../redux/slice/AuthSlice";
import { baseApi } from "../../redux/api/baseApi";
import { useTranslation } from "react-i18next";
import AccountMenuItem from "./AccountMenuItem";

interface LogoutSectionProps {
  onClick?: () => void;
}

const LogoutSection: FC<LogoutSectionProps> = ({ onClick }) => {
  const { logout } = useAuth();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onLogoutClick = () => {
    if (onClick) {
      onClick();
    }
    logout({ redirectUri: `${window.location.origin}` }).then(() => {
      dispatch(resetAuth());
      dispatch(baseApi.util.resetApiState());
    });
  };

  return (
    <AccountMenuItem onClick={onLogoutClick}>
      <ListItemIcon>
        <LogoutIcon fontSize="small" />
      </ListItemIcon>
      {t("menu.logout")}
    </AccountMenuItem>
  );
};

export default LogoutSection;
