import React, { FC, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Associate } from "../../redux/api/AssociatesApi";
import {
  mapAssociatesDataToCsv,
  translateAssociatesHeaders,
} from "./AssociatesExportHelper";

const CSVDownload = React.lazy(() =>
  import("react-csv").then(({ CSVDownload }) => ({ default: CSVDownload }))
);

interface AssociateExportProps {
  content: Associate[];
}

const AssociatesExport: FC<AssociateExportProps> = ({ content }) => {
  const { t } = useTranslation();

  const associatesRows = mapAssociatesDataToCsv(content, t);
  return (
    <>
      <Suspense fallback={<></>}>
        <CSVDownload
          data={associatesRows}
          filename={new Date().toISOString() + ".csv"}
          headers={translateAssociatesHeaders(t)}
        />
      </Suspense>
    </>
  );
};

export default AssociatesExport;
