import { styled } from "@mui/system";
import React, { Suspense } from "react";
import { Copyright } from "./CopyRight";
import { grey } from "@mui/material/colors";
import { SocialMedia } from "./SocialMedia";
import LanguageSelector from "../language-selector/LanguageSelector";
import Grid from "@mui/material/Grid";
import { Email, Infoline } from "./Contacts";
import Version from "./Version";

const Footer = () => {
  return (
    <Wrapper>
      <MainGrid>
        <Grid item>
          <FooterContainer>
            <Grid item>
              <FooterContainer>
                <Grid item>
                  <Infoline />
                </Grid>
                <Grid item>
                  <Email />
                </Grid>
              </FooterContainer>
            </Grid>
            <Grid item>
              <Suspense fallback={<></>}>
                <SocialMedia />
              </Suspense>
            </Grid>
          </FooterContainer>
        </Grid>
        <Grid item>
          <FooterContainer>
            <MobileOrderedItem xs mobileOrder={1}>
              <Copyright />
            </MobileOrderedItem>
            <MobileOrderedItem xs="auto" mobileOrder={2}>
              <Suspense fallback={<></>}>
                <Version />
              </Suspense>
            </MobileOrderedItem>
            <MobileOrderedItem xs mobileOrder={0}>
              <Suspense fallback={<></>}>
                <LanguageSelector />
              </Suspense>
            </MobileOrderedItem>
          </FooterContainer>
        </Grid>
      </MainGrid>
    </Wrapper>
  );
};

const MainGrid = styled((props: any) => (
  <Grid container direction="column" spacing={2} maxWidth="xl" {...props} />
))(() => ({
  zIndex: 1,
}));

const FooterContainer = styled((props: any) => (
  <Grid container spacing={2} {...props} />
))(({ theme }) => ({
  alignItems: "center",
  zIndex: 1,
  flexDirection: "row",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const MobileOrderedItem = styled(({ mobileOrder, ...props }: any) => (
  <Grid item {...props} />
))<{
  mobileOrder: number;
}>(({ theme, mobileOrder }) => ({
  [theme.breakpoints.down("md")]: {
    order: mobileOrder,
  },
}));

const Wrapper = styled("footer")(({ theme }) => {
  return {
    display: "flex",
    position: "relative",
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: "5rem 1rem 1rem",
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    clipPath: "polygon(0 0, 100% 25%, 100% 100%, 0% 100%)",
    ":before": {
      position: "absolute",
      top: "2px",
      left: "0",
      right: "0",
      bottom: "0",
      content: "''",
      clipPath: "inherit",
      background: grey[900],
    },
  };
});

export default Footer;
