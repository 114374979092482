import { useKeycloak } from "@react-keycloak/web";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export const useAuth = () => {
  const { keycloak, initialized } = useKeycloak();
  const {
    identity,
    technicalUser,
    client: { error },
    role,
  } = useSelector((state: RootState) => state.auth);
  return {
    user: keycloak.tokenParsed?.email,
    name: keycloak.tokenParsed?.name,
    username: keycloak.tokenParsed?.preferred_username,
    login: keycloak.login,
    logout: keycloak.logout,
    register: keycloak.register,
    authenticated: keycloak.authenticated,
    initialized,
    roles: role.granted,
    error,
    identity,
    technicalUser,
    role: role.selected,
    preferencesLoaded: role.preferencesLoaded,
  };
};
