import { IconButton, styled, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { FC } from "react";

interface CloseModalIconButtonProps {
  onClick: () => void;
}

export const CloseModalIconButton: FC<CloseModalIconButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  const tooltip = t("close.modal.tooltip");

  return (
    <Tooltip title={tooltip}>
      <StyledButton onClick={onClick}>
        <CloseIcon />
      </StyledButton>
    </Tooltip>
  );
};

const StyledButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 8,
  top: 8,
}));
