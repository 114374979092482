import { FC } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Property } from "csstype";

interface ViewWrapperProps {
  id?: string;
  title?: string;
  align?: Property.TextAlign;
}

const ViewWrapper: FC<ViewWrapperProps> = ({ id, title, align, children }) => {
  return (
    <Wrapper id={id} elevation={0} square>
      <Header>{title}</Header>
      <Content align={align}>{children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled(Paper)(() => ({
  padding: "1.5rem",
  boxShadow: "0 0 5px 0 rgba(0,0,0,.05),0 5px 20px 0 rgba(0,0,0,.05)",
  height: "100%",
}));

const Header = styled(Typography)(() => ({
  fontSize: "1rem",
  lineHeight: "1.875rem",
  marginBottom: "1rem",
  textTransform: "uppercase",
  fontWeight: "normal",
}));

const Content = styled(Box)(({ align }: { align?: Property.TextAlign }) => ({
  textAlign: align,
}));

export default ViewWrapper;
