import UploadFileIcon from "@mui/icons-material/UploadFile";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useFileUpload from "../../hooks/useFileUpload";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";
import { useUploadTechnicalCardMutation } from "../../redux/api/VehiclesApi";

interface TechnicalCardUploadButtonProps {
  id: string;
}

const TechnicalCardUploadButton: FC<TechnicalCardUploadButtonProps> = ({
  id,
}) => {
  const { t } = useTranslation();
  const [uploadTechnicalCard] = useUploadTechnicalCardMutation();
  const organization = useSelectedOrganization();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const title = t("verification.upload.tc");

  const onFileSelected = async (files: File[] | FileList) => {
    try {
      setLoading(true);
      const file = files[0];
      await uploadTechnicalCard({
        file,
        publicId: id,
        organizationId: organization.publicId,
      }).unwrap();
    } catch (err: any) {
      enqueueSnackbar(t("upload.failed"), {
        variant: "error",
        preventDuplicate: true,
        key: err.status,
      });
    } finally {
      setLoading(false);
    }
  };

  const { openDialog } = useFileUpload({
    inputRef: fileInputRef,
    onFileSelected,
  });

  return (
    <div id="upload-technical-card-button">
      <Tooltip title={title}>
        <IconButton size="medium" onClick={openDialog} disabled={loading}>
          {loading ? <CircularProgress size={22.5} /> : <UploadFileIcon />}
        </IconButton>
      </Tooltip>

      <input
        ref={fileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png"
        multiple={false}
        hidden
      />
    </div>
  );
};

export default TechnicalCardUploadButton;
